import {getActivePinia, storeToRefs,} from 'pinia'
import {usePrefetchData,} from '@/assets/js/src/util/composables/usePrefetchData'
import {useNavigationStore,} from '@/assets/js/src/modules/navigation/_pinia/navigation'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'

export function useLastActivityApi () {
    let activePinia = getActivePinia()
    let navigationStore = useNavigationStore(activePinia)
    let userStore = useUserStore(activePinia)

    const {activity,} = storeToRefs(navigationStore)
    
    if(userStore.loggedIn) {
        usePrefetchData({
            actions: [
                {
                    item: activity,
                    action: navigationStore.fetchLastActivity,
                },
            ],
            loadAsyncMessages: [ 'common', ],
        })
    }
    
    return {
        activity,
    }
}