import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'

const HelpList = () => import(/* webpackChunkName: "Help" */ '@/assets/js/src/modules/help/_views/HelpList.vue')
const HelpOverlay = () => import(/* webpackChunkName: "Help" */ '@/assets/js/src/modules/help/_views/HelpOverlay.vue')
const HelpItem = () => import(/* webpackChunkName: "Help" */ '@/assets/js/src/modules/help/_views/HelpItem.vue')

export default function (pinia) {
    let langStore = useLangStore(pinia)

    return [
        {
            path: '/help',
            meta: {
                uiType: 'Overlay',
            },
            alias: [ '/hilfe', '/ajuda', ],
            component: HelpOverlay,
            children: [
                {
                    name: 'HelpOverlay',
                    path: '',
                    meta: {
                        uiType: 'Overlay',
                    },
                    component: HelpList,
                },
                {
                    name: 'HelpItem',
                    path: ':id(\\d+)',
                    meta: {
                        uiType: 'Overlay',
                    },
                    alias: [ '/hilfe/:id(\\d+)', '/ajuda/:id(\\d+)', ],
                    component: HelpItem,
                },
                {

                    name: 'HelpItemWithText',
                    path: ':title(.*)/:id(\\d+)',
                    meta: {
                        uiType: 'Overlay',
                    },
                    alias: [ '/hilfe/:title(.*)/:id(\\d+)', '/ajuda/:title(.*)/:id(\\d+)', ],
                    component: HelpItem,
                },
            ],
        },
        {
            path: `/:locale(${langStore.supportedLocales.join('|')})/help`,
            meta: {
                uiType: 'Overlay',
            },
            component: HelpOverlay,
            children: [
                {
                    name: 'HelpOverlayML',
                    path: '',
                    meta: {
                        uiType: 'Overlay',
                    },
                    component: HelpList,
                },
                {
                    name: 'HelpItemML',
                    path: ':id(\\d+)',
                    meta: {
                        uiType: 'Overlay',
                    },
                    component: HelpItem,
                },
                {
                    name: 'HelpItemWithTextML',
                    path: ':title(.*)/:id(\\d+)',
                    meta: {
                        uiType: 'Overlay',
                    },
                    component: HelpItem,
                },
            ],
        },
    ]
}