import {REF_DEFAULT, REF_SHORT,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {reduceCanonicalsToRef,} from '@/assets/js/src/util/uiTools'
import {getCurrentInstance,} from 'vue'
import {useBooknamesLoaded,} from "@/assets/js/src/util/composables/useBooknamesLoaded"

export function useBibleRefs () {
    let {booknamesLoaded,} = useBooknamesLoaded()

    let {proxy,} = getCurrentInstance()
    proxy.REF_SHORT = REF_SHORT
    proxy.REF_DEFAULT = REF_DEFAULT

    let getRefByCanonicals = function ({canonicals, type, isUrl, booknames,}) {
        return booknamesLoaded.value 
            ? reduceCanonicalsToRef({canonicals, type, isUrl, booknames,}) 
            : ''
    }

    return {
        getRefByCanonicals,
    }
}
