import { decrypt, } from '@/assets/js/src/util/cryptservice'
import {defineStore,} from 'pinia'

export const useAuthStore = defineStore('auth',{
    state () {
        return {
            bugsnagApiKey: '',
        }
    },
    getters: {
        bugsnagAuth: (state) => decrypt(state.bugsnagApiKey),
    },
})
