import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'

const BibleIntroduction = () => import(/* webpackChunkName: "BibleIntroduction" */ '@/assets/js/src/modules/bible/_views/BibleIntroduction.vue')

export default function (pinia) {
    let bibleStore = useBibleStore(pinia)
    let abbreviationsEncoded = bibleStore.abbreviations.map((abbr) => encodeURIComponent(abbr))
    let oneBible = '(' + [ ...abbreviationsEncoded, ].join('|') + ')'

    return [
        {
            name: 'BibleIntroductionRoute',
            path: '/bible/:abbreviation' + oneBible,
            meta: {uiType: 'Overlay',},
            component: BibleIntroduction,
        },
    ]
}
