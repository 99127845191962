import {getInitializedApp,} from '@/assets/js/src/util/appRef'
import {useShowMessage,} from '@/assets/js/src/modules/snackbar/_composables/useShowMessage'
import {ApiResponseError,} from "@/assets/js/src/util/bugsnag"

export const CANCELED_API_CALL = 'redundant_api_call'

const handleException = function (
    e,
    throwException = false,
    showSnackbar = undefined
) {
    // Globales Objekt (window bzw. global) referenzieren
    let globalThis = (1, eval)('this')
    if (!import.meta.env.PROD) {
        if (import.meta.env.SSR) {
            if (typeof e.payload === 'undefined') {
                globalThis.dd(e, e.stack)
            }
        } else {
            console.error(e)
        }
    } else if (import.meta.env.PROD) {
        if (import.meta.env.SSR) {
            if (e.constructor.name === "Object") {
                try {
                    if(![ 404,403, ].includes(e?.payload?.status)) {
                        globalThis.errorLog(e)
                    }
                } catch (e) { /* empty */ }

            } else {
                globalThis.errorLog(e)
            }
        }
    }

    // Wenn Snakcbar-Anzeige nicht über Argumente definiert ist, dann entgegensetzt zum Exception-Werfen handeln
    if (typeof showSnackbar === 'undefined') {
        showSnackbar = !throwException
    }

    if (showSnackbar) {
        // Exception über Snackbar anzeigen
        if (typeof e.payload === 'undefined') {
            commitError(e.message)
        } else {
            if(e.payload.message) {
                commitError(e.payload.message)
            } else {
                try {
                    if(![ 404,403, ].includes(e.payload.status)) {
                        let msg = ''
                        if(typeof e.payload === 'object'){
                            for (const [ key, value, ] of Object.entries(e.payload)) {
                                if(isNaN(key)) {
                                    msg += key + ': ' + value + '<br>'
                                }else{
                                    msg += value + '<br>'
                                }
                            }
                        }else{
                            msg =  JSON.stringify(e.payload).replace(/(\{|\})/g,'')
                        }
                        commitError(msg)
                    }
                } catch (e) {
                    commitError()
                }
            }
        }
    }

    // Formular-Fehler
    let formError = e.payload && typeof e.payload.data !== 'undefined'

    if (throwException || formError) {
        // Exception weitereichen an Vue-Komponente
        throw e
    }
}

const checkResponse = function (apiResponse) {
    if(!apiResponse) {
        throw new ApiResponseError('no response')
    }
    // Überprüfe auf Fehler und werfe Exception
    if (checkResponseKey(apiResponse, 'error')) {
        // ErrorResponse vom Server behandeln
        if([ 404,403,400, ].includes(apiResponse.error.status)) {
            throw {
                exception: new Error('Not Found'),
                payload: apiResponse.error,
            }
        }

        if (!import.meta.env.PROD) {
            console.debug('devInfo:', apiResponse.error.dev_info)
            console.debug('Api Response:', apiResponse)
        }

        throw new Error(apiResponse.error.message)

    } else if (
        // SuccessResponse mit Fehlermeldungen vom Server (z.B. Formulare) behandeln
        checkResponseKey(apiResponse, 'data') &&
        checkResponseKey(apiResponse.data, 'errors')
    ) {
        // FormType Fehler
        if (import.meta.env.DEV) {
            console.debug(apiResponse.data.errors)
            console.debug('Api Response:', apiResponse)
        }
        throw {
            exception: new Error(apiResponse.message),
            payload: apiResponse.data.errors,
        }
    }

    // SuccessResponse ohne Fehlermeldungen
    if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
        console.debug('Api Response:', apiResponse)
    }
    let status =
        apiResponse?.hasOwnProperty('status') && apiResponse.status >= 400
    let data = checkResponseKey(apiResponse, 'data')

    return status && data
}

const checkResponseKey = function (apiResponse, key) {
    return (
        apiResponse?.hasOwnProperty(key) &&
        Object.keys(apiResponse[key]).length !== 0
    )
}

const commitMessage = function (apiResponse, type = 'success') {
    if (!checkResponseKey(apiResponse, 'message')) {
        return
    }

    useShowMessage().showMessage({ message: apiResponse.message, type: type, })
}

const commitError = function (errorMsg = 'Fehler') {
    if(!import.meta.env.SSR) {
        let {i18n,} = getInitializedApp()
        if(errorMsg === 'Failed to fetch' || errorMsg.length === 0) {
            errorMsg = i18n && i18n.t('async.common.standard_error')
        }

        useShowMessage().showMessage({ message: errorMsg, type: 'error', html: !!errorMsg.match(/(<*>)/g), })
    }
}

const startLoadingIndicator = function () {
    if(!(window.bsNProgress && window.bsNProgress.isStarted())) {
        window.bsNProgress && window.bsNProgress.configure({
            trickleSpeed: 150,
            minimum: 0.2,
            showSpinner: false,
        })
        window.bsNProgress && window.bsNProgress.start()
    } else {
        window.bsNProgress && window.bsNProgress.set(0.5)
    }
}

const stopLoadingIndicator = function () {
    if(window.bsNProgress && window.bsNProgress.isStarted() && process.env.NODE_ENV !== 'test') {
        window.bsNProgress && window.bsNProgress.done()
    }
}

const paramsToQuery = function (obj, prefix) {
    let str = [],
        p
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p]
            str.push((v !== null && typeof v === "object") ?
                paramsToQuery(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v))
        }
    }

    return str.join("&")
}

const compareUrl = function (url, compareTo) {
    return url === compareTo ||
        url === compareTo.split('/').map((part)=>encodeURIComponent(part)).join('/') ||
        false
}

export {
    paramsToQuery,
    checkResponse,
    handleException,
    commitMessage,
    commitError,
    startLoadingIndicator,
    stopLoadingIndicator,
    compareUrl,
}
