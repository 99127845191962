<template>
    <div
        v-cloak
        id="app"
        :dir="localeDirection"
        :class="['bs-app', ...getTopClasses(), {'bs-app--top':isTop && $route.meta.landingPage}]"
        style="--vh: 1vh; --vvh: 1vh;"
    >
        <v-app
            id="inspire"
        >
            <v-no-ssr>
                <print-header v-if="printingVar" />
            </v-no-ssr>
            <bs-snackbar v-if="loadSnackbar" />
            <hydrate ssr-only>
                <noscript>
                    <div id="snackbar__wrapper" aria-live="polite" class="hidden-print-only" style="bottom: 0;">
                    <div class="v-snack v-snack--active v-snack--bottom v-snack--right"
                    style="z-index: 10200;">
                    <div class="v-snack__wrapper error">
                    <div class="v-snack__content">{{ messagesLoaded ? t('async.common.no_javascript_notice') : 'Please activate JavaScript to use the website.' }}</div>
                    </div>
                    </div>
                    </div>
                </noscript>
            </hydrate>
            <hydrate ssr-only>
                <div
                    id="snackbar__wrapper--classic"
                    aria-live="polite"
                    class="hidden-print-only"
                    style=" bottom: 0;display: none;"
                >
                    <div
                        class="v-snack v-snack--active v-snack--bottom v-snack--right"
                        style="z-index: 10200;"
                    >
                        <div class="v-snack__wrapper bg-error">
                            <div class="v-snack__content">
                                {{ messagesLoaded ? t('async.common.classic_notice.others') : 'Your browser is out of date. If ERF Bibleserver is very slow, please update your browser.' }}
                            </div>
                        </div>
                    </div>
                </div>
            </hydrate>
            <bs-navigation
                :messages-loaded="messagesLoaded"
            />
            <bs-header
                :messages-loaded="messagesLoaded"
            />
            <banner
                v-if="mounted"
                tabindex="-1"
            ></banner>
            <report-form
                v-if="report"
                tabindex="-1"
            ></report-form>
            <v-main
                v-show="!overlayModal"
                v-bind="specialAttrs"
                :class="{'hidden-print-only':overlayModal,'is-overlay':overlay}"
            >
                <!-- in-out - beide Elemente gleichzeitig präsent; out-in - jeweils nur ein Element präsent;-->
                <router-view v-slot="{Component}">
                    <transition
                        :name="transitionName"
                        :mode="transitionMode"
                    >
                        <keep-alive
                            include="SearchView,TextView"
                            max="2"
                        >
                            <component :is="Component" />
                        </keep-alive>
                    </transition>
                </router-view>
            </v-main>
            <transition :name="overlay ? 'none' : (overlayModal ? 'overlay-slide-out' : 'overlay-slide-in') ">
                <div
                    v-if="overlayModal"
                    id="main-content"
                    class="overlay-modal-wrapper"
                >
                    <overlay-modal></overlay-modal>
                </div>
            </transition>
            <transition
                v-if="!($route.meta.noScrollTop && $route.meta.noScrollTop($route))"
                name="slide-y-reverse-transition"
            >
                <v-btn
                    v-show="fab"
                    aria-live="polite"
                    :title="messagesLoaded ? t('async.common.button.top') : ''"
                    :class="['bs-app__scroll-top-btn', 'hidden-print-only', {'sidebar-open': sidebarOpen && $route.meta.sidebar, 'sidebar-closed': !sidebarOpen && $route.meta.sidebar, 'notrans': sidebarOnMove, 'on-overlay': overlay}]"
                    position="fixed"
                    density="comfortable"
                    icon
                    @click="goTo(0)"
                >
                    <v-icon :color="colors.black">
                        bs:$vuetify.icons.mdiArrowUp
                    </v-icon>
                </v-btn>
            </transition>
            <audio-player v-if="audioPlayer" />
            <bs-help-tour
                v-if="showHelp"
                :type="helpTour"
                :immediate="startHelpTour"
            ></bs-help-tour>
            <chat-button></chat-button>
        </v-app>
    </div>
</template>

<script>
import Header from './layout/Header.vue'
import Navigation from './modules/navigation/_views/Navigation.vue'
import Snackbar from '@/assets/js/src/modules/snackbar/_components/Snackbar.vue'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import Hydrate from '@/assets/js/src/components/LazyHydrate.vue'
import {getActivePinia, mapActions, storeToRefs,} from 'pinia'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {
    useUserOptionSyncWithSessionStorage,
} from '@/assets/js/src/modules/user/_composables/useUserOptionSyncWithSessionStorage'
import {usePrefetchData,} from '@/assets/js/src/util/composables/usePrefetchData'
import colors from '@/assets/js/src/style/json/colors'
import {defineAsyncComponent,} from "vue"
import {useHeadInfo,} from "@/assets/js/src/layout/useHeadInfo"
import {useGoTo,} from "vuetify"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import ChatButton from "@/assets/js/src/modules/chat/_components/chat/ChatButton.vue"

export default {
    name: 'App',
    components: {
        ChatButton,
        'bs-snackbar': Snackbar,
        'bs-header': Header,
        'bs-navigation': Navigation,
        PrintHeader: defineAsyncComponent(() => import('@/assets/js/src/layout/print/PrintHeader.vue')),
        OverlayModal: defineAsyncComponent(() => import('@/assets/js/src/components/OverlayModal.vue')),
        Banner: defineAsyncComponent(() => import('@/assets/js/src/modules/banner/_components/Banner.vue')),
        ReportForm: defineAsyncComponent(() => import('@/assets/js/src/modules/report/_components/ReportForm.vue')),
        AudioPlayer: defineAsyncComponent(() => import('@/assets/js/src/modules/audioPlayer/_components/AudioPlayer.vue')),
        'bs-help-tour': defineAsyncComponent(() => import('@/assets/js/src/modules/help/_components/HelpTour.vue')),
        Hydrate,
    },
    setup () {
        let activePinia = getActivePinia()
        let goTo = useGoTo()

        const {
            notifications,
            report,
            startHelpTour,
            overlayModal,
            overlay,
            helpTour,
            audioPlayer,
            sidebarOpen,
            sidebarOpenWidthPx,
            sidebarOnMove,
            existsLastRoute,
            topClass,
            banner,
        } = storeToRefs(useAppUiStore(activePinia))

        const {
            printBtnDisabled,
            menu,
            criticalMessagesLoaded,
            mobileView,
            printing,
        } = storeToRefs(useAppUiStore(activePinia))

        const { localeDirection, } = storeToRefs(useLangStore(activePinia))

        useUserOptionSyncWithSessionStorage()
        let {messagesLoaded,} = usePrefetchData({
            loadAsyncMessages: [ 'autocomplete', 'common', 'menu', ],
        })

        useHeadInfo()

        return {
            notifications,
            report,
            startHelpTour,
            overlayModal,
            overlay,
            helpTour,
            audioPlayer,
            sidebarOpen,
            sidebarOpenWidthPx,
            sidebarOnMove,
            existsLastRoute,
            topClass,
            banner,
            printBtnDisabled,
            menu,
            criticalMessagesLoaded,
            mobileView,
            printing,
            localeDirection,
            messagesLoaded,
            colors,
            ...useTranslation(),
            goTo,
        }
    },
    data () {
        return {
            mounted: false,
            transitionName: 'none',
            transitionMode: 'out-in',
            loadSnackbar: false,
            fab: false,
            showHelp: false,
            isTop: true,
        }
    },
    computed: {
        specialAttrs () {
            return !this.overlayModal ? {'id': 'main-content',} : null
        },
        printingVar () {
            return this.printing || globalThis.isFF
        },
    },
    watch: {
        $route (to, from) {
            // Transition zuordnen
            if (to.meta.uiType === 'Overlay' && from.meta.uiType === 'Overlay') {
                this.transitionName = 'none'
                this.transitionName = 'out-in'
            } else if (to.meta.uiType === 'Overlay') {
                this.transitionName = 'overlay-slide-in'
                this.transitionName = 'in-out'
            } else if (from.meta.uiType === 'Overlay') {
                this.transitionName = 'overlay-slide-out'
                this.transitionName = 'in-out'
            } else {
                this.transitionName = 'none'
                this.transitionName = 'out-in'
            }

            // Nach Route CHANGED grundsätzlich Menu schließen
            this.menu = false

            if (!import.meta.env.SSR) {

                // bs-params auf Startseite löschen/reseten
                if ([ 'Home', 'HomeML', ].includes(to.name) && cookieManager.get('bs-params')) {
                    cookieManager.remove('bs-params')
                }

                // From PageNotFound window reload
                if (!this.existsLastRoute && from.name === 'PageNotFound' && to.name !== 'PageNotFound') {
                    location.reload()
                }
            }
        },
        helpTour: {
            immediate: !import.meta.env.SSR,
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal && (newVal === 'text' || newVal === 'search') && !cookieManager.get('bs-help-' + newVal)) {
                    this.showHelp = true
                } else if (this.startHelpTour) {
                    this.showHelp = true
                }
            },
        },
        'messagesLoaded': {
            handler: function (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    this.criticalMessagesLoaded = newValue
                }
            },
            immediate: !import.meta.env.SSR,
        },
        '$vuetify.display.smAndDown': {
            handler: function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    // Für die Fixierung mit store syncen
                    this.mobileView = newValue

                    // Zeige Header, wenn sich mobileView ändert
                    if (window.MAIN_HEADROOM) {
                        window.MAIN_HEADROOM.top()
                        window.MAIN_HEADROOM.pin()
                    }
                }
            },
            immediate: !import.meta.env.SSR,
        },
    },
    created () {
        this.oldScrollPos = 0
    },
    beforeMount () {
        if (this.$vuetify.isHydrating) {
            this.$vuetify.isHydrating = false
            this.$vuetify.display.update()
        }
    },
    mounted () {
        this.loadSnackbar = true

        // Regestrierung des EventListener für ein PrintEvent, um gebraucht Layout-Komponenten dynamisch nachzuladen
        let self = this

        if (typeof window.onbeforeprint === 'undefined') {
            // Safari
            if (window.matchMedia) {
                // Safari versteht kein add EventListener an dieser stelle
                window.matchMedia('print').addListener((mql) => {
                    if (mql.matches) {
                        self.printing = true
                        // Druck-Event an ga senden
                        this.$bsa && this.$bsa.event({
                            eventCategory: 'printing',
                            eventAction: 'start',
                            eventLabel: 'via matchMedia',
                            eventValue: 0,
                        })
                    } else {
                        self.printing = false
                    }
                })
            }
        } else {
            // IE & Chrome
            window.addEventListener('beforeprint', () => {
                this.printing = true

                // Druck-Event an ga senden
                this.$bsa && this.$bsa.event({
                    eventCategory: 'printing',
                    eventAction: 'start',
                    eventLabel: 'via beforeprint hook',
                    eventValue: 0,
                })
            })
            window.addEventListener('afterprint', () => {
                this.printing = false
            })
        }

        // Nach 2 Sekunden Print-Header laden, weil bei STRG+P keine async Komponenten geladen werden
        setTimeout(() => {
            this.printing = true
            setTimeout(() => {
                this.printing = false
                this.printBtnDisabled = false
            })
        }, 2000)
        window.addEventListener('beforeunload', this.handleReload)

        // ViewportHeight berechnen
        this.calculateViewportHeight()
        window.addEventListener('resize', this.calculateViewportHeight)

        // Notifications laden
        let notificationWatcher = this.$watch('notifications', function (newVal) {
            // Notification laden
            if (typeof newVal.top !== 'undefined' && typeof newVal.top.id !== 'undefined') {
                this.mounted = true
                if (this.$vuetify.display.smAndDown) {
                    window.addEventListener('orientationchange', this.handleOrientationOnMobile)
                }
                notificationWatcher()
            }
        }, {immediate: false,})

        setTimeout(() => {
            this.loadNotifications()
            this.calculateViewportHeight()
        }, 1000)

        this.isTop = (window.pageYOffset || 0) <= 100

        window.addEventListener('scroll', this.onScroll)
        if(window.visualViewport) {
            visualViewport.addEventListener('scroll', this.onVisualViewportScroll)
        }
    },
    beforeUnmount () {
        window.removeEventListener('scroll', this.onScroll)
        if(window.visualViewport) {
            visualViewport.removeEventListener('scroll', this.onVisualViewportScroll)
        }
        window.removeEventListener('beforeunload', this.handleReload)
        window.removeEventListener('resize', this.calculateViewportHeight)
        if (this.$vuetify.display.smAndDown && typeof this.notifications.top !== 'undefined' && typeof this.notifications.top.id !== 'undefined') {
            window.removeEventListener('orientationchange', this.handleOrientationOnMobile)
        }
    },
    methods: {
        ...mapActions(useAppUiStore, [ 'loadNotifications', ]),
        calculateViewportHeight () {
            let appEl = document.querySelector('.bs-app')
            if (appEl) {
                let vh = window.innerHeight * 0.01
                appEl.style.setProperty('--vh', `${vh}px`)
            }
            if(appEl && window.visualViewport?.height) {
                appEl.style.setProperty('--vvh', `${window.visualViewport.height * 0.01}px`)
            }
        },
        getTopClasses () {
            return this.topClass
        },
        onScroll (e) {
            if (!import.meta.env.SSR && this.$route.meta.uiType !== 'TextView') {
                let scrollPos = window.pageYOffset || e.target.scrollTop || 0
                this.isTop = scrollPos <= 100
                if (this.oldScrollPos >= scrollPos) {
                    this.fab = scrollPos > 20
                } else {
                    this.fab = false
                }
                this.oldScrollPos = scrollPos
            }
        },
        onVisualViewportScroll () {
            if(typeof window.visualViewport?.offsetTop !== 'undefined') {
                let vvot = window.visualViewport?.height === window.innerHeight
                    ? 0
                    : window.visualViewport.offsetTop
                document.body.style.setProperty('--vvot', `${vvot}px`)
            }
        },
        handleReload () {
            try {
                let options = window.sessionStorage.getItem('options')
                window.sessionStorage.clear()
                window.sessionStorage.setItem('options', options)
            } catch (err) {
                // eslint-disable-next-line no-empty
            }
        },
        handleOrientationOnMobile () {
            var orientation = screen.msOrientation || screen.mozOrientation || screen.orientation?.type
            let landscape = false
            if (typeof orientation === 'undefined') {
                landscape = window.orientation && Math.abs(window.orientation) === 90
            } else {
                landscape = orientation.startsWith('landscape')
            }
            if (typeof this.notifications.top.id !== 'undefined') {
                this.banner = !landscape
            }
        },
    },
}
</script>

<style lang="scss">
    .bs-app {
        --bs-sidebar-width: v-bind(sidebarOpenWidthPx);

        .v-main.is-overlay {
            position: relative !important;
            top: auto !important;
        }

        .bs-header-wrapper {
            opacity: 1;

            @at-root .bs-app--top .bs-header-wrapper {
                opacity: 0;
            }
        }

        &__scroll-top-btn {
            right: #{map-deep-get($bs-xl, text, textNav, padding)}px;
            bottom: #{map-deep-get($bs-xl, text, textNav, padding)}px;
            z-index: 8;
            margin: 6px 8px;
            color: map-deep-get($bs-color, sidebar, background);
            background-color: map-deep-get($bs-color, grey);

            &.sidebar-open {
                right: calc(#{map-deep-get($bs-xl, text,textNav,padding)}px + var(--bs-sidebar-width));
                bottom: #{map-deep-get($bs-xl, text, textNav, padding)}px;

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    right: calc(#{map-deep-get($bs-xl, text,textNav,padding)}px + var(--bs-sidebar-width));
                    bottom: #{map-deep-get($bs-xl, text, textNav, padding)}px;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    right: #{map-deep-get($bs-xl, text, textNav, padding)}px;
                    bottom: #{map-deep-get($bs-xl, text, textNav, padding)}px;
                }
            }

            &.sidebar-closed {
                right: #{map-deep-get($bs-xl, text,textNav,padding) + map-deep-get($bs-xl, sidebar, closed, width)}px;
                bottom: #{map-deep-get($bs-xl, text, textNav, padding)}px;

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    right: #{map-deep-get($bs-xl, text,textNav,padding) + map-deep-get($bs-md, sidebar, closed, width)}px;
                    bottom: #{map-deep-get($bs-xl, text, textNav, padding)}px;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    right: #{map-deep-get($bs-xl, text, textNav, padding)}px;
                    bottom: #{map-deep-get($bs-xl, text, textNav, padding)}px;
                }
            }

            &.on-overlay {
                bottom: #{map-deep-get($bs-xl, text, textNav, padding)}px !important;
                z-index: 9997;
            }
        }

        &.audio-player .bs-app__scroll-top-btn {
            bottom: #{map-deep-get($bs-xl, text, textNav, padding) + map-deep-get($bs-xl, audioPlayer, height)}px;
        }

        .overlay-slide-in-enter-active {
            &-enter-active {
                transition: all .25s ease-out;
            }

            &-leave-active {
                z-index: -1 !important;
                display: none;
                transition: all .05s linear;
            }

            &-enter-from, &-leave-to {
                position: fixed;
                top: 0;
                z-index: -1 !important;
                transform: translateY(100vh);
            }

            &-leave-from, &-enter-to {
                position: fixed;
                top: 100vh;
                z-index: 9997;
                transform: translateY(-100vh);
            }
        }

        .overlay-slide-out-enter-active {
            &-enter-active {
                z-index: 9997;
                display: none;
                transition: all .05s linear;
            }

            &-leave-active {
                transition: all .1s ease-in;
            }

            &-leave-from, &-leave-to {
                position: fixed;
                z-index: 9997;
                transform: translateY(100vh);
            }

            &-enter-from, &-enter-to {
                z-index: -1 !important;
            }
        }
    }

    // Override Vuetify Settings
    #app.bs-app {
        .application {
            color: map-deep-get($bs-color, black);
            background: #fff;
        }

        &[v-cloak] {
            display: none;
        }
    }
</style>
