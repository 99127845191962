import {useLangStore,} from "@/assets/js/src/modules/lang/_pinia/lang"
import {getActivePinia, storeToRefs,} from "pinia"
import {computed,} from "vue"

export function useMessagesLoaded (keys) {
    let activePinia = getActivePinia()
    
    const langStore = useLangStore(activePinia)
    const {areAsyncKeysLoaded,} = storeToRefs(langStore)

    const messagesLoaded = computed(()=>{
        return areAsyncKeysLoaded.value(keys)
    })

    return {
        messagesLoaded,
    }
}