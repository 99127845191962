export function isObject (item) {
    return (
        item &&
        typeof item === 'object' &&
        !Array.isArray(item) &&
        item !== null
    )
}

export function mergeDeep (target, source) {
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!target[key]) {
                    try {
                        Object.assign(target, { [key]: {}, })
                    } catch (e) {
                        /* continue regardless of error */
                    }
                }
                mergeDeep(target[key], source[key])
            } else if (Array.isArray(source[key])){
                try {
                    if (source[key] === null) {
                        source[key] = ''
                    }
                    if (typeof target[key] === 'undefined' || !Array.isArray(target[key])) {
                        Object.assign(target, { [key]: source[key], })
                    } else {
                        target[key] = Array.from(new Set([ ...target[key], ...source[key], ]))
                    }
                } catch (e) {
                    /* continue regardless of error */
                }
            } else {
                try {
                    if (source[key] === null) {
                        source[key] = ''
                    }
                    Object.assign(target, { [key]: source[key], })
                } catch (e) {
                    /* continue regardless of error */
                }
            }
        })
    }

    return target
}

export function getValueByPath (path, obj, sep = '/') {
    return path.split(sep).reduce((value, pathPart) => {
        if(typeof value === 'undefined') {
            return undefined
        } else {
            return value[pathPart]
        }
    }, obj)
}
