<template>
    <div
        id="snackbar__wrapper"
        class="hidden-print-only"
        aria-live="polite"
        :style="`z-index: ${zIndex};bottom: ${ccOffset}px`"
    >
        <v-snackbar
            v-for="(snackbar, index) in snackbars"
            :key="snackbar.uniqueId"
            :ref="snackbar.uniqueId"
            :model-value="snackbar.show"
            :color="snackbar.type"
            :timeout="snackbar.timeout"
            transition="fade-transition"
            location="bottom right"
            attach="#snackbar__wrapper"
            variant="flat"
            :style="'z-index:'+ (10200 - index)"
            @update:model-value="snackbar.show = false"
            @click.capture="closeSnackbarOnClickedLink($event, snackbar)"
        >
            <template v-if="snackbar.html">
                <span
                    class="snackbar-item__content"
                    @click="handleRoutableLink"
                    v-html="snackbar.message"
                ></span>
            </template>
            <template v-else>
                {{ snackbar.message }}
            </template>
            <template #actions>
                <v-btn
                    icon
                    variant="text"
                    :title="'Snackbar schließen'"
                    class="snackbar-item__btn--close"
                    @click.prevent.stop="hideSnackbar(snackbar)"
                >
                    <v-icon>bs:$vuetify.icons.mdiClose</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {getActivePinia, mapActions, storeToRefs,} from 'pinia'
import {useSnackbarStore,} from '@/assets/js/src/modules/snackbar/_pinia/snackbar'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useHandleRoutableLink,} from '@/assets/js/src/util/composables/useHandleRoutableLink'

let snackbarWatchers = []

export default {
    name: 'Snackbar',
    setup () {
        let activePinia = getActivePinia()

        const appUiStore = useAppUiStore(activePinia)
        const {menu,} = storeToRefs(appUiStore)

        const autocompleteStore = useAutocompleteStore(activePinia)
        const {isComponentVisible,} = storeToRefs(autocompleteStore)

        const snackbarStore = useSnackbarStore(activePinia)
        const {snackbars,} = storeToRefs(snackbarStore)

        return {
            menu,
            isComponentVisible,
            snackbars,
            ...useHandleRoutableLink(),
        }
    },
    data () {
        return {
            ccOffset: 0,
        }
    },
    computed: {
        zIndex: function () {
            return (this.isComponentVisible || this.menu) ? 9990 : 10200
        },
    },
    watch: {
        snackbars: {
            handler (newSnackbarsStatus) {
                this.$nextTick(() => {
                    snackbarWatchers.forEach((cancelWatcherFn) => {
                        cancelWatcherFn()
                    })

                    snackbarWatchers = []
                    newSnackbarsStatus.forEach((snackbar) => {
                        let cancelWatcherFn = this.$watch(
                            () => {
                                return snackbar.show
                            },
                            (newValue, oldValue) => {
                                if (newValue !== oldValue && !newValue) {
                                    // Evtl. einen Snackbar close callback ausführen
                                    if (snackbar.closeCb) {
                                        snackbar.closeCb()
                                    }

                                    // Snackbar löschen
                                    this.removeSnackbarByUniqueId({uniqueId: snackbar.uniqueId,})

                                    // Watcher beenden
                                    cancelWatcherFn()
                                }
                            }
                        )

                        // Watcher merken
                        snackbarWatchers.push(cancelWatcherFn)
                    })

                    this.adjustBottom()
                })
            },
            immediate: !import.meta.env.SSR,
        },
        $route (newRoute) {
            this.snackbars.forEach((snackbar) => {
                if (snackbar.routes.length) {
                    if (!snackbar.routes.includes(newRoute.meta.uiType)) {
                        this.hideSnackbar(snackbar)
                    }
                }
            })
        },
    },
    mounted () {
        this.$el.addEventListener('resize', this.adjustBottom)
    },
    beforeUnmount () {
        this.$el.removeEventListener('resize', this.adjustBottom)
    },
    methods: {
        ...mapActions(useSnackbarStore, [
            'removeSnackbarByUniqueId',
        ]),
        closeSnackbarOnClickedLink (event, snackbar) {
            let {target,} = event

            if (target && target.tagName === 'A') {
                this.hideSnackbar(snackbar)
            }
        },
        hideSnackbar (snackbar) {
            if (this.$refs[snackbar.uniqueId][0]) {
                snackbar.show = false
            }
        },
        adjustBottom () {
            // Wenn Cookie Consent eingeblendet ist, dann snackbars darüber anzeigen
            let cc = document.querySelector('.cc-dialog-container.visible')
            if (!import.meta.env.SSR && cc) {
                this.ccOffset = cc?.clientHeight ?? 0
            }
        },
    },
}
</script>

<style lang="scss">
.bs-app #snackbar__wrapper, .bs-app #snackbar__wrapper--classic {
    position: fixed;
    left: 200vw;
    z-index: 10100;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-around;
    width: 100vw;

    .v-snackbar {
        position: relative !important;
        left: -200vw;
        height: auto;
        margin-right: #{map-deep-get($bs-xl, sidebar,closed,width) + 20}px;
        margin-bottom: 8px;

        @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
            bottom: 0;
            width: 100%;
            margin-right: 0;
            margin-bottom: 0;
        }

        .snackbar-item__btn--close {
            margin: 0 0 0 8px;
            width: 36px;
            height: 36px;
        }

        .v-snackbar__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;
        }

        .v-snack__action {
            margin: 0;
        }

        .v-snackbar__wrapper {
            max-width: 480px;
            margin: 0;
            box-shadow: none !important;

            @media screen and (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                min-width: 100%;
                max-width: 100%;
            }

            &.bg-warning, &.bg-info {
                color: #fff;
                background-color: map-deep-get($bs-color, snackbar, info) !important;
                border-color: map-deep-get($bs-color, snackbar, info) !important;

                button {
                    color: #fff;
                }
            }

            &.bg-error {
                color: #fff;
                background-color: map-deep-get($bs-color, snackbar, error) !important;
                border-color: map-deep-get($bs-color, snackbar, error) !important;

                button {
                    color: #fff;
                }
            }

            &.bg-success {
                color: #fff;
                background-color: map-deep-get($bs-color, snackbar, success) !important;
                border-color: map-deep-get($bs-color, snackbar, success) !important;

                button {
                    color: #fff;
                }
            }

            &.bg-help, &.bg-highlight {
                color: #fff;
                background-color: map-deep-get($bs-color, bsHighlight);
                border-color: map-deep-get($bs-color, bsHighlight);

                button {
                    color: #fff;
                    background: transparent;
                }
            }

            a {
                color: white
            }
        }
    }
}

</style>
