const VerseOfTheDayOverlay = () => import(/* webpackChunkName: "VerseOfTheDay" */ '@/assets/js/src/modules/verseOfTheDay/_views/VerseOfTheDayOverlay.vue')

export default  [
    {
        name: 'VerseOfTheDayRoute',
        path: '/verse-of-the-day/:date?',
        meta: {
            uiType: 'Overlay',
            landingPage: true,
            registerBack: true,
        },
        beforeEnter: (to, from, next) => {
            let param = to.params?.date || ''
            let isParamUndefined = !param.length
            let isDate = (new RegExp('\\d\\d\\d\\d-\\d\\d-\\d\\d')).test(param)
            if(isDate) {
                let dataObj = new Date(to.params.date)
                isDate = dataObj instanceof Date && !isNaN(dataObj)
            }
            let isSubscriptionId = (new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$')).test(param)
            if (isParamUndefined || isDate || isSubscriptionId) {
                to.meta.isId = isSubscriptionId
                to.meta.isDate = isDate
                next()
            } else {
                next({
                    name: 'PageNotFound',
                })
            }
        },
        component: VerseOfTheDayOverlay,
    },
]