import {defineStore, getActivePinia,} from 'pinia'
import {getInitializedApp,} from '@/assets/js/src/util/appRef'
import {removeSpecialCharacters,} from '@/assets/js/src/util/slugify'
import levenshtein from 'fast-levenshtein'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useBooknamesStore,} from '@/assets/js/src/modules/bible/_pinia/booknames'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'

export const SEARCH_MIN_CHARACTERS = 3
export const LEVENSHTEIN_MIN_CHARACTERS = 3

const gaNames = {
    BIBLE_SELECT: 'Bibelauswahl',
    BOOK_SELECT: 'Buchauswahl',
    AUTOCOMPLETE: 'Dropdown',
    TEXT_FORMAT: 'Textformatierung',
    USER_POPOVER: 'Nutzerpopup',
    SEARCH_OPTIONS: 'Suchfilter',
}

export function clearInput (input) {
    // Kapitel und Vers erkennen
    let regex = /^[0-9]?[^0-9]+([0-9]{0,3}[^0-9]*[0-9]{0,3}([^0-9]*[0-9]{0,3})*)/gim
    let matches = regex.exec(input)
    let chapterVerses = ''

    if (matches !== null) {
        chapterVerses = matches[1]
    }

    if (chapterVerses !== '') {
        input = input.replace(new RegExp(chapterVerses.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '$'), '')
    }

    return {chapterVerses, input: input.trim(),}
}

export const useAutocompleteStore = defineStore('autocomplete', {
    state: () => ({
        /** Inhalt des Textfelds **/
        autocompleteInput: '',

        /** Buchnamen, die anhand des Inhalts gefunden wurden **/
        filteredBooknames: [],

        /** STEUERUNG DER SICHTBARKEIT  einzelner Komponenten **/
        components: [ 'BIBLE_SELECT', 'BOOK_SELECT', 'AUTOCOMPLETE', 'TEXT_FORMAT', 'USER_POPOVER', 'SEARCH_OPTIONS', ],
        componentsVisibility: {
            BIBLE_SELECT: false,
            BOOK_SELECT: false,
            AUTOCOMPLETE: false,
            TEXT_FORMAT: false,
            USER_POPOVER: false,
            SEARCH_OPTIONS: false,
        },
        blockHideAllComponents: false,
        headingToScroll: '',
        reload: false,
        reloadSearch: false,
        resetBookSelect: false,
        isSearch: false,
        loadingStatus: {},
    }),
    getters: {
        getVisibleComponent: (state) => () => {
            return Object.keys(state.componentsVisibility).find((key) => {
                return state.componentsVisibility[key] === true
            })
        },
        getVisibleComponentCached: (state) => {
            return Object.keys(state.componentsVisibility).find((key) => {
                return state.componentsVisibility[key] === true
            })
        },
        getLoadingStatus: (state) => (component) => {
            if (typeof state.loadingStatus[component] === 'undefined') {
                return false
            }

            return state.loadingStatus[component]
        },
        getComponentVisibility: (state) => (component) => state.componentsVisibility[component],
        isComponentVisible: (state) => Object.values(state.componentsVisibility).some((visibility) => visibility === true),
    },
    actions: {
        setLoading ({type, status,}) {
            let loadingStatus = globalThis.clone(this.loadingStatus)

            loadingStatus[type] = status

            this.loadingStatus = loadingStatus
        },
        hideAllComponents () {
            if (!this.blockHideAllComponents) {
                let componentsVisibility = globalThis.clone(this.componentsVisibility)

                Object.keys(componentsVisibility).forEach((key) => {
                    componentsVisibility[key] = false
                })
                this.resetBookSelect = true

                this.componentsVisibility = componentsVisibility
            }
        },
        setComponentVisible (component) {
            if (this.components.indexOf(component) === -1) {
                throw 'Komponente mit dem Namen ' + component + ' existiert nicht!'
            }

            let componentsVisibility = globalThis.clone(this.componentsVisibility)

            Object.keys(componentsVisibility).forEach((key) => {
                if (key !== component) {
                    componentsVisibility[key] = false
                }
            })

            componentsVisibility[component] = !componentsVisibility[component]

            if(componentsVisibility[component] && component !== 'AUTOCOMPLETE') {
                let {app,} = getInitializedApp()
                let $bsa = app?.config?.globalProperties?.$bsa
                $bsa && $bsa.event({
                    eventCategory: 'autocomplete',
                    eventAction: gaNames[component],
                })
            }

            this.componentsVisibility = componentsVisibility
        },
        resetToBeSearched () {
            let filteredBooknames = this.filteredBooknames

            for (let i = 0; i < filteredBooknames.length; i++) {
                filteredBooknames[i].toBeSearched = false
            }

            this.filteredBooknames = filteredBooknames
        },
        async calculateToBeSearched () {
            let result = globalThis.clone(this.filteredBooknames)
            let input = globalThis.clone(this.autocompleteInput)

            if (input.trim() !== '') {
                // Aktuelle Auswahl markieren
                if (result.length > 0 && input.length >= SEARCH_MIN_CHARACTERS) {
                    result[0].toBeSearched = true

                    let check = true
                    let actual = true
                    let realInput = input
                    let inputData = clearInput(input)
                    input = inputData.input
                    input = removeSpecialCharacters(input.toLowerCase())

                    for (let i = 0; i < result.length - 1; i++) {
                        // Direkte Matches bei Alias bevorzugen (auch wenn Default mit Ziffer beginnt)
                        let regexp = new RegExp('^' + result[i].pattern, 'i')

                        if (
                            result[i].default.toLowerCase().indexOf(input.toLowerCase()) === 0 ||
                            regexp.test(input) ||
                            regexp.test(removeSpecialCharacters(input)) ||
                            /^\d/g.test(input) === true ||
                            result[i]['default-ff'].indexOf(input.toLowerCase()) === 0 ||
                            result[i]['default-ft'].indexOf(input.toLowerCase()) === 0) {
                            check = false
                        }

                        if(/^.+\d/g.test(realInput) === true){
                            check = false
                        }

                        let pattern = new RegExp('^' + input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gmi')

                        if (/^\d/g.test(input) === true && pattern.test(result[i].default) === true && actual) {
                            result[0].toBeSearched = false
                            result[i].toBeSearched = true
                            actual = false
                        }
                    }

                    if (check || /^jesus$/gi.test(input)) {
                        result[0].toBeSearched = false
                        result[result.length - 1].toBeSearched = true
                    }
                }
                this.filteredBooknames = result
            }
        },
        async handleAutocompleteInput (input) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value

            let result = []
            input = input.trim()
            let orgInput = input
            let {app,} = getInitializedApp(rootState.appId)

            if (input !== '') {
                let bibleStore = useBibleStore(activePinia)
                let booknamesStore = useBooknamesStore(activePinia)
                let langStore = useLangStore(activePinia)
                // Übersetzungsauswahl
                if (input.indexOf(':') !== -1) {
                    let abbr = input.split(':')[0].trim()
                    let foundAbbr = Object.keys(bibleStore.bibles).find(bibleAbbr => bibleAbbr.toUpperCase() === abbr.toUpperCase())

                    if (foundAbbr) {
                        let $bsa = app?.config?.globalProperties?.$bsa
                        $bsa && $bsa.event({
                            eventCategory: 'shortcuts',
                            eventAction: ': (Bibelauswahl im Suchfeld)',
                        })

                        bibleStore.setSelectedBibles([ foundAbbr, ])

                        input = input.replace(abbr + ':', '')
                        this.autocompleteInput = input
                    }
                }

                let inputData = clearInput(input)
                input = inputData.input

                // Buchauswahl
                if (typeof booknamesStore.booknames !== 'undefined') {
                    let booknames = booknamesStore.booknames

                    let toSearch = removeSpecialCharacters(input.toLowerCase())
                    let levValue
                    if (toSearch !== '') {
                        for (let key in booknames) {
                            let bookname = booknames[key]
                            bookname.type = 'bookname'
                            bookname.toBeSearched = false
                            bookname.levenshtein = 99

                            // Such in allen Varianten
                            for (let i = 0; i < bookname.all.length; i++) {

                                if (bookname.all[i].indexOf(toSearch) === 0 ||
                                    bookname['all-ft'][i].indexOf(toSearch) === 0 ||
                                    bookname['all-tt'][i].indexOf(toSearch) === 0 ||
                                    (input.length >= LEVENSHTEIN_MIN_CHARACTERS && toSearch.length >= 5 && (levValue = levenshtein.get(toSearch, bookname.all[i].substring(0, toSearch.length), {useCollator: true,})) <= 1)) {
                                    if (typeof bookname.chapter !== 'undefined') {
                                        bookname.input = bookname.chapter + ' ' + inputData.chapterVerses
                                    } else {
                                        bookname.input = bookname.default + ' ' + inputData.chapterVerses
                                    }

                                    if(typeof levValue === 'undefined'){
                                        levValue = levenshtein.get(toSearch, bookname.all[i].substring(0, toSearch.length), {useCollator: true,})
                                    }

                                    bookname.levenshtein = levValue

                                    result.push(bookname)
                                }

                                levValue = undefined
                            }
                        }
                    }

                    if (result.length === 0 && toSearch !== '') {
                        let bibleLocales = bibleStore.getBibleLocales(bibleStore.selectedBibles)
                        let loadedLocales = Object.keys(booknamesStore.booknamesByLocale)
                        let locales = bibleLocales.filter((locale) => {
                            return loadedLocales.indexOf(locale) === -1 && langStore.locale !== locale
                        })

                        if (locales.length > 0) {
                            await booknamesStore.loadBooknamesByLocale(locales)
                        }

                        bibleLocales.forEach((locale) => {
                            if (typeof booknamesStore.booknamesByLocale[locale] !== 'undefined') {
                                let booknamesByLocale = booknamesStore.booknamesByLocale[locale].booknames

                                Object.keys(booknamesByLocale).forEach((key) => {
                                    let bookname = booknamesByLocale[key]
                                    bookname.type = 'bookname'
                                    bookname.toBeSearched = false
                                    bookname.levenshtein = 99

                                    // Such in allen Varianten
                                    for (let i = 0; i < bookname.all.length; i++) {
                                        if (bookname.all[i].indexOf(toSearch) === 0 || bookname.all[i].replace(/[^A-Z]/gi, '').indexOf(toSearch) === 0 || (input.length >= LEVENSHTEIN_MIN_CHARACTERS && toSearch.length >= 5 && (levValue = levenshtein.get(toSearch, bookname.all[i].substring(0, toSearch.length), {useCollator: true,})) <= 1)) {
                                            if (typeof bookname.chapter !== 'undefined') {
                                                bookname.input = bookname.chapter + ' ' + inputData.chapterVerses
                                            } else {
                                                bookname.input = bookname.default + ' ' + inputData.chapterVerses
                                            }

                                            if(typeof levValue === 'undefined'){
                                                levValue = levenshtein.get(toSearch, bookname.all[i].substring(0, toSearch.length), {useCollator: true,})
                                            }

                                            bookname.levenshtein = levValue

                                            result.push(bookname)
                                        }

                                        levValue = undefined
                                    }
                                })
                            }
                        })
                    }

                } else {
                    await booknamesStore.loadBooknames(langStore.locale)
                }

                result = [ ...new Set(result), ]

                result.sort((a,b) => {
                    return a.levenshtein - b.levenshtein
                })

                // "Suchen nach" ergänzen
                result.push({
                    type: 'search',
                    input: orgInput,
                    toBeSearched: false,
                })
            }

            this.filteredBooknames = result
            await this.calculateToBeSearched()
        },
    },
})