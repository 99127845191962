import {useLangStore,} from "@/assets/js/src/modules/lang/_pinia/lang"
import {getActivePinia, storeToRefs,} from "pinia"
import {getValueByPath,} from "@/assets/js/src/util/merge"
import {notifyError,} from "@/assets/js/src/util/bugsnag"

export function useTranslation (langStoreArg) {
    let activePinia = getActivePinia()

    const langStore = langStoreArg ?? useLangStore(activePinia)
    const {messages,locale,} = storeToRefs(langStore)

    const t = function (messageKey, replacements = null, notify = true) {
        let translation = getValueByPath(messageKey, messages.value[locale.value], '.')
        if (!translation) {
            notify && notifyError(new Error(`Folgender Term: "${messageKey}" konnte nicht übersetzt werden'`))

            return messageKey
        }

        if (replacements) {
            for (let placeholder in replacements) {
                if (Object.prototype.hasOwnProperty.call(replacements, placeholder)) {
                    translation = translation.replace(new RegExp('{' + placeholder + '}', "g"), replacements[placeholder])
                }
            }
        }

        return translation
    }

    return {
        t,
        tc: (messageKey, count) => {
            try {
                let message = t(messageKey, {
                    count,
                })

                message = message.split(' | ')

                if(parseInt(count) > 1) {
                    return message[1] ?? messageKey
                }

                return message[0] ?? messageKey
            } catch (e) {
                notifyError(new Error(`Folgender Term: "${messageKey}" konnte nicht übersetzt werden'`))

                return messageKey
            }
        },
    }
}
