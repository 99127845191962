import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'

const ReadingPlanOverlay = () => import(/* webpackChunkName: "ReadingPlan" */ '@/assets/js/src/modules/readingPlan/_views/ReadingPlanOverlay.vue')
const ReadingPlanList = () => import(/* webpackChunkName: "ReadingPlan" */ '@/assets/js/src/modules/readingPlan/_views/ReadingPlanList.vue')
const ReadingPlanItem = () => import(/* webpackChunkName: "ReadingPlan" */ '@/assets/js/src/modules/readingPlan/_views/ReadingPlanItem.vue')
const ReadingPlanSection = () => import(/* webpackChunkName: "ReadingPlan" */ '@/assets/js/src/modules/readingPlan/_views/ReadingPlanSection.vue')

export default function (pinia) {
    let langStore = useLangStore(pinia)
    let userStore = useUserStore(pinia)
    let appUiStore = useAppUiStore(pinia)

    return [
        {
            path: '/reading-plans',
            meta: {
                uiType: 'Overlay',
                module: 'readingPlan',
            },
            props: {mode: 'overview',},
            component: ReadingPlanOverlay,
            children: [
                {
                    name: 'ReadingPlanOverlay',
                    path: '',
                    meta: {
                        uiType: 'Overlay',
                        module: 'readingPlan',
                    },
                    component: ReadingPlanList,
                },
            ],
        },
        {
            path: '/reading-plan',
            meta: {
                uiType: 'Overlay',
                module: 'readingPlan',
            },
            props: {mode: 'overview',},
            component: ReadingPlanOverlay,
            children: [
                {
                    name: 'ReadingPlanItem',
                    path: ':name/:id(\\d+)',
                    meta: {
                        uiType: 'Overlay',
                        module: 'readingPlan',
                    },
                    component: ReadingPlanItem,
                },
                {

                    name: 'ReadingPlanSection',
                    path: ':name/:title/:id(\\d+)',
                    meta: {
                        uiType: 'Overlay',
                        module: 'readingPlan',
                        noScrollTop: () => true,
                    },
                    component: ReadingPlanSection,
                    beforeEnter: (to, from, next) => {
                        if (!userStore.loggedIn) {
                            appUiStore.userContentRoute = {
                                route: to,
                                key: 'async.menu.reading-plan',
                            }
                            appUiStore.menu = false
                            next({path: '/login',})
                        } else {
                            next()
                        }
                    },
                },
                {
                    name: 'ReadingPlanOverlayAll',
                    path: ':catchAll(.*)',
                    meta: {
                        uiType: 'Overlay',
                        module: 'readingPlan',
                    },
                    component: ReadingPlanList,
                },
            ],
        },
        {
            path: `/:locale(${langStore.supportedLocales.join('|')})/reading-plans`,
            meta: {
                uiType: 'Overlay',
                module: 'readingPlan',
            },
            props: {mode: 'overview',},
            component: ReadingPlanOverlay,
            children: [
                {
                    name: 'ReadingPlanOverlayML',
                    path: '',
                    meta: {
                        uiType: 'Overlay',
                        module: 'readingPlan',
                    },
                    component: ReadingPlanList,
                },
            ],
        },
        {
            path: `/:locale(${langStore.supportedLocales.join('|')})/reading-plan`,
            meta: {
                uiType: 'Overlay',
                module: 'readingPlan',
            },
            props: {mode: 'overview',},
            component: ReadingPlanOverlay,
            children: [
                {
                    name: 'ReadingPlanItemML',
                    path: ':name/:id(\\d+)',
                    meta: {
                        uiType: 'Overlay',
                        module: 'readingPlan',
                    },
                    component: ReadingPlanItem,
                },
            ],
        },
    ]
}