import fetch from '@/assets/js/src/util/fetch'
import {handleException, checkResponse, compareUrl,} from '@/assets/js/src/util/apiTools'
import {slugify,} from '@/assets/js/src/util/slugify'
import {
    getVerseFootnotes,
    getVerseReferences,
} from '@/assets/js/src/modules/text/_pinia/helper'
import {getDefaultHeaders,} from '@/assets/js/src/util/fetch/defaultHeaders'
import {useBooknamesStore,} from '@/assets/js/src/modules/bible/_pinia/booknames'
import {defineStore, getActivePinia,} from 'pinia'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'

const API_PREFIX_TEXT = '/api/text'
const API_GET_NOTES = '/api/notes'

export const SELECT_TYPE_SINGLE = 0
export const SELECT_TYPE_MULTIPLE = 1
export const SELECT_TYPE_RANGE = 2

const sleep = function (time) {
    if (import.meta.env.SSR) {
        return Promise.resolve()
    } else {
        return new Promise(resolve => setTimeout(resolve, time))
    }
}

export const useTextStore = defineStore('text', {
    state () {
        return {
            chapters: [],
            chaptersData: [],
            chapterCanonical: 0,
            abbreviations: [],
            selectedVerses: [],
            loading: false,
            last: '',
            resetScrollPosition: false,
            notes: [],
        }
    },
    getters: {
        isSelectedVerse: (state) => (verseNumber) => {
            return state.selectedVerses.includes(verseNumber)
        },
        selectedVersesAsArray: (state) => {
            let selectedArray = []
            let tmp = []

            if (state.selectedVerses.length > 0) {
                state.selectedVerses.forEach((verse) => {
                    if (tmp.length === 0 || verse - 1 === tmp[tmp.length - 1]) {
                        if (tmp.length > 1) {
                            tmp[1] = verse
                        } else {
                            tmp.push(verse)
                        }
                    } else {
                        selectedArray.push(tmp)
                        tmp = [ verse, ]
                    }
                })

                selectedArray.push(tmp)

                selectedArray = selectedArray.map((elem) => {
                    if (elem.length === 1) {
                        elem.push(elem[0])
                    }

                    return elem
                })
            }

            return selectedArray
        },
        selectedVersesAsSet: (state) => {
            if (state.chapters.length !== 0) {
                let chapterCanonical = state.chapters[0].chapter.canonical

                return state.selectedVerses.map((verseNumber) => chapterCanonical + parseInt(verseNumber))
            }
        },
        existsVerseSelection: (state) => state.selectedVerses.length > 0,
        allVerses: (state) => {
            if ((state.chapters[0]?.chapter?.verses || []).length === 0) {
                return [ [], ]
            }
            let lastVerse = state.chapters[0].chapter.verses[state.chapters[0].chapter.verses.length - 1]
            let number = lastVerse.verse
            if (typeof lastVerse.verse_end !== 'undefined') {
                number = lastVerse.verse_end
            }

            return [ [ 1, number, ], ]
        },
    },
    actions: {
        setSelectedVerses ({selectedVerses, selected,}) {
            if (selectedVerses.length > 0) {
                selectedVerses = selectedVerses.flat()
                let newSelection = []

                if (selected) {
                    newSelection.push(...[ ...new Set(selectedVerses), ])
                } else {
                    let oldSelection = globalThis.clone(this.selectedVerses)

                    oldSelection = oldSelection.filter((elem) => !selectedVerses.includes(elem))
                    newSelection.push(...new Set(oldSelection))
                }

                this.selectedVerses = [ ...new Set(newSelection), ].sort((a, b) => a - b)
            } else if (selected === false) {
                this.selectedVerses = []
            }
        },
        setChapters (payload) {
            if (payload && payload.length) {
                let bibleStore = useBibleStore(getActivePinia())

                this.chapterCanonical = payload[0].chapter.canonical
                payload = payload.filter((chapter) => chapter.chapter.bible)
                this.abbreviations = payload.map((chapter) => bibleStore.getBibleById(chapter.chapter.bible.id).abbreviation)
            }
            this.chapters = [ ...payload, ]
        },
        async setSelectedVerse ({verse, selected, type,}) {
            let verseNumber = verse.verse
            let selectedVerses = [ verseNumber, ]
            if (typeof verse.verse_end !== 'undefined') {
                selectedVerses.push(...[ ...Array(verse.verse_end - verseNumber).keys(), ].map(i => i + 1 + verseNumber))
            }
            /** AUSWAHL/ABWAHL OHNE GEDRÜCKTE TASTE **/
            if (type === SELECT_TYPE_SINGLE) {
                let isInRange = this.selectedVerses.filter((elem) => !selectedVerses.includes(elem))

                // Einzelvers-Abwahl, wenn in Range, verursacht eine Auswahl
                if (!selected && isInRange.length > 0) {
                    selected = true
                }

                this.setSelectedVerses({selectedVerses, selected,})

                /** AUSWAHL/ABWAHL MIT STRG TASTE / OS TASTE (WINDOWS/APPLE) **/
            } else if (type === SELECT_TYPE_MULTIPLE) {
                let oldSelection = []

                if (selected) {
                    oldSelection = globalThis.clone(this.selectedVerses)
                }

                oldSelection.push(verse.verse)

                if (typeof verse.verse_end !== 'undefined') {
                    // Alle bis verse_end
                    oldSelection.push(...[ ...Array(verse.verse_end - verseNumber).keys(), ].map(i => i + 1 + verseNumber))
                }

                this.setSelectedVerses({selectedVerses: oldSelection, selected,})
                /** AUSWAHL/ABWAHL SHIFT TASTE **/
            } else if (type === SELECT_TYPE_RANGE && this.selectedVerses.length === 0) {

                this.setSelectedVerses({selectedVerses, selected,})

            } else if (type === SELECT_TYPE_RANGE && this.selectedVerses.length !== 0) {
                let oldSelection = globalThis.clone(this.selectedVerses)

                if (typeof verse.verse_end !== 'undefined') {
                    verseNumber = verse.verse_end
                }

                selectedVerses = []
                let start = oldSelection[0]
                let end = verseNumber

                if (oldSelection[0] > verseNumber) {
                    start = verseNumber
                    end = oldSelection[0]
                }

                for (let i = start; i <= end; i++) {
                    selectedVerses.push(i)
                }

                this.setSelectedVerses({selectedVerses, selected: true,})
            }
        },

        async fetchChapterByRef ({path,}) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            let bibleStore = useBibleStore(activePinia)
            let langStore = useLangStore(activePinia)

            try {
                // Übersetzungen in einer Standard-Reihenfolge (Alphabetisch) bei der Api anfragen, um Cache zu minimieren
                let routePath = path.slice(1)
                let pathArray = routePath.split('/')
                let chapterRef = (pathArray[1] ?? '1001000').split(new RegExp(`(${langStore.sepChapters.join('|')})`, 'i'))
                let rightOrderAbbr = pathArray[0].split('.')

                pathArray[0] = [ ...rightOrderAbbr, ].sort()
                
                // Prüfe, ob im Vergleich evtl. an erste Stelle jetzt eine Bibel einer anderen Sprache steht und korrigiere
                try {
                    let originalLocale = bibleStore.bibles[decodeURIComponent(rightOrderAbbr[0])].locale
                    let sortedLocale = bibleStore.bibles[decodeURIComponent(pathArray[0][0])].locale
                    if (originalLocale !== sortedLocale) {
                        pathArray[0].splice(pathArray[0].indexOf(rightOrderAbbr[0]), 1)
                        pathArray[0].unshift(rightOrderAbbr[0])
                    }
                    // eslint-disable-next-line no-empty
                } catch (err) {

                }

                pathArray[0] = pathArray[0].join('.')
                
                // Bibel aus Route übernehmen
                bibleStore.setSelectedBibles(rightOrderAbbr.map((abbr) => decodeURIComponent(abbr)))

                this.loading = true

                // Auch RouteMeta laden
                let routeMetaStore = useRouteMetaStore(activePinia)
                let appUiStore = useAppUiStore(activePinia)
                if (!import.meta.env.SSR && !compareUrl('/' + routePath, routeMetaStore.canonical) && appUiStore.countRouteChange !== 0) {
                    let routePathArray = routePath.split('/')
                    if(routePathArray.length > 1 && !routePathArray[1]) {
                        routePathArray = [ routePathArray[0], ]
                    }

                    routeMetaStore.loadRouteMeta({
                        url: routePathArray.map((part) => decodeURIComponent(part)).join('/'),
                        type: 'text',
                        reload: false,
                    })
                }

                let compositedPath = `${pathArray[0]}${chapterRef[0] ? '/' + chapterRef[0] : '/1001000'}`

                if (this.last === compositedPath) {
                    this.loading = false

                    return {
                        reloaded: false,
                    }
                }

                let timeStart = Date.now()

                // Api-Request senden
                let useCache = true
                let apiResponse = await fetch({
                    url: `${API_PREFIX_TEXT}/${compositedPath}`,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                            useCache,
                        }),
                    },
                    useCache,
                    tags: pathArray[0].split('.').map((abbr) => `bible.${abbr}`),
                })
                if (timeStart - Date.now() < 300) {
                    await sleep(300)
                }

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse) && apiResponse.status !== 404) {
                    throw new Error('Fehler in Api-Response')
                }

                let chapters = rightOrderAbbr.map((abbreviation) => apiResponse.data[decodeURIComponent(abbreviation)])

                await this.calculateChapterData(chapters)

                // Kapitel in der richtigen Reihenfolge setzen
                await this.setChapters(chapters)

                this.last = compositedPath
                this.loading = false

            } catch (e) {
                handleException(e, false, false)
            }
        },

        async calculateChapterData (chapters) {
            let activePinia = getActivePinia()
            let maxColumnsArr = await this.calculateRowCount(chapters)
            let hasRowDiff = await this.hasRowDiff(maxColumnsArr)
            maxColumnsArr = await this.addMissingRows(maxColumnsArr)

            let chaptersData = []
            let booknamesLoaded = false


            for (let i = 0; i < chapters.length; i++) {
                let chapterData = {
                    'heading-slugs': {},
                    'verse-references': {},
                    'verse-references-google': {},
                    'verse-footnotes': {},
                    'rows': maxColumnsArr[i],
                    'rows-diff': hasRowDiff,
                }

                let footnoteOffset = 0

                for (let k = 0; k < chapters[i].chapter.verses.length; k++) {
                    // Überschriften slugify
                    if (typeof chapters[i].chapter.verses[k].heading !== 'undefined') {
                        chapterData['heading-slugs']['slug_' + chapters[i].chapter.verses[k].verse] = slugify(chapters[i].chapter.verses[k].heading)
                    }
                    if (typeof chapters[i].chapter.verses[k].subheading !== 'undefined') {
                        chapterData['heading-slugs']['subslug_' + chapters[i].chapter.verses[k].verse] = slugify(chapters[i].chapter.verses[k].subheading)
                    }

                    // Vers Referenzen
                    if (chapters[i].chapter.verses[k].references.length !== 0) {
                        let booknamesStore = useBooknamesStore(activePinia)

                        if (!booknamesLoaded && !Object.keys(booknamesStore.booknames).length) {
                            let langStore = useLangStore(activePinia)
                            let locale = langStore.locale
                            await booknamesStore.loadBooknames(locale)

                            // Warten bis Buchnamen geladen
                            if (!import.meta.env.SSR) {
                                while (Object.keys(booknamesStore.booknames).length === 0) {
                                    await new Promise(resolve => setTimeout(resolve))
                                }
                            }

                            booknamesLoaded = true
                        }

                        let {
                            verseReferences,
                            verseReferencesGoogle,
                        } = getVerseReferences({
                            verse: chapters[i].chapter.verses[k],
                            abbr: chapters[i].chapter.bible.abbreviation,
                            books: chapters[i].booknames,
                        })
                        verseReferences.length !== 0 && (chapterData['verse-references']['v' + chapters[i].chapter.verses[k].verse] = verseReferences)
                        verseReferencesGoogle && (chapterData['verse-references-google']['v' + chapters[i].chapter.verses[k].verse] = verseReferencesGoogle)
                    }

                    // Verse Footnotes
                    if (chapters[i].chapter.verses[k].footnotes.length) {
                        chapterData['verse-footnotes']['v' + chapters[i].chapter.verses[k].verse] = getVerseFootnotes({
                            verse: chapters[i].chapter.verses[k],
                            footnoteOffset,
                        })
                        footnoteOffset += chapters[i].chapter.verses[k].footnotes.length
                    }

                }

                chaptersData.push(chapterData)
            }

            this.chaptersData = chaptersData
        },

        // eslint-disable-next-line no-empty-pattern
        async calculateRowCount (chapters) {
            let rowsPerChapter = []
            for (let i = 0; i < chapters.length; i++) {
                let row = []
                if (chapters[i].chapter.verses !== 'undefined') {
                    chapters[i].chapter.verses.forEach((verse) => {
                        let start = 4 * verse.verse
                        let tmp = []
                        for (let j = start; j < start + 4; j++) {
                            tmp.push(j)
                        }

                        if (typeof verse.verse_end !== 'undefined') {
                            tmp[tmp.length - 1] = tmp[tmp.length - 1] + (verse.verse_end - verse.verse) * 4
                        }
                        row.push(tmp)
                    })
                }
                rowsPerChapter.push(row)
            }

            return rowsPerChapter

        },

        // eslint-disable-next-line no-empty-pattern
        async hasRowDiff (maxColumnsArr) {
            let rows = new Set()
            for (let i = 0; i < maxColumnsArr.length; i++) {
                let lastRow = maxColumnsArr[i][maxColumnsArr[i].length - 1]
                lastRow && rows.add(lastRow[3])
            }

            return rows.size > 1
        },

        async addMissingRows (maxColumnsArr) {
            let max = Math.max(...(maxColumnsArr.reduce((carry, elem) => {
                carry.push(elem.length - 1)

                return carry
            }, [])))

            for (let i = 0; i < maxColumnsArr.length; i++) {
                if (maxColumnsArr[i][max]) {
                    continue
                }

                let limit = max - maxColumnsArr[i].length + 1

                for (let j = 0; j < limit; j++) {
                    if (!(maxColumnsArr[i][maxColumnsArr[i].length - 1] || [])[3]) {
                        continue
                    }
                    let base = maxColumnsArr[i][maxColumnsArr[i].length - 1][3]
                    maxColumnsArr[i].push([ base + 1, base + 2, base + 3, base + 4, ])
                }
            }

            return maxColumnsArr
        },

        async fetchNotesByChapter ({chapterCanonical,}) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value

            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_GET_NOTES}/${chapterCanonical}`,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.notes = apiResponse.data || []

            } catch (e) {
                handleException(e, false, false)
            }
        },
    },
})
