import {getActivePinia,} from 'pinia'

const dismissNotification = async function ({position, readingPlan, ids,}) {
    const { useNotificationStore, } = await import(/* webpackChunkName: "appUi" */ '@/assets/js/src/pinia/notification')

    return useNotificationStore(getActivePinia()).dismissNotification({position, readingPlan, ids,})
}

export function useDismissNotification () {
    return {
        dismissNotification,
    }
}
