<template>
    <router-link
        :key="`mobile-menu-logo-${$vuetify.display.smAndDown}`"
        :to="{name: 'Home'}"
        title="bibleserver.com"
        aria-label="bibleserver.com"
        class="bs-nav__logo"
        :class="[{'bs-nav__logo--banner-visible': isBanner},{'bs-nav__logo--open':isMenu},{'bs-nav__logo--overlay':isOverlay},{'bs-home__logo': isHome},{'bs-home__logo--hidden': isHomeHidden},mainHeaderClasses]"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 454.1 49.8"
            fill="currentColor"
        >
            <use xlink:href="#logo"></use>
        </svg>
    </router-link>
</template>

<script>
export default {
    name: 'MenuLogo',
    props: {
        isBanner: {
            type: Boolean,
            default: false,
        },
        isMenu: {
            type: Boolean,
            default: false,
        },
        isOverlay: {
            type: Boolean,
            default: false,
        },
        isHome: {
            type: Boolean,
            default: true,
        },
        isHomeHidden: {
            type: Boolean,
            default: false,
        },
        mainHeaderClasses: {
            type: Object,
            default: ()=>({}),
        },
    },
}
</script>

<style lang="scss">
    .bs-app .bs-nav {
        &__logo {
            position: fixed;
            top: 31px;
            right: auto;
            left: 91px;
            z-index: 9993;
            color: white;

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                top: 23px;
                left: 85px;
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                top: 7px;
                left: 55px;
                max-width: 0;
                overflow: hidden;
                opacity: 0;
                transition: all .2s ease-in;
            }

            &--banner-visible {
                top: map-deep-get($bs-xl, banner, height) + 30;

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    top: map-deep-get($bs-xl, banner, height) + 22;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    top: map-deep-get($bs-xs, banner, height) + 6;
                }
            }

            > svg {
                width: 190px !important;
                height: 36px !important;
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                &--open {
                    @include cache((
                        max-width: 300px,
                        opacity: 1,
                    ));
                }

                &--overlay {
                    @include cache((
                        max-width: 300px,
                        opacity: 1,
                    ));
                }
            }

            &.main-header {
                &--unpinned {
                    top: -#{map-deep-get($bs-xl, header, height)};
                    transform: translateY(-#{map-deep-get($bs-xl, header, height)});
                    transition: .5s;
                }

                &--pinned {
                    transition: .5s;
                }
            }
        }
    }
</style>
