import {getActivePinia,} from 'pinia'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'

export function getDefaultHeaders ({rootState, useCache,}) {
    let activePinia = getActivePinia()
    let langStore = useLangStore(activePinia)
    let headers = {}
    
    if (import.meta.env.SSR) {
        headers['x-user-agent'] = rootState.xUserAgent
    }

    headers['X-Requested-With'] = 'XMLHttpRequest'
    headers['x-locale'] = langStore.locale
    
    if(!useCache) {
        let userStore = useUserStore(activePinia)
        let jwtToken = userStore.jwt

        if (typeof jwtToken !== 'undefined' && jwtToken.trim() !== '') {
            headers['Authorization'] = 'Bearer ' + jwtToken
        }
    }

    return headers
}