import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'

export default function (pinia) {
    let userStore = useUserStore(pinia)
    let appUiStore = useAppUiStore(pinia)

    return [
        {
            name: 'ActivityRoute',
            path: '/history',
            meta: {
                uiType: 'Overlay',
                userContent: true,
            },
            beforeEnter: (to, from, next) => {
                if (!userStore.loggedIn) {
                    appUiStore.userContentRoute = {
                        route: to,
                        key: 'async.menu.history',
                    }
                    appUiStore.menu = false
                    next({path: '/login',})
                } else {
                    next()
                }
            },
            component: () => import(/* webpackChunkName: "ActivityOverlays" */ '@/assets/js/src/modules/activity/_views/ActivityOverlay.vue'),
            children: [],
        },
    ]
}
