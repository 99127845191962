import {useConsentStore,} from "@/assets/js/src/modules/imprint/_pinia/consent"

function loadContainer (src) {
    window._mtm = window._mtm || []
    window._mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start',})
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0]
    g.async = true; g.src = src; s.parentNode.insertBefore(g,s)
}

export function loadDefaultContainer () {
    // eslint-disable-next-line no-undef
    loadContainer(__BS_MTM_DEFAULT__)
}

export function loadStatisticContainer () {
    // eslint-disable-next-line no-undef
    loadContainer(__BS_MTM_ANALYTICS__)
}

export function loadMarketingContainer () {
    // eslint-disable-next-line no-undef
    loadContainer(__BS_MTM_MARKETING__)
}

export function initConsent (pinia) {
    if(!window.Cookiebot || !window.Cookiebot.consent){
        return
    }

    let consentStore = useConsentStore(pinia)
    consentStore.consent = globalThis.clone(window.Cookiebot.consent)

    if (window.Cookiebot.consent.statistics) {
        loadStatisticContainer()
        window._paq && window._paq.push([ 'setCustomDimension', 3, 'Consented', ])
    } else {
        window._paq && window._paq.push([ 'setCustomDimension', 3, window.Cookiebot.declined ? 'Declined' : 'Declined (not set)', ])
    }

    if (window.Cookiebot.consent.marketing) {
        loadMarketingContainer()
    }
}
