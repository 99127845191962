export default async function ({url, options, loading, useCache, tags, includeTagsInId,}) {
    if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
        url && console.debug(url)
        options && console.debug(options)
    }

    options = options || {}
    if (typeof options.headers === 'undefined') {
        options.headers = {}
    }

    tags = tags || []
    includeTagsInId = includeTagsInId || false

    if (typeof loading == 'undefined') {
        loading = true
    }
    useCache = (import.meta.env.PROD && useCache) || false

    if (!import.meta.env.SSR) {
        const {default: clientFetch,} = await import('@/assets/js/src/util/fetch/clientFetch')

        return clientFetch({url, options, loading, useCache, tags, includeTagsInId,})
    } else {
        let globalThis = (1, eval)('this')
        let apiCallId = hashCode(url)
        globalThis.log('tap_' + apiCallId)
        globalThis.log('log_renderToString')

        return import('@/assets/js/src/util/fetch/nodeCommandFetch')
            .then(({default: nodeCommandFetch,}) => {
                return nodeCommandFetch({url, options, useCache, tags, includeTagsInId,}).catch((err)=>{
                    console.error(err)
                }).finally(()=>{
                    globalThis.log('stop_' + apiCallId)
                })
            })
    }
}

let fetchCount = 0

const hashCode = (str) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = (hash << 5) - hash + char
        hash = hash & hash
    }

    return 'api->php->' + hash + '_' + fetchCount++
}
