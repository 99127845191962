import {ord,b64DecodeUnicode,b64EncodeUnicode,} from './strTools'

let initVars

const setup = () => {
    if(!initVars) {
        let so885KHv0vLocal = (globalThis.so885KHv0v || '').split('_')
        initVars = {
            max: parseInt(so885KHv0vLocal[0]),
            shift: parseInt(so885KHv0vLocal[1]),
        }
    }

    return initVars
}

export function encrypt (plain_text, localShift) {
    let {max, shift,} = setup()
    localShift = localShift || shift
    let text = [ ...plain_text, ]
        .map((char) => {
            return ord(char)
        })
        .map((mpInt) => {
            return (mpInt + localShift) % max
        })
        .map((mbInt) => {
            return String.fromCodePoint(mbInt)
        })
        .join('')

    return b64EncodeUnicode(text)
}

export function decrypt (encrypted_text, localShift) {
    let {max, shift,} = setup()
    localShift = localShift || shift
    let text = b64DecodeUnicode(encrypted_text)

    return [ ...text, ]
        .map((char) => {
            return ord(char)
        })
        .map((mpInt) => {
            if (mpInt - localShift <= 0) {
                return max + (mpInt - localShift)
            }

            return mpInt - localShift
        })
        .map((mbInt) => {
            return String.fromCodePoint(mbInt)
        })
        .join('')
}
