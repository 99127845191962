import {defineStore, getActivePinia,} from 'pinia'
import fetch from '@/assets/js/src/util/fetch'
import {getDefaultHeaders,} from '@/assets/js/src/util/fetch/defaultHeaders'
import {checkResponse, commitMessage, handleException,} from '@/assets/js/src/util/apiTools'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {getInitializedApp,} from '@/assets/js/src/util/appRef'
import {useLangActionsStore,} from '@/assets/js/src/modules/lang/_pinia/langActions'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useNavigationStore,} from '@/assets/js/src/modules/navigation/_pinia/navigation'

const API_REGISTER_USER = '/api/register'
const API_LOGIN_USER = '/api/login'
const API_PASSWORD_RESET = '/api/password_reset'
const API_LOGOUT_USER = '/api/logout'
const API_UPDATE_USER_PROFILE = '/api/profile'
const API_GET_USER_NEWSLETTER = '/api/profile/newsletter'
const FORCE_PASSWORD_RESET = 'password_reset'
const API_BETA_FEATURE = '/api/beta'

export const useUserActionsStore = defineStore('userActions', {
    actions: {
        logoutUser: async function () {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value

            // SSR für logout route umgehen
            if (import.meta.env.SSR) {
                throw new Error('Logout Route should not be called in ssr')
            }

            try {
                let userStore = useUserStore(activePinia)
                if (!userStore.loggedIn) {
                    return
                }

                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_LOGOUT_USER}/${userStore.user.id}`,
                    options: {
                        method: 'DELETE',
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                // JWT Token im Store löschen
                userStore.jwt = ''

                if (!import.meta.env.SSR) {
                    // Lösche auch den Sessionstorage im client
                    try {
                        window.sessionStorage.clear()
                    } catch (err) {
                        // eslint-disable-next-line no-empty
                    }

                    setTimeout(() => {
                        // Headroom einblenden
                        if (window.MAIN_HEADROOM) {
                            window.MAIN_HEADROOM.pin()
                        }

                        // User-Object im Store löschen
                        userStore.resetUser()
                    })
                } else {
                    userStore.resetUser()
                }
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e)
            }
        },

        async registerUser ({userData,promo,}) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            
            try {
                // Promotion-Code mitsenden
                if(cookieManager.get('promo') || promo) {
                    userData = {
                        ...userData,
                        promo: cookieManager.get('promo') ?? promo,
                    }
                }

                // Api-Request senden
                let apiResponse = await fetch({
                    url: API_REGISTER_USER,
                    options: {
                        method: 'POST',
                        body: JSON.stringify(userData),
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                if (apiResponse.data.token) {
                    return this.loginUser({
                        user: null,
                        stayloggedin: true,
                        signUpResponse: apiResponse,
                    })
                }

                // Promotion-Code löschen
                if(cookieManager.get('promo')) {
                    cookieManager.remove('promo')
                }

                // Zeige Erfolgsmeldung
                commitMessage(apiResponse)

                return true
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, false, true)
            }
        },

        async loginUser ({user, stayloggedin, signUpResponse,}) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            
            try {
                // Api-Request senden
                let apiResponse
                if (signUpResponse) {
                    apiResponse = signUpResponse
                } else {
                    apiResponse = await fetch({
                        url: API_LOGIN_USER,
                        options: {
                            method: 'POST',
                            body: JSON.stringify({
                                ...user,
                                stayloggedin,
                            }),
                            headers: getDefaultHeaders({
                                rootState,
                            }),
                        },
                    })

                    // Api-Response prüfen
                    if (checkResponse(apiResponse)) {
                        throw new Error('Fehler in Api-Response')
                    }
                }

                // Der Nutzer braucht ein neues Passwort
                if (apiResponse.data === FORCE_PASSWORD_RESET) {
                    return false
                }

                let userStore = useUserStore(activePinia)
                
                // Speichere das JWT Token aus der Antwort im Store
                userStore.jwt = apiResponse.data.token.jwt_token
                cookieManager.set('bs-login-reminder', Date.now(), {secure: true, expires: 365,})

                // Ändere Dimension
                let {app,pinia,} = getInitializedApp(rootState.appId)
                app.$matomo && app.$matomo.setCustomDimension( 1, 'Member')

                userStore.setUser(apiResponse.data.user)

                // Ändere evtl. die Sprache
                let langActionsStore = useLangActionsStore(pinia)
                await langActionsStore.setClientLocale({
                    id: apiResponse.data.user.language,
                    updateUserLocally: false,
                })

                // Lade auch die letzte Activity
                let navigationStore = useNavigationStore(activePinia)
                await navigationStore.fetchLastActivity()

                // Zeige Erfolgsmeldung
                commitMessage(apiResponse)

                return true
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, false, true)
            }
        },

        async resetPassword (email) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: API_PASSWORD_RESET,
                    options: {
                        method: 'POST',
                        body: JSON.stringify({email,}),
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                // Zeige Erfolgsmeldung
                commitMessage(apiResponse)
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, true)
            }
        },

        async updateProfile ({userData, snackbar, updateLanguageLocally,}) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            try {

                // Action nur bei angemeldeteten Nutzern sinnvoll
                let userStore = useUserStore(activePinia)
                if (!userStore.loggedIn) {
                    return
                }

                // Standardwert für changeLang setzen
                if (typeof updateLanguageLocally === 'undefined') {
                    updateLanguageLocally = true
                }

                // Extract some porperties from userData, ignore hash and roles!
                let {id, language, options, hash, roles, beta, ...remainingUserData} = userData

                let languageUpdate = userStore.user.language !== language

                // Konvertiere Options zu Json-String
                if (typeof options !== 'undefined') {
                    options = JSON.stringify(options)
                }

                // Lösche den Nicknamen, weil UserProfileType es nicht kennt
                delete remainingUserData.nickname

                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_UPDATE_USER_PROFILE}/${id}`,
                    options: {
                        method: 'PATCH',
                        body: JSON.stringify({
                            language,
                            options,
                            ...remainingUserData,
                        }),
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                let {user, jwt,} = apiResponse.data
                userStore.setUser(user, jwt)

                // Ändere evtl. die Sprache
                if (languageUpdate && updateLanguageLocally) {
                    let {pinia,} = getInitializedApp(rootState.appId)
                    let langActionsStore = useLangActionsStore(pinia)
                    await langActionsStore.setClientLocale({id: language, updateUserLocally: false,})
                }

                if (snackbar) {
                    commitMessage(apiResponse)
                }

                return true
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e)

                return false
            }
        },

        async deleteProfile (userId) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            
            try {

                let userStore = useUserStore(activePinia)
                if (!userStore.loggedIn) {
                    return
                }

                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_UPDATE_USER_PROFILE}/${userId}`,
                    options: {
                        method: 'DELETE',
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                // JWT Token im Store löschen
                userStore.jwt = ''

                if (!import.meta.env.SSR) {
                    // Lösche auch den Sessionstorage im client
                    setTimeout(() => {
                        try {
                            window.sessionStorage.removeItem('options')
                            window.sessionStorage.clear()
                        } catch (err) {
                            // eslint-disable-next-line no-empty
                        }
                    })
                }

                setTimeout(() => {
                    // User-Object im Store löschen
                    userStore.resetUser()
                })

                commitMessage(apiResponse)
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e)
            }
        },

        async updateLanguageProfile ({id, updateLanguageLocally,}) {
            let activePinia = getActivePinia()
            let userStore = useUserStore(activePinia)
            
            // Prüfe, ob Sprache geändert werden muss
            let languageUpdate = userStore.user.language !== id

            if (languageUpdate) {
                userStore.user.language = id
                await this.updateProfile({
                    userData: globalThis.clone(userStore.user),
                    snackbar: false,
                    updateLanguageLocally,
                })
            }
        },

        async fetchNewsletter (userId) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            try {
                let userStore = useUserStore(activePinia)
                if (!userStore.loggedIn || typeof userId === 'undefined') {
                    return
                }

                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_GET_USER_NEWSLETTER}/${userId}`,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                return apiResponse.data
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, false, false)
            }
        },

        async subscribeBetaFeasture (feature) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value

            try {
                let userStore = useUserStore(activePinia)

                let apiResponse = await fetch({
                    url: API_BETA_FEATURE,
                    options: {
                        method: 'POST',
                        body: JSON.stringify({
                            feature,
                        }),
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                userStore.setUser(apiResponse.data)
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, false, false)
            }
        },
    },
})