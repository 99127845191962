import {notifyError,ServiceWorkerRegError,} from '@/assets/js/src/util/bugsnag'
/**
 * Die folgenden Variabblen-Deklarationen sind für die gesamte Runtime (Server und Client) relevant
 */

let globalThis = (1, eval)('this')
if (globalThis.runningMain) throw new Error('double execution prevented')
globalThis.runningMain = true

globalThis.isServer = import.meta.env.SSR

// Browser environment sniffing
globalThis.inBrowser = typeof window !== 'undefined'
globalThis.inWeex = typeof WXEnvironment !== 'undefined' && !!WXEnvironment.platform
globalThis.weexPlatform = globalThis.inWeex && WXEnvironment.platform.toLowerCase()
globalThis.UA = globalThis.inBrowser && window.navigator.userAgent.toLowerCase()
globalThis.isIE = globalThis.UA && /msie|trident/.test(globalThis.UA)
globalThis.isIE9 = globalThis.UA && globalThis.UA.indexOf('msie 9.0') > 0
globalThis.isEdge = globalThis.UA && globalThis.UA.indexOf('edge/') > 0
globalThis.isAndroid = (globalThis.UA && globalThis.UA.indexOf('android') > 0) || (globalThis.weexPlatform === 'android')
globalThis.isIOS = (globalThis.UA && /iphone|ipad|ipod|ios/.test(globalThis.UA)) || (globalThis.weexPlatform === 'ios')
globalThis.isChrome = globalThis.UA && /chrome\/\d+/.test(globalThis.UA) && !globalThis.isEdge
globalThis.isPhantomJS = globalThis.UA && /phantomjs/.test(globalThis.UA)
globalThis.isFF = globalThis.UA && globalThis.UA.match(/firefox\/(\d+)/)
globalThis.isSafari = globalThis.UA && globalThis.UA.indexOf('safari') !== -1 && globalThis.UA.indexOf('chrome') === -1
globalThis.isTouchDevice = globalThis.inBrowser && (function () {
    let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
    let mq = function (query) {
        return window.matchMedia(query).matches
    }
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true
    }

    let query = [ '(', prefixes.join('touch-enabled),('), 'heartz', ')', ].join('')

    return mq(query)
}())
// Detect Mac
globalThis.isMac = globalThis.inBrowser && navigator.platform.toLowerCase().indexOf('mac') !== -1

if (import.meta.env.SSR) {
    // Deklarationen für den Server
    globalThis.dd = function (...args) {
        if (args[0] instanceof Error) {
            notifyError(args[0])
        }
        if (import.meta.env.NODE_ENV === 'development') {
            process.on('exit', () => {
                console.error('exit dd', ...args)
            })
            process.exit(1)
        }
    }

    globalThis.errorLog = function (...args) {
        if (import.meta.env.DEV) {
            try {
                throw new Error()
            } catch (e) {
                console.error(e.stack)
            }
        }

        console.error(...args)
    }

    globalThis.log = function (...args) {
        // Nur im dev-Environment loggen
        if (import.meta.env.DEV) {
            if (args[0].startsWith('tap_')) {
                let label = args[0].replace('tap_','')
                console.log(label, 'start')
                console.time(label)

                return
            }

            if (args[0].startsWith('stop_')) {
                let label = args[0].replace('stop_','')
                console.log(label, 'end')
                console.timeEnd(label)

                return
            }

            if (args[0].startsWith('log_')) {
                let label = args[0].replace('log_','')
                console.log(label, 'log')
                console.timeLog(label)

                return
            }

            console.log(...args)
        }
    }

    globalThis.errorPage = function (statusCode, ctx) {
        ctx.errorPage = statusCode
    }
} else {
    if ('serviceWorker' in navigator && globalThis.hasLocalStorageAccess) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/sw.js')
                .catch(registrationError => {
                    notifyError(new ServiceWorkerRegError(registrationError?.message))
                })
        })
    }

    // Declare global Logging
    globalThis.log = function (...args) {
        // StopWatch-Logging Clientseitig ignorieren
        if (
            args.length === 1 &&
            typeof args[0] === 'string' &&
            (args[0].startsWith('tap_') || args[0].startsWith('stop_'))
        )
            return

        // Nur im dev-Environment loggen
        if (import.meta.env.DEV) {
            console.warn(...args)
        }

    }

    // Polyfill for IE
    if (!globalThis.fetch) {
        require('whatwg-fetch')
    }

    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
            Element.prototype.webkitMatchesSelector
    }

    if (!globalThis.IntersectionObserver) {
        require('intersection-observer')
    }
}

globalThis.clone = obj => {
    return JSON.parse(JSON.stringify(obj))
}

Array.prototype.swap = function (x, y) {
    var b = this[x]
    this[x] = this[y]
    this[y] = b

    return this
}

function at (n) {
    // ToInteger() abstract op
    n = Math.trunc(n) || 0
    // Allow negative indexing from the end
    if (n < 0) n += this.length
    // OOB access is guaranteed to return undefined
    if (n < 0 || n >= this.length) return undefined

    // Otherwise, this is just normal property access
    return this[n]
}

const TypedArray = Reflect.getPrototypeOf(Int8Array)
for (const C of [ Array, String, TypedArray, ]) {
    Object.defineProperty(C.prototype, "at",
        { value: at,
            writable: true,
            enumerable: false,
            configurable: true, })
}
