import {
    mdiPageFirst,
    mdiPageLast,
    mdiMenu,
    mdiHistory,
    mdiCog,
    mdiFilter,
    mdiClose,
    mdiTextBoxMultiple,
    mdiPin,
    mdiContentCopy,
    mdiPrinter,
    mdiHelpCircle,
    mdiHeartOutline,
    mdiCheckboxBlankCircle,
    mdiChevronUp,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronRight,
    mdiUnfoldMoreVertical,
    mdiArrowLeft,
    mdiArrowRight,
    mdiArrowUp,
    mdiDragVertical,
    mdiCheckboxMarkedCircle,
    mdiBookOpenPageVariant,
    mdiMenuDown,
    mdiPlayCircleOutline,
    mdiCheckboxMarked,
    mdiCheckboxBlankOutline,
    mdiDelete,
    mdiPlay,
    mdiPause,
    mdiPlayCircle,
    mdiPauseCircle,
    mdiCloseCircle,
    mdiTag,
    mdiKeyboardReturn,
    mdiSortAlphabeticalVariant,
    mdiFormatListBulletedSquare,
    mdiPlusCircle,
    mdiMinusCircle,
    mdiMagnify,
    mdiPencil,
    mdiRadioboxBlank,
    mdiRadioboxMarked,
    mdiOpenInNew,
    mdiApps,
    mdiViewList,
    mdiCalendar,
    mdiStar,
    mdiStarOutline,
    mdiInformationOutline,
    mdiCheck,
    mdiHeart,
    mdiMenuUp,
    mdiAlertCircle,
    mdiBellAlert,
    mdiBellRing,
    mdiBell,
    mdiBookOpen,
    mdiImageMultiple,
    mdiPlayBoxMultiple,
    mdiAccountCircle,
    mdiCamera,
    mdiAlert,
    mdiNoteMultiple,
    mdiTagMultiple,
    mdiViewColumn,
    mdiFirefox,
    mdiOpera,
    mdiMicrosoftInternetExplorer,
    mdiGoogleChrome,
    mdiAppleSafari,
    mdiMicrosoftEdge,
    mdiThumbUp,
    mdiThumbDown,
    mdiLightbulbOn,
    mdiDownload,
    mdiSwapHorizontal,
    mdiEye,
    mdiEyeOff,
    mdiCalendarCheck,
    mdiCalendarMultipleCheck,
    mdiVolumeLow,
    mdiVolumeHigh,
    mdiVolumeMedium,
    mdiVolumeOff,
    mdiReload,
    mdiSync,
    mdiSyncOff,
    mdiChat,
    mdiRobot,
    mdiSend,
    mdiDotsVertical,
    mdiChatPlus,
} from '@mdi/js'
import SvgIcon from "@/assets/js/src/components/SvgIcon.vue"
import {h,} from "vue"

const library = {
    mdiMenu,
    mdiHistory,
    mdiSettings: mdiCog,
    mdiFilter,
    mdiClose,
    mdiLibraryBooks: mdiTextBoxMultiple,
    mdiPin,
    mdiHeart,
    mdiHeartOutline,
    mdiFacebook: {
        viewBox: '0 0 35 35',
        content: `<path d="M25.34,1.66H9.66a8,8,0,0,0-8,8V25.34a8,8,0,0,0,8,8h5.39V22.08h-4V17.53h4V14.07C15.05,10.13,17.39,8,21,8a25.3,25.3,0,0,1,3.5.29v3.88h-2A2.26,2.26,0,0,0,20,14.58v3h4.36l-.71,4.55H20V33.34h5.39a8,8,0,0,0,8-8V9.66A8,8,0,0,0,25.34,1.66Z"/>`,
    },
    mdiTwitter: {
        viewBox: '0 0 35 35',
        content: `<path d="M9.66 1.66H25.34C26.3906 1.66 27.4309 1.86693 28.4015 2.26896C29.3721 2.671 30.254 3.26028 30.9969 4.00315C31.7397 4.74601 32.329 5.62793 32.731 6.59853C33.1331 7.56914 33.34 8.60942 33.34 9.66V25.34C33.34 26.3906 33.1331 27.4309 32.731 28.4015C32.329 29.3721 31.7397 30.254 30.9969 30.9969C30.254 31.7397 29.3721 32.329 28.4015 32.731C27.4309 33.1331 26.3906 33.34 25.34 33.34H9.66C7.53827 33.34 5.50344 32.4971 4.00315 30.9969C3.26028 30.254 2.671 29.3721 2.26896 28.4015C1.86693 27.4309 1.66 26.3906 1.66 25.34V9.66C1.66 7.53827 2.50285 5.50344 4.00315 4.00315C5.50344 2.50285 7.53827 1.66 9.66 1.66ZM26.5909 7.5H23.8636L18.4091 14.3182L13.8283 7.5H7.5L15.0613 18.4385L7.89494 27.5H10.9636L16.4946 20.5082L21.3284 27.5H27.5L19.7727 16.5909L27.0455 7.5H26.5909ZM23.8742 25.4692H22.1748L11.0813 9.4241H12.9049L23.8742 25.4692Z"/>`,
    },
    mdiWhatsapp: {
        viewBox: '0 0 35 35',
        content: `<path d="M17.56,7.61A9.85,9.85,0,0,0,9.21,22.68l.24.38-1,3.63,3.73-1,.36.21a9.71,9.71,0,0,0,5,1.37,9.84,9.84,0,0,0,0-19.68Zm5.78,14.07a3.06,3.06,0,0,1-2,1.41A4.07,4.07,0,0,1,19.48,23a12.7,12.7,0,0,1-1.68-.63,13.42,13.42,0,0,1-5.08-4.46,5.92,5.92,0,0,1-1.19-3,3.42,3.42,0,0,1,1-2.48,1.12,1.12,0,0,1,.79-.37l.56,0c.19,0,.43-.06.67.51s.84,2.06.92,2.2a.53.53,0,0,1,0,.51,1.8,1.8,0,0,1-.3.49,3.88,3.88,0,0,1-.44.52.47.47,0,0,0-.13.6,8.71,8.71,0,0,0,1.65,2.05,7.79,7.79,0,0,0,2.38,1.47c.29.15.46.12.64-.08s.74-.86.93-1.16.39-.24.67-.14,1.72.81,2,1a1.53,1.53,0,0,1,.58.32A2.52,2.52,0,0,1,23.34,21.68Z"/><path d="M25.34,1.66H9.66a8,8,0,0,0-8,8V25.34a8,8,0,0,0,8,8H25.34a8,8,0,0,0,8-8V9.66A8,8,0,0,0,25.34,1.66ZM17.55,29.3a11.88,11.88,0,0,1-5.66-1.45L5.62,29.5l1.67-6.14A11.83,11.83,0,1,1,17.55,29.3Z"/>`,
    },
    mdiInstagram: {
        viewBox: '0 0 35 35',
        content: `<path d="M22.73,8H12.27A4.27,4.27,0,0,0,8,12.27V22.73A4.27,4.27,0,0,0,12.27,27H22.73A4.27,4.27,0,0,0,27,22.73V12.27A4.27,4.27,0,0,0,22.73,8ZM17.5,23.44a5.94,5.94,0,1,1,5.94-5.94A5.94,5.94,0,0,1,17.5,23.44Zm6.24-10.69a1.49,1.49,0,1,1,0-3,1.49,1.49,0,0,1,0,3Z"/><path d="M17.5,13.94a3.56,3.56,0,1,0,3.56,3.56A3.56,3.56,0,0,0,17.5,13.94Z"/><path d="M25.34,1.66H9.66a8,8,0,0,0-8,8V25.34a8,8,0,0,0,8,8H25.34a8,8,0,0,0,8-8V9.66A8,8,0,0,0,25.34,1.66Zm4,20.83a6.9,6.9,0,0,1-6.89,6.89h-10a6.9,6.9,0,0,1-6.89-6.89v-10a6.89,6.89,0,0,1,6.89-6.89h10a6.9,6.9,0,0,1,6.89,6.89Z"/>`,
    },
    mdiPageFirst,
    mdiPageLast,
    mdiContentCopy,
    mdiPrinter,
    mdiHelpCircle,
    mdiChevronUp,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronRight,
    mdiUnfoldMoreVertical,
    mdiArrowLeft,
    mdiArrowRight,
    mdiArrowUp,
    mdiCheckboxBlankCircle,
    mdiCheckboxMarkedCircle,
    mdiCheckboxMarked,
    mdiCheckboxBlankOutline,
    mdiRadioboxBlank,
    mdiRadioboxMarked,
    mdiBookOpenPageVariant,
    mdiMenuDown,
    mdiPlayCircleOutline,
    mdiPlayCircle,
    mdiPauseCircle,
    mdiDelete,
    mdiCloseCircle,
    mdiTag,
    mdiDragVertical,
    mdiKeyboardReturn,
    mdiSortAlphabetical: mdiSortAlphabeticalVariant,
    mdiFormatListBulletedSquare,
    mdiPlusCircle,
    mdiMinusCircle,
    mdiMagnify,
    mdiPencil,
    mdiOpenInNew,
    mdiApps,
    mdiViewList,
    mdiCalendar,
    mdiStar,
    mdiStarOutline,
    mdiInformationOutline,
    mdiCheck,
    mdiMenuUp,
    mdiAlertCircle,
    mdiBellAlert,
    mdiBellRing,
    mdiBell,
    mdiBookOpen,
    mdiImageMultiple,
    mdiLibraryVideo: mdiPlayBoxMultiple,
    mdiAccountCircle,
    mdiCamera,
    mdiAlert,
    mdiNoteMultiple,
    mdiTagMultiple,
    mdiViewColumn,
    mdiFirefox,
    mdiOpera,
    mdiInternetExplorer: mdiMicrosoftInternetExplorer,
    mdiGoogleChrome,
    mdiAppleSafari,
    mdiEdge: mdiMicrosoftEdge,
    mdiThumbUp,
    mdiThumbDown,
    mdiLightbulbOn,
    mdiDownload,
    mdiSwapHorizontal,
    mdiEye,
    mdiEyeOff,
    mdiCalendarCheck,
    mdiCalendarMultipleCheck,
    textFormat: 'M5 17v2h14v-2H5zm4.5-4.2h5l.9 2.2h2.1L12.75 4h-1.5L6.5 15h2.1l.9-2.2zM12 5.98L13.87 11h-3.74L12 5.98z',
    customCheckboxBlankCircleOff: 'M20.2,17.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.5-4.5-10-10-10C9.9,2,7.9,2.7,6.3,3.8 M2.3,2.3L1,3.5l2.8,2.8C2.7,7.9,2,9.9,2,12c0,5.5,4.5,10,10,10c2.1,0,4.1-0.7,5.7-1.8l2.8,2.8l1.3-1.3L2.3,2.3',
    disableComparison: 'M7.6,5l1.3,1.3V5H7.6z M10,5v2.3l5,5V5H10z M16,5v8.4l4.7,4.7H21V5H16z M1.9,2L0.5,3.4l3.4,3.4v11.3h5v-6.3l1,1v5.3h5v-0.2 l1.4,1.4l3.7,3.7l1.4-1.4L18,18.1h0l-2-2v0l-1-1v0l-5-5v0l-1-1v0l-4-4h0L1.9,2z',
    mdiVolumeLow,
    mdiVolumeHigh,
    mdiVolumeMedium,
    mdiVolumeOff,
    mdiPlay,
    mdiPause,
    mdiReload,
    mdiSync,
    mdiSyncOff,
    mdiChat,
    mdiRobot,
    mdiSend,
    mdiDotsVertical,
    mdiChatPlus,
}

const VUETIFY_ICONS_MDI = {
    'complete': 'mdi-check',
    'cancel': 'mdi-close-circle',
    'close': 'mdi-close',
    'delete': 'mdi-close-circle',
    'clear': 'mdi-close',
    'success': 'mdi-check-circle',
    'info': 'mdi-information',
    'warning': 'mdi-exclamation',
    'error': 'mdi-alert',
    'prev': 'mdi-chevron-left',
    'next': 'mdi-chevron-right',
    'checkboxOn': 'mdi-checkbox-marked',
    'checkboxOff': 'mdi-checkbox-blank-outline',
    'checkboxIndeterminate': 'mdi-minus-box',
    'delimiter': 'mdi-circle',
    'sort': 'mdi-arrow-up',
    'expand': 'mdi-chevron-down',
    'menu': 'mdi-menu',
    'subgroup': 'mdi-menu-down',
    'dropdown': 'mdi-menu-down',
    'radioOn': 'mdi-radiobox-marked',
    'radioOff': 'mdi-radiobox-blank',
    'edit': 'mdi-pencil',
    'ratingEmpty': 'mdi-star-outline',
    'ratingFull': 'mdi-star',
    'ratingHalf': 'mdi-star-half',
}

const icons = {}

Object.keys(VUETIFY_ICONS_MDI).forEach((iconName) => {
    if (typeof icons[iconName] === 'undefined') {
        icons[iconName] = {
            component: SvgIcon,
            props: {
                path: library[VUETIFY_ICONS_MDI[iconName].replace(/-([a-z])/g, function (g) {
                    return g[1].toUpperCase()
                })],
            },
        }
    }
})

Object.keys(library).forEach((iconName) => {
    if (typeof icons[iconName] === 'undefined') {
        let props
        if(typeof library[iconName] === 'object') {
            props = library[iconName]
        } else {
            props = {
                path: library[iconName],
            }
        }
        icons[iconName] = {
            component: SvgIcon,
            props,
        }
    }
})

const iconSet = {
    component: (props) => {
        let key
        let {tag,icon, ... passthrough} = props
        if(icon.startsWith('mdi-')) {
            key = icon.replace(/-([a-z])/g, function (g) {
                return g[1].toUpperCase()
            })
        } else {
            key = icon.substring(15)
        }

        return h(tag === 'button' ? 'button' : 'i', passthrough, [
            h(SvgIcon, icons[key].props),
        ])
    },
}

export {
    iconSet,
    icons,
}