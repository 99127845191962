import {VProgressCircular,} from 'vuetify/components'
import {getActivePinia,} from 'pinia'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {h, onUnmounted,} from 'vue'

export function useLoading ({type,}) {
    return () => {
        let autocompleteStore = useAutocompleteStore(getActivePinia())
        autocompleteStore.setLoading({type, status: true,})
        onUnmounted(()=>{
            autocompleteStore.setLoading({type, status: false,})
        })

        return () => {
            return h(
                'div',
                { class: 'bs-loading-component', },
                h(VProgressCircular, {
                    size: '50',
                    color: 'primary',
                    indeterminate: true,
                    class: 'bs-loading-component__progress',
                })
            )
        }
    }
}