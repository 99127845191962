import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useUserActionsStore,} from '@/assets/js/src/modules/user/_pinia/userActions'

export default function (pinia) {
    let userStore = useUserStore(pinia)
    let userActionsStore = useUserActionsStore(pinia)

    return [
        {
            name: 'SignRoute',
            path: '/:signAction(login|register)',
            component: () => import(/* webpackChunkName: "User" */ '@/assets/js/src/modules/user/_views/SignOverlay.vue'),
            meta: {uiType: 'Overlay',},
            beforeEnter: (to, from, next) => {
                if (userStore.loggedIn) {
                    next({path: '/profile',})
                } else {
                    next()
                }
            },
        },
        {
            name: 'LogoutRoute',
            path: '/logout',
            component: () => import(/* webpackChunkName: "Home" */ '@/assets/js/src/modules/home/_views/Home.vue'),
            beforeEnter: (to, from, next) => {
                if (userStore.loggedIn) {
                    userActionsStore.logoutUser().then(() => {
                        if (import.meta.env.SSR) {
                            next()
                        } else {
                            setTimeout(() => {
                                if ((!from.name && from.matched.length === 0) || from.meta.userContent) {
                                    next({path: '/',})
                                } else {
                                    next(false)
                                }
                            })
                        }
                    })
                } else {
                    next()
                }
            },
        },
        {
            name: 'ProfileRoute',
            path: '/profile',
            component: () => import(/* webpackChunkName: "User" */ '@/assets/js/src/modules/user/_views/ProfileOverlay.vue'),
            meta: {uiType: 'Overlay', userContent: true,},
            beforeEnter: (to, from, next) => {
                if (!userStore.loggedIn) {
                    next({path: '/register',})
                } else {
                    next()
                }
            },
        },
    ]
}
