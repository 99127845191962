const TagOverlay = () => import(/* webpackChunkName: "Tags" */ '@/assets/js/src/modules/tag/_views/TagOverlay.vue')
const TagItem = () => import(/* webpackChunkName: "Tags" */ '@/assets/js/src/modules/tag/_views/TagItem.vue')
const TagList = () => import(/* webpackChunkName: "Tags" */ '@/assets/js/src/modules/tag/_views/TagList.vue')

export default [
    {
        path: '/tags',
        meta: {
            uiType: 'Overlay',
            userContent: true,
        },
        component: TagOverlay,
        children: [
            {
                name: 'TagList',
                path: '',
                meta: {
                    uiType: 'Overlay',
                    userContent: true,
                },
                component: TagList,
            },
        ],
    },
    { path: '/tag', redirect: { name: 'PageNotFound', params: {catchAll: "tag",},}, },
    {
        path: '/tag',
        meta: {
            uiType: 'Overlay',
            userContent: true,
        },
        component: TagOverlay,
        children: [
            {
                name: 'TagItem',
                path: ':key',
                meta: {
                    uiType: 'Overlay',
                    userContent: true,
                },
                component: TagItem,
            },
        ],
    },
]