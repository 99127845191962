const LikeList = () => import(/* webpackChunkName: "Likes" */ '@/assets/js/src/modules/like/_views/LikeList.vue')

export default [
    {
        name: 'LikeList',
        path: '/likes',
        meta: {
            uiType: 'Overlay',
        },
        component: LikeList,
    },
]