import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'

export default function (pinia) {
    let bibleStore = useBibleStore(pinia)
    let abbreviationsEncoded = bibleStore.abbreviations.map((abbr) => encodeURIComponent(abbr))
    let oneBible = '(' + [ ...abbreviationsEncoded, ].join('|') + ')'

    return [
        {
            name: 'CrossRefRoute',
            path: '/cross-reference/:abbreviation' + oneBible + '/:textref*',
            meta: {uiType: 'Overlay',},
            component: () => import(/* webpackChunkName: "Crossrefs" */ '@/assets/js/src/modules/crossRef/_views/CrossReferenceOverlay.vue'),
        },
    ]
}