import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'

const Home = () => import(/* webpackChunkName: "Home" */ '@/assets/js/src/modules/home/_views/Home.vue')

export default function (pinia) {
    let langStore = useLangStore(pinia)

    return [
        {name: 'Home', path: '/', component: Home,},
        {
            name: 'HomeML',
            path: `/:locale(${langStore.supportedLocales.join('|')})`,
            component: Home,
        },
    ]
}