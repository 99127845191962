import {createPinia,} from 'pinia'
import {mergeDeep,} from '@/assets/js/src/util/merge'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import pako from 'pako/lib/inflate'
import {useResourceStore,} from "@/assets/js/src/modules/resource/_pinia/resource"

export function createPiniaStore ({initState,}) {
    // Globales Objekt (window bzw. global) referenzieren
    let globalThis = (1, eval)('this')

    globalThis.log('tap_createPiniaStore')

    let state = {
        baseUrl: '',
        appId: 'app',
    }

    if (import.meta.env.SSR && typeof initState !== 'undefined') {
        state = mergeDeep(state, initState)
    } else if (typeof globalThis.U2FsdGVkX1 !== 'undefined' && typeof initState === 'undefined') {
        // Evtl. entschlüsseln
        let decryptedState
        if (globalThis.stateEncrypted) {
            try{
                // State gzip bzw. deflate Dekompriemierung-Funktion
                let uncompress = (base64data) => {
                    let compressData = atob(base64data)
                    compressData = compressData.split('').map(function (e) {
                        return e.charCodeAt(0)
                    })
                    let binData = new Uint8Array(compressData)
                    let result = pako.inflate(binData,{ to: 'string', })

                    return result
                }

                decryptedState = JSON.parse(uncompress(globalThis.U2FsdGVkX1))
            }catch(err) {
            }
        }

        state = mergeDeep(state, decryptedState || globalThis.U2FsdGVkX1)

        import.meta.env.PROD && delete globalThis.U2FsdGVkX1
    } else if(!import.meta.env.SSR && typeof initState !== 'undefined') {
        state = mergeDeep(state,mergeDeep(globalThis.U2FsdGVkX1,initState))
    }

    const pinia = createPinia()

    // Stores that are initially needed
    let initialStores = [
        useAutocompleteStore (pinia),
        useUserStore (pinia),
        useRouteMetaStore (pinia),
        useAppUiStore (pinia),
        useBibleStore(pinia),
        useResourceStore(pinia),
        useLangStore(pinia),
    ]

    initialStores.forEach((initialStore)=>{
        initialStore.$patch(state[initialStore.$id])
        delete state[initialStore.$id]
    })

    pinia.state.value = mergeDeep(globalThis.clone(pinia.state.value), state)
    globalThis.log('stop_createPiniaStore')

    return pinia
}
