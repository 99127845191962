import {getActivePinia,} from 'pinia'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useRouter, useRoute,} from 'vue-router'

export function useOverlayRouteAction () {
    let activePinia = getActivePinia()
    let appUiStore = useAppUiStore(activePinia)
    let router = useRouter()
    let route = useRoute()

    const overlayRouteAction = function (closeBtn = false) {
        if(appUiStore.userContentRoute && !closeBtn) {
            let path = globalThis.clone(appUiStore.userContentRoute.route.path)
            appUiStore.userContentRoute = null
            router.push({ path, }).catch(() => {})

            return
        } else if (closeBtn) {
            appUiStore.userContentRoute = null
            if(route.meta.module === 'readingPlan') {
                appUiStore.hideExtraBox()
            }
        }

        if(appUiStore.backOverlayRoute) {
            appUiStore.backOverlayRoute = ''
            route.path === '/login' ?
                router.go(-2) :
                router.back()
        }

        let lastRoute = appUiStore.lastRoute

        if (appUiStore.existsLastRoute) {
            if (route.name === 'CrossRefRoute') {
                router.back()
            } else {
                // Zurück zur letzten nicht Overlay
                router.push({ path: lastRoute.route.path, }).catch(() => {})
            }
        } else {
            // Für Overlays, die als erste Seite geladen werden (F5) wichtig
            if (route.name === 'CrossRefRoute') {
                router.push({ path: `/${route.params.abbreviation}/${route.params.textref}`, }).catch(() => {})
            } else {
                // Startseite
                router.push({ path: '/', }).catch(() => {})
            }
        }
    }
    
    return {
        overlayRouteAction,
    }
}