<template>
    <div
        class="bs-nav__menu-user__user"
        @click="profileHandler"
    >
        <div class="bs-nav__menu-user__user__text">
            <p>
                {{ title }}<br>
                <span v-if="loggedIn">
                    <a
                        tabindex="-1"
                        @click.stop="logoutHandler"
                    >{{ t('async.menu.logout') }}</a>
                </span>
                <span v-else>{{ subTitle }}</span>
            </p>
        </div>
        <div
            class="bs-nav__menu-user__user__image-wrapper"
            tabindex="-1"
            @keydown.prevent.space="profileHandler"
        >
            <bs-user-image
                class="bs-nav__menu-user__user__image-wrapper__image"
            ></bs-user-image>
        </div>
    </div>
</template>

<script>
import {getActivePinia, storeToRefs,} from 'pinia'
import BsUserImage from '@/assets/js/src/modules/user/_components/BsUserImage.vue'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {computed,} from "vue"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

export default {
    name: 'MenuUser',
    components: {BsUserImage,},
    setup () {
        let activePinia = getActivePinia()

        const appUiStore = useAppUiStore(activePinia)
        const {menu,} = storeToRefs(appUiStore)

        const userStore = useUserStore(activePinia)
        const {loggedIn,email,fullnameShort,} = storeToRefs(userStore)

        const {t,} = useTranslation()

        return {
            menu,
            loggedIn,
            title: computed(() => {
                if (loggedIn.value) {
                    return fullnameShort.value
                } else {
                    return t('async.menu.login.title')
                }
            }),
            subTitle: computed(() => {
                if (loggedIn.value) {
                    return email.value
                } else {
                    return t('async.menu.login.teaser')
                } 
            }),
            t,
        }
    },
    created () {
        if (!import.meta.env.SSR) {
            document.addEventListener('keydown', this.handleESC)
        }
    },
    methods: {
        profileHandler () {
            // Hier ist es eingebunden in der Navigation-Ansicht
            if (this.loggedIn) {
                if (this.$route.path === '/profile') {
                    this.menu = false
                } else {
                    this.$router.push({path: '/profile',})
                }
            } else {
                if (this.$route.path === '/register') {
                    this.menu = false
                } else {
                    this.$router.push({path: '/register',}).catch(() => {
                    })
                }
            }
        },
        logoutHandler () {
            this.$router.push({path: '/logout',}).catch(() => {
            })
        },
        handleESC (event) {
            if (this.menu && event.keyCode === 27) {
                this.menu = false
            }
        },
    },
}
</script>

<style lang="scss">
    .bs-app .bs-nav {
        &__menu-user__user {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            cursor: pointer;

            @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                justify-content: flex-start;
                padding: 18px 0;
                background: map-deep-get($bs-color, sidebar, greyDark);
            }

            &__text {
                align-self: flex-end;

                @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                    order: 2;
                }

                p {
                    margin: 0 0 7px;
                    color: #fff;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: right;

                    @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                        text-align: left;
                    }

                    & > span {
                        font-weight: normal;
                        font-size: 13px;

                        & > a {
                            color: #fff;
                            text-decoration: underline;
                            transition: map-deep-get($bs-transition, standard);

                            &:hover, &:focus {
                                color: map-deep-get($bs-color, bsHighlight);
                                outline: none;
                            }
                        }
                    }
                }
            }

            &__image-wrapper {
                align-self: flex-end;
                margin-left: 18px;

                @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                    order: 1;
                    margin: 0 18px 0 24px;
                }

                &__image {
                    width: 46px !important;
                    max-width: 46px;
                    height: 46px !important;
                    color: #fff;
                    border-radius: 46px;

                    .v-icon {
                        width: 46px;
                        height: 46px;
                    }
                }

                &:focus {
                    outline: none;

                    .bs-nav__menu-user__user__image-wrapper__image {
                        background: map-deep-get($bs-color, bsHighlight);
                    }
                }
            }
        }
    }
</style>
