<script setup>
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {useChatItemStore,} from "@/assets/js/src/modules/chat/_pinia/chatItem"
import {getActivePinia, storeToRefs,} from "pinia"
import NikoSvg from "@/assets/js/src/modules/chat/_components/NikoSvg.vue"
import {useUserOptions,} from "@/assets/js/src/modules/user/_composables/useUserOptions"
import {computed, defineAsyncComponent, onMounted, ref, watch,} from "vue"
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {useMessagesLoaded,} from "@/assets/js/src/modules/lang/_composables/useMessagesLoaded"
import {useRoute,} from "vue-router"
import {Scroll as vScroll,} from 'vuetify/directives'
import {useAppUiStore,} from "@/assets/js/src/pinia/appUi"
const WELCOME_MESSAGE_CLOSED = 'bs-chat-welcome-closed'

const WelcomeMessage = defineAsyncComponent(
    () => import("@/assets/js/src/modules/chat/_components/chat/WelcomeMessage.vue")
)
const Chat = defineAsyncComponent(
    () => import("@/assets/js/src/modules/chat/_components/chat/Chat.vue")
)

const {t,} = useTranslation()
const {messagesLoaded,} = useMessagesLoaded([ 'menu', ])
const activePinia = getActivePinia()
const chatItemStore = useChatItemStore(activePinia)
const {active,} = storeToRefs(chatItemStore)
const {sidebarOnMove,} = storeToRefs(useAppUiStore(activePinia))
const {getUserOption,} = useUserOptions()
const currentRoute = useRoute()

const startNewChat = () => {
    chatItemStore.initChat()
}

const isTextView = computed(() => {
    return currentRoute.meta.uiType === 'TextView'
})

const sidebarContext = computed(() => {
    return isTextView.value || currentRoute.meta.uiType === 'SearchView'
})


watch(sidebarContext, () => {
    if (!import.meta.env.SSR) {
        let chatBtn = document.getElementById('chat-btn')
        chatBtn && (chatBtn.style.bottom = null)
    }
})

const showTooltip = ref(false)
onMounted(() => {
    setTimeout(() => {
        showTooltip.value = !cookieManager.get(WELCOME_MESSAGE_CLOSED)
    }, 5000)
})

watch(active, () => {
    if (active.value) {
        showTooltip.value = false
    }
})

const setCookie = () => {
    if (!cookieManager.get(WELCOME_MESSAGE_CLOSED)) {
        cookieManager.set(WELCOME_MESSAGE_CLOSED, 'true', {expires: 365,})
    }
    showTooltip.value = false
}

const showChatBtn = computed(()=> {
    return getUserOption('chat/btn')
})

const showChatBtnOnScroll = ref(true)

let oldScrollPos = 0

const onScroll = (e) => {
    if (!import.meta.env.SSR) {
        let scrollPos = window.pageYOffset || e.target.scrollTop || 0
        if (oldScrollPos > scrollPos) {
            showChatBtnOnScroll.value = true
        } else {
            showChatBtnOnScroll.value = false
        }

        if ((Math.max(document.documentElement.offsetHeight, document.body.offsetHeight) - document.documentElement.clientHeight - window.pageYOffset) < 30) {
            showChatBtnOnScroll.value = true
        }
        oldScrollPos = scrollPos
    }
}
</script>

<template>
    <v-tooltip
        v-if="messagesLoaded"
        v-model="showTooltip"
        :close-delay="showTooltip ? 60000 : 0"
        location="right bottom"
        :disabled="true"
        attach="#teleported"
        :eager="false"
        content-class="chat-btn-tooltip"
        offset="16 0"
    >
        <template #activator="{ props }">
            <transition
                name="slide-x-transition"
            >
                <v-btn
                    v-show="!isTextView || (isTextView && showChatBtnOnScroll && !sidebarOnMove) || !$vuetify.display.smAndDown"
                    id="chat-btn"
                    v-bind="props"
                    v-scroll="onScroll"
                    icon
                    :aria-label="t('async.menu.nicodemus-ai').split('<')[0]"
                    :title="t('async.menu.nicodemus-ai').split('<')[0]"
                    size="small"
                    :class="{
                        'hidden-print-only': true,
                        'd-none': !showChatBtn,
                    }"
                    @click="startNewChat"
                >
                    <niko-svg
                        dark
                        class="v-icon__svg v-icon"
                    />
                </v-btn>
            </transition>
        </template>
        <welcome-message
            v-if="showTooltip && showChatBtn && (!isTextView || (isTextView && showChatBtnOnScroll && !sidebarOnMove) || !$vuetify.display.smAndDown)"
            @closed="setCookie"
        ></welcome-message>
    </v-tooltip>
    <chat v-if="active"></chat>
</template>

<style lang="scss">
.bs-app {
    $offset: map-deep-get($bs-xl, text,textNav,offset);

    #chat-btn {
        position: fixed;
        bottom: #{map-deep-get($bs-xl, text,textNav,padding)}px;
        left: #{map-deep-get($bs-xl, text,textNav,padding)}px;
        z-index: 1000;
        width: 36px;
        height: 36px;
    }

    &.fixed-navigation #chat-btn {
        bottom: #{map-deep-get($bs-xl, text,textNav,padding) + $offset}px;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            bottom: #{map-deep-get($bs-xl, text,textNav,padding) + map-deep-get($bs-xs, sidebar, closed, height) + $offset}px;
        }
    }
}
</style>
