import {getCurrentInstance,} from "vue"

export function useAnalytics () {
    const {proxy,} = getCurrentInstance()
    const ssrHint = (o) => {console.error(o,new Error('Analytics not available in SSR'))}

    return {
        bsa: import.meta.env.SSR
            ? {
                event: ssrHint,
                page: ssrHint,
                link: ssrHint,
            }
            : proxy.$bsa,
    }
}
