import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'

const SearchView = () => import(/* webpackChunkName: "Search" */ '@/assets/js/src/modules/search/_views/SearchView.vue')

export default function (pinia) {
    let bibleStore = useBibleStore(pinia)
    let abbreviationsEncoded = bibleStore.abbreviations.map((abbr) => encodeURIComponent(abbr))
    let oneBible = '(' + [ ...abbreviationsEncoded, ].join('|') + ')'

    return [
        {
            name: 'SearchBible',
            path: '/search/:abbreviation' + oneBible + '/:slug?',
            component: SearchView,
            meta: {autocomplete: true, autocompleteInput: '', sidebar: true, uiType: 'SearchView',},
            props: {isCompare: false,},
        },
        {
            name: 'SearchCompare',
            path: '/search/:abbreviation' + oneBible + ':separator(\\.):rest/:slug?',
            component: SearchView,
            meta: {autocomplete: true, autocompleteInput: '', sidebar: true, uiType: 'SearchView',},
            props: {isCompare: true,},
        },
        {
            name: 'SearchDefault',
            path: '/search/:slug*',
            component: SearchView,
            meta: {autocomplete: true, autocompleteInput: '', sidebar: true, uiType: 'SearchView',},
            props: {isCompare: false,},
        },
    ]
}