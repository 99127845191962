import {useRouter,} from 'vue-router'

export function useHandleRoutableLink () {
    let router = useRouter()

    return {
        handleRoutableLink ($event) {

            // ensure we use the link, in case the click has been received by a subelement
            let {target,} = $event

            while (target && target.tagName !== 'A') target = target.parentNode

            // handle only links that occur inside the component and do not reference external resources
            if (target && target.matches(`a:not([href*='://']):not([href^='mailto'])`) && target.href) {
                // some sanity checks taken from vue-router:
                // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented,} = $event

                // don't handle when preventDefault called
                if (defaultPrevented) return
                // don't handle with control keys
                if (metaKey || altKey || ctrlKey || shiftKey) return
                // don't handle right clicks
                if (button !== undefined && button !== 0) return
                // don't handle if `target="_blank"`
                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target')
                    if (/\b_blank\b/i.test(linkTarget)) return
                }
                // don't handle same page links/anchors
                const url = new URL(target.href)
                const to = url.pathname
                const notSamePage = window.location.pathname !== to
                const samePageHashDiff = !notSamePage && window.location.hash !== url.hash

                if ((notSamePage ||
                        samePageHashDiff) &&
                    $event.preventDefault) {
                    $event.preventDefault()
                    router.push(to + (url.search || '') + (url.hash || ''))
                }
            }
        },
    }
}