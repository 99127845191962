<script setup>
import {getActivePinia, storeToRefs,} from 'pinia'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {computed, toRefs,} from "vue"
import {useAnalytics,} from "@/assets/js/src/util/composables/useAnalytics"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {useMainHeaderClasses,} from "@/assets/js/src/util/composables/useMainHeaderClasses"

const props = defineProps({
    isBanner: {
        type: Boolean,
        default: false,
    },
    isMenu: {
        type: Boolean,
        default: false,
    },
    isNotification: {
        type: Boolean,
        default: false,
    },
})

const activePinia = getActivePinia()
const appUiStore = useAppUiStore(activePinia)
const {menu,} = storeToRefs(appUiStore)
const {bsa,} = useAnalytics()
const {t,} = useTranslation()
const {isMenu,} = toRefs(props)
const {mainHeaderClasses,} = useMainHeaderClasses()

const menuTitle = computed(() => {
    if (isMenu.value) {
        return t('async.menu.close')
    } else {
        return t('async.menu.open')
    }
})

const setMenu = function (val) {
    val && bsa && bsa.event({
        eventCategory: 'button actions',
        eventAction: 'Menü öffnen',
    })
    menu.value = val
}
</script>

<template>
    <div
        id="help-tour-text-1"
        class="bs-nav__menu-btn"
        :class="[{'bs-nav__menu-btn--banner-visible':isBanner},{'bs-nav__menu-btn--open':isMenu},mainHeaderClasses,]"
        :title="menuTitle"
        :aria-label="menuTitle"
        role="button"
        @click="setMenu(!isMenu)"
        @keydown.prevent.space="setMenu(!isMenu)"
        @keydown.prevent.enter="setMenu(!isMenu)"
    >
        <div class="bs-nav__menu-btn--hover"></div>
        <transition name="fade">
            <div
                v-show="isNotification"
                class="bs-nav__menu-btn--notification"
                :class="[{'bs-nav__menu-btn--notification--open':isMenu}]"
            ></div>
        </transition>
        <span
            class="bs-nav__menu-btn--line bs-nav__menu-btn--line--1"
            :class="{'bs-nav__menu-btn--line--1--open':isMenu}"
        ></span>
        <span
            class="bs-nav__menu-btn--line bs-nav__menu-btn--line--2"
            :class="{'bs-nav__menu-btn--line--2--open':isMenu}"
        ></span>
        <span
            class="bs-nav__menu-btn--line bs-nav__menu-btn--line--3"
            :class="{'bs-nav__menu-btn--line--3--open':isMenu}"
        ></span>
    </div>
</template>

<style lang="scss">
    .bs-app .bs-nav {
        &__menu-btn {
            position: fixed;
            top: 43px;
            left: 40px;
            z-index: 9993;
            width: 25px;
            height: 17px;
            cursor: pointer;
            transition: map-deep-get($bs-transition, standard);

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                top: 36px;
                left: 40px;
                width: 22px;
                height: 15px;
            }

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                top: 20px;
                left: 17px;
                width: 22px;
                height: 15px;
            }

            &--banner-visible {
                top: map-deep-get($bs-xl, banner, height) + 43;

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    top: map-deep-get($bs-xl, banner, height) + 36;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    top: map-deep-get($bs-xs, banner, height) + 20;
                }
            }

            &--hover {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 46px;
                height: 46px;
                background: transparent;
                border-radius: 46px;
                transform: translate(-50%, -50%);
                transition: map-deep-get($bs-transition, standard);
            }

            &--notification {
                position: absolute;
                top: -4px;
                right: -4px;
                z-index: 1;
                width: 8px;
                height: 8px;
                background: map-deep-get($bs-color, bsHighlight);
                border-radius: 8px;

                &--open {
                    opacity: 0;
                }
            }

            &--open {
                transform: rotate(180deg);
                transition: transform .2s ease-in-out;
                transition-delay: .3s;
            }

            &--line {
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background: #fff;
                transition: map-deep-get($bs-transition, standard);

                &--1 {
                    top: 0;
                    transform-origin: top left;

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        top: 0;
                    }

                    &--open {
                        top: 50%;
                        left: 50%;
                        transform: rotate(45deg) translate(-50%, -50%);

                        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                            top: 50%;
                            left: 50%;
                            transform: rotate(45deg) translate(-50%, -50%);
                        }
                    }
                }

                &--2 {
                    top: 7px;

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        top: 6px;
                    }

                    &--open {
                        opacity: 0;
                    }
                }

                &--3 {
                    top: 14px;
                    transform-origin: top left;

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        top: 12px;
                    }

                    &--open {
                        top: 50%;
                        left: 50%;
                        transform: rotate(-45deg) translate(-50%, -50%);

                        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                            top: 50%;
                            left: 50%;
                            transform: rotate(-45deg) translate(-50%, -50%);
                        }
                    }
                }
            }

            &:hover, &:focus {
                outline: none;

                .bs-nav__menu-btn--line {
                    background: map-deep-get($bs-color, bs);
                }

                .bs-nav__menu-btn--hover {
                    background: #f4f4f4;
                }
            }

            &.main-header {
                &--unpinned {
                    top: -#{map-deep-get($bs-xl, header, height)};
                    transform: translateY(-#{map-deep-get($bs-xl, header, height)});
                    transition: .5s;
                }

                &--pinned {
                    transition: .5s;
                }
            }
        }
    }
</style>
