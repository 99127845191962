import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'

export default function (pinia) {
    let userStore = useUserStore(pinia)

    return [
        {
            name: 'NewsletterOverlay',
            path: '/newsletter',
            meta: {
                uiType: 'Overlay',
            },
            beforeEnter: (to, from, next) => {
                if (userStore.loggedIn) {
                    next({path: '/profile',})
                } else {
                    next()
                }
            },
            component: () => import(/* webpackChunkName: "Newsletter" */ '@/assets/js/src/modules/newsletter/_views/NewsletterOverlay.vue'),
        },
    ]
}
