import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {getActivePinia,} from 'pinia'

export function useUserOptions () {
    let activePinia = getActivePinia()
    let userStore = useUserStore(activePinia)

    return {
        getUserOption: userStore.getOptionByPath,
        setUserOption: userStore.setUserOptions,
    }
}