import {defineStore, getActivePinia,} from "pinia"
import fetch from "@/assets/js/src/util/fetch"
import {checkResponse, handleException, paramsToQuery,} from "@/assets/js/src/util/apiTools"
import {getDefaultHeaders,} from "@/assets/js/src/util/fetch/defaultHeaders"

export const API_GET_ALL_CHATS = '/api/chats'

export const useChatListStore = defineStore('chatList', {
    state: () => ({
        chats: [],
        search: '',
        reload: false,
        responseMeta: {},
        page: 1,
    }),
    getters: {
        maxPages: (state) => state.responseMeta.max_page,
        count: (state) => state.responseMeta.total,
    },
    actions: {
        async getChats ({page,}) {

            page = page || 1

            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_GET_ALL_CHATS}?${paramsToQuery({
                        filter: JSON.stringify({
                            search: this.search,
                        }),
                        page,
                    })}`,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                const {chats, ...responseMeta} = apiResponse.data

                this.page = page
                if (page === 1) {
                    this.chats = chats
                    this.responseMeta = responseMeta
                } else {
                    this.chats.push(...chats)
                }
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true, true)
            }
        },
    },
})
