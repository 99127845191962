import cookieManager from 'js-cookie'

let browserStorage = {}

export default {
    get (key) {
        if(globalThis.navigator?.cookieEnabled) {
            return cookieManager.get(key)
        } else {
            return browserStorage[key]
        }
    },
    set (key, value, options) {
        if(globalThis.navigator?.cookieEnabled) {
            return cookieManager.set(key, value, options)
        } else {
            browserStorage[key] = value
        }
    },
    remove (key) {
        if(globalThis.navigator?.cookieEnabled) {
            return cookieManager.remove(key)
        } else {
            delete browserStorage[key]
        }
    },
}