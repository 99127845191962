import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'

const SynopticOverlay = () => import(/* webpackChunkName: "Synoptic" */ '@/assets/js/src/modules/synoptic/_views/SynopticOverlay.vue')

export default function (pinia) {
    let bibleStore = useBibleStore(pinia)
    let abbreviations = bibleStore.abbreviations
    let abbreviationsEncoded = abbreviations.map((abbr) => encodeURIComponent(abbr))
    let oneBible = '(' + [ ...abbreviationsEncoded, ].join('|') + ')'

    return [
        {
            name: 'SynopticRoute',
            path: '/synoptic/:abbreviation' + oneBible + '/:id(\\d+)',
            meta: {uiType: 'Overlay',},
            component: SynopticOverlay,
        },
    ]
}