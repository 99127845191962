<template>
    <div class="bs-user">
        <template v-if="!home && !$route.meta.landingPage">
            <span
                v-if="fullname.length > 0"
                class="greeting"
            >{{ t('async.common.greeting.user', {nickname: nickname} ) }}
            </span>
            <router-link
                v-else
                :to="{path: '/register'}"
                class="greeting"
            >{{ t('async.common.greeting.default') }}
            </router-link>
        </template>
        <v-btn
            id="USER_POPOVER_BTN"
            icon
            class="bs-header__user__btn bs-header__user__btn--login"
            :aria-label="loggedIn ? t('async.menu.profile') : t('async.menu.login.title')"
            v-bind="specialAttrs"
            :title="loggedIn ? t('async.menu.profile') : t('async.menu.login.title')"
            @click="userIconHandler"
        >
            <bs-user-image class="userimage"></bs-user-image>
        </v-btn>
        <bs-user-popover
            v-if="loggedIn && getComponentVisibility('USER_POPOVER')"
            ref="userPopup"
        />
    </div>
</template>

<script>
import {mapActions, storeToRefs, getActivePinia,} from 'pinia'
import BsUserImage from '@/assets/js/src/modules/user/_components/BsUserImage.vue'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {defineAsyncComponent,} from "vue"
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'

export default {
    name: 'BsUser',
    components: {
        BsUserImage,
        BsUserPopover: defineAsyncComponent(() => import('@/assets/js/src/modules/user/_components/BsUserPopover.vue')),
    },
    props: {
        home: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        let activePinia = getActivePinia()

        const userStore = useUserStore(activePinia)
        const {loggedIn,user,nickname,fullname,} = storeToRefs(userStore)

        const autocompleteStore = useAutocompleteStore(activePinia)
        const {getComponentVisibility,} = storeToRefs(autocompleteStore)

        return {
            loggedIn,
            user,
            nickname,
            fullname,
            getComponentVisibility,
            ...useTranslation(),
        }
    },
    computed: {
        specialAttrs () {
            return this.loggedIn ? {'aria-haspopup': 'menu',} : null
        },
    },
    methods: {
        ...mapActions(useAutocompleteStore, [
            'setComponentVisible',
        ]),
        userIconHandler () {
            if (!this.loggedIn) {
                this.$router.push({path: '/register',}).catch(() => {
                })
            } else {
                this.setComponentVisible('USER_POPOVER')
                setTimeout(() => {
                    let btn = this.$el.querySelector('.user-popover__settings-btn')
                    btn && btn.focus()
                }, 310) // Popover trans delay
            }
        },
    },
}
</script>

<style lang="scss">
    .bs-app .bs-user {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .v-btn__content {
            flex-shrink: 1;
        }

        .greeting {
            padding-right: 5px;
            color: #fff;
            white-space: nowrap;
            text-decoration: none;

            @media (width <= 960px) {
                display: none;
            }
        }

        .userimage, .v-icon {
            width: map-deep-get($bs-xl, header, btn, login, size);
            height: map-deep-get($bs-xl, header, btn, login, size);
            border-radius: 50%;
        }

        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
            .userimage, .v-icon {
                width: map-deep-get($bs-md, header, btn, login, size);
                height: map-deep-get($bs-md, header, btn, login, size);
            }
        }
    }
</style>
