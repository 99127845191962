<template>
    <transition name="bs-nav__notification-slide">
        <div
            v-show="menuNotificationVisible"
            class="bs-nav__notification"
        >
            <p
                @click="handleNotificationLinks($event)"
                v-html="text"
            >
            </p>
            <div
                class="bs-nav__notification__button"
                tabindex="-1"
                @click="handleDismissNotification"
                @keydown.prevent.space="handleDismissNotification"
            >
                <v-icon
                    v-if="type === 'info'"
                    size="large"
                    class="bs-nav__notification__button--icon"
                    :class="{'bs-nav__notification__button--icon--no-dismiss':!isDismissable}"
                >
                    bs:$vuetify.icons.mdiBell
                </v-icon>
                <v-icon
                    v-if="type === 'warning'"
                    size="large"
                    class="bs-nav__notification__button--icon"
                    :class="{'bs-nav__notification__button--icon--no-dismiss':!isDismissable}"
                >
                    bs:$vuetify.icons.mdiBellAlert
                </v-icon>
                <v-icon
                    v-if="type === 'donation'"
                    size="large"
                    class="bs-nav__notification__button--icon"
                    :class="{'bs-nav__notification__button--icon--no-dismiss':!isDismissable}"
                >
                    bs:$vuetify.icons.mdiBellRing
                </v-icon>
                <v-icon
                    size="large"
                    class="bs-nav__notification__button--icon bs-nav__notification__button--icon-close"
                    :class="{'bs-nav__notification__button--icon-close--no-dismiss':!isDismissable}"
                >
                    bs:$vuetify.icons.mdiClose
                </v-icon>
            </div>
        </div>
    </transition>
</template>

<script>
import {getActivePinia, mapState, mapWritableState, storeToRefs} from 'pinia'
import {useDismissNotification,} from '@/assets/js/src/util/composables/useDismissNotification'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'

export default {
    name: 'MenuNotification',
    setup () {
        let activePinia = getActivePinia()

        const appUiStore = useAppUiStore(activePinia)
        const {menuNotificationVisible,notifications,} = storeToRefs(appUiStore)

        return {
            menuNotificationVisible,
            notifications,
            ...useDismissNotification(),
        }
    },
    data: () => ({
        isDismissable: false,
        text: '',
        type: '',
        isReadingPlan: false,
    }),
    watch: {
        notifications: function (newVal) {
            // Notification laden
            if (typeof newVal.menu !== 'undefined' && typeof newVal.menu.id !== 'undefined') {
                this.text = newVal.menu.notification_message.message.replace('<a', '<a tabindex="-1"')
                this.type = newVal.menu.type
                this.isDismissable = typeof newVal.menu.dismiss_days !== 'undefined'
                this.isReadingPlan = newVal.menu.id === 0
                this.isDismissable = this.isDismissable || this.isReadingPlan
                this.menuNotificationVisible = true
            } else {
                this.menuNotificationVisible = false
            }
        },
    },
    methods: {
        async handleDismissNotification () {
            if (!this.isDismissable) {
                return
            }

            await this.dismissNotification({
                position: 'menu',
                readingPlan: this.isReadingPlan,
            })
        },
        handleNotificationLinks (event) {
            let {target,} = event
            while (target && target.tagName !== 'A') target = target.parentNode
            if (target) {
                let link = target.href
                let origin = location.origin

                if (link.indexOf(origin) !== -1 || (link.indexOf('http') === -1 && link.indexOf('mailto') === -1)) {
                    event.preventDefault()
                    if (link.replace(origin, '') !== '') {
                        this.$router.push({path: link.replace(origin, ''),})
                    } else {
                        this.$router.push({path: '/',})
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss">
    .bs-app .bs-nav {
        &__notification {
            position: relative;
            margin-top: 32px;

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                width: 100%;
                margin: 0;
                padding: 16px 54px 16px 24px;
                background: map-deep-get($bs-color, sidebar, greyDark);
                border-top: 1px solid map-deep-get($bs-color, greyDark);
            }

            &-slide-enter-from, &-slide-leave-to {
                max-height: 0;
                margin-top: 0;
                padding: 0;
                overflow: hidden;
            }

            &-slide-enter-active, &-slide-leave-active {
                transition: .3s cubic-bezier(.25, .8, .5, 1);
            }

            &-slide-enter-to, &-slide-leave-from {
                max-height: 200px;
                margin-top: 32px;
            }

            p {
                max-width: 250px;
                max-height: 72px;
                margin: 0;
                padding: 0;
                overflow: hidden;
                color: map-deep-get($bs-color, bsHighlight);
                font-weight: 900;
                font-size: 16px;
                text-align: right;
                text-transform: uppercase;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    max-width: none;
                    text-align: left;
                }
            }

            a, a:hover, a:focus {
                text-decoration: none;
                outline: none;
            }

            &__button {
                position: absolute;
                top: 50%;
                right: -48px;
                width: 24px;
                height: 24px;
                overflow: hidden;
                transform: translateY(-50%);
                cursor: pointer;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    right: 24px;
                }

                &--icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    color: map-deep-get($bs-color, bsHighlight);
                    transform: translate(-50%, -50%);
                }

                &--icon-close {
                    @include cache((
                        opacity: 0,
                    ));
                }

                &:hover, &:focus {
                    outline: none;

                    .bs-nav__notification__button--icon {
                        @include cache((
                            opacity: 0,
                        ));
                    }

                    .bs-nav__notification__button--icon--no-dismiss {
                        @include cache((
                            opacity: 1,
                        ));
                    }

                    .bs-nav__notification__button--icon-close {
                        @include cache((
                            opacity: 1,
                        ));
                    }

                    .bs-nav__notification__button--icon-close--no-dismiss {
                        @include cache((
                            opacity: 0,
                        ));
                    }
                }
            }
        }
    }
</style>
