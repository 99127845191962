import {defineStore, getActivePinia,} from 'pinia'
import {entireUserType,} from '@/assets/js/src/modules/user/_pinia/types'
import {getValueByPath, mergeDeep,} from '@/assets/js/src/util/merge'
import {getDefaultHeaders,} from '@/assets/js/src/util/fetch/defaultHeaders'
import fetch from '@/assets/js/src/util/fetch'
import {checkResponse, commitMessage, handleException,} from '@/assets/js/src/util/apiTools'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {COOKIE_LIKES,} from '@/assets/js/src/modules/like/_pinia/_constants'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useColorMode,} from '@vueuse/core'
import {getInitializedApp,} from '@/assets/js/src/util/appRef'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'

const API_UPDATE_USER_OPTION = '/api/profile/option'

export const useUserStore = defineStore('user', {
    state () {
        return {
            jwt: '',
            user: entireUserType(),
            tmpUser: {},

            // Activity
            parentActivity: 0,
        }
    },
    getters: {
        loggedIn: state => state.jwt.length !== 0,
        getOptionByPath: (state) => (path) => {
            let value = getValueByPath(path, state.user.options)
            if (typeof value === 'undefined') {
                return getValueByPath(path, entireUserType().options)
            } else {
                return value
            }
        },
        isBetaFeatureUnlocked: (state) => (feature) => {
            return (state.user.beta || []).some(featureObj => {
                return featureObj.feature === feature && featureObj.unlocked
            })
        },
        isBetaFeatureOnWaitlist: (state) => (feature) => {
            return (state.user.beta || []).some(featureObj => featureObj.feature === feature)
        },
        email: state => state.user.email,
        timezone: state => state.user.timezone,
        fullnameShort: function () {
            let fullname = this.fullname
            if (fullname.trim().length > 20) {
                fullname = fullname.substring(0, 20) + '...'
            }

            return fullname
        },
        fullname: state => {
            let fullname = `${state.user.firstname} ${state.user.lastname}`

            if (fullname.trim().length === 0) {
                fullname = state.user.email.split('@', 1)[0]
            }

            return fullname
        },
        nickname: (state) => {
            let nickname = `${state.user.firstname}`

            if (nickname.trim().length === 0) {
                nickname = state.user.email.split('@', 1)[0]
            }

            if (nickname.trim().length > 20) {
                nickname = nickname.substring(0, 20) + '...'
            }

            return nickname
        },
    },
    actions: {
        setUserOptionsByPath ({path, value,}) {
            if (typeof getValueByPath(path, this.user.options) === 'undefined') {
                this.user.options = mergeDeep(entireUserType().options, this.user.options)
            }
            let schema = this.user.options  // a moving reference to internal objects within obj
            let pList = path.split('/')
            let len = pList.length
            for (let i = 0; i < len - 1; i++) {
                let elem = pList[i]
                if (!schema[elem]) schema[elem] = {}
                schema = schema[elem]
            }
            schema[pList[len - 1]] = value
            this.user.options = globalThis.clone(this.user.options)
        },
        async setUserOptions ({option, data, snackbar,}) {
            snackbar = snackbar || false
            if (JSON.stringify(data) !== JSON.stringify(this.getOptionByPath(option))) {
                this.setUserOptionsByPath({path: option, value: data,})
                await this.updateUserOptions({option, data, silent: true, snackbar,})
            }
        },
        async updateUserOptions ({option, data, snackbar, silent,}) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            try {
                option = option || ''
                snackbar = snackbar || false
                silent = silent || false
                if (option === '' || this.loggedIn === false) {
                    return
                }

                let user = globalThis.clone(this.user)

                let fetchData = {
                    url: `${API_UPDATE_USER_OPTION}/${user.id}`,
                    options: {
                        method: 'PATCH',
                        body: JSON.stringify({path: option, value: data,}),
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                }

                // Send silent Request to store user options on server
                if (silent) {
                    fetchData['loading'] = false
                    fetchData.options['headers']['x-silent-request'] = true
                    fetch(fetchData)

                    return
                }

                // Api-Request senden
                let apiResponse = await fetch(fetchData)

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                if (snackbar) {
                    commitMessage(apiResponse)
                }

            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, false, snackbar)
            }
        },

        resetUser () {
            this.setUser({})
        },

        setUser (user, jwt = '') {
            // Delete Cookie
            cookieManager.remove(COOKIE_LIKES)

            jwt && (this.jwt = jwt)

            let oldSelectedBibles = JSON.stringify(this.user.options.selectedBibles)
            let bibles = user.options?.bibles ?? []
            let selectedBibles = user.options?.selectedBibles ?? []
            let externalLinks = user.options?.externalLinks ?? []

            let userTmp = mergeDeep(entireUserType('setUser'), user)
            if (bibles) {
                userTmp.options.bibles = bibles
            }
            if (selectedBibles) {
                userTmp.options.selectedBibles = selectedBibles
            }
            if (externalLinks) {
                userTmp.options.externalLinks = externalLinks
            }

            this.user = userTmp
            if (oldSelectedBibles !== JSON.stringify(this.user.options.selectedBibles) && this.user.options.selectedBibles && this.user.options.selectedBibles.length > 0) {
                let activePinia = getActivePinia()
                let bibleStore = useBibleStore(getActivePinia())
                let autocompleteStore = useAutocompleteStore(activePinia)
                let routeMetaStore = useRouteMetaStore(activePinia)

                bibleStore.setSelectedBibles(this.user.options.selectedBibles)

                if (routeMetaStore.type === 'search') {
                    autocompleteStore.reloadSearch = true
                }

                autocompleteStore.reload = true
            }

            // Darkmode prüfen
            if(typeof this.user.options?.textFormats?.darkMode !== 'undefined' && !import.meta.env.SSR) {
                let mode = useColorMode()
                if(this.user.options.textFormats.darkMode) {
                    mode.value = 'dark'
                    let {app,} = getInitializedApp()
                    app.$vuetify && (app.$vuetify.theme.current.dark = true)
                }
                let tmp = globalThis.clone(this.user.options.textFormats)
                delete tmp.darkMode

                this.setUserOptions({
                    option: 'textFormats',
                    data: tmp,
                })
            }
        },
    },
})