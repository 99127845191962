import '@/assets/js/src/util/declareglobals'
import {createPiniaStore,} from '@/assets/js/src/pinia'
import {createHead,createServerHead,} from '@unhead/vue'
import {createRouterInstance,} from '@/assets/js/src/router'
import {createI18nInstance,} from '@/assets/js/src/i18n'
import {createVuetifyInstance,} from '@/assets/js/src/vuetify'
import {setInitializedApp,} from '@/assets/js/src/util/appRef'
import SvgIcon from '@/assets/js/src/components/SvgIcon.vue'

export function setupApp ({state,history,app,}) {
    // Globales Objekt (window bzw. global) referenzieren
    let globalThis = (1, eval)('this')

    globalThis.log('tap_createApp')

    // create router, store and i18n instances
    const pinia = createPiniaStore({initState:state,})
    const head = import.meta.env.SSR ? createServerHead() : createHead()
    const router = createRouterInstance({pinia,history,head,app,})
    const i18n = createI18nInstance({pinia,})
    const vuetify = createVuetifyInstance({i18n,pinia,})

    if (import.meta.env.DEV) {
        app.config.globalProperties.$console = {
            log: (...args) => {
                console.log(...args)
            },
        }
    }

    app.component('SvgIcon', SvgIcon)
    app.use(head)
    app.use(vuetify)
    app.use(router)
    app.use(pinia)
    app.config.globalProperties.$isServer = import.meta.env.SSR
    app.config.globalProperties.$i18n = i18n
    app.config.globalProperties.$vuetify = vuetify

    setInitializedApp(app)

    globalThis.log('stop_createApp')

    // expose the app, the router and the store.
    return {app, router, i18n, pinia, head,}
}

