import requireModule from '../util/requireContextModuleFiles'
import {notifyError,} from '@/assets/js/src/util/bugsnag'

export default (pinia, app) => {
    let appModulesRoutes = []
    let modules = requireModule()

    // alle gefundenen Dateien durchlaufen und Routes registrieren
    for (let fileName of Object.keys(modules)) {
        let pathParts = fileName.split('/')

        if (pathParts[6] === '_routes') {
            try {
                let {default: appRouteDefinition,} = modules[fileName]

                if (appRouteDefinition) {
                    typeof appRouteDefinition === 'function' && (appRouteDefinition = appRouteDefinition(pinia, app))
                    appModulesRoutes.push(...appRouteDefinition)
                }
            } catch (err) {
                if (import.meta.env.DEV) {
                    if (err.constructor.name === 'Object') console.warn(err)
                    else console.warn(err.constructor.name, err.message, err.stack)
                } else {
                    // Bugsnag
                    notifyError(err)
                }
                // keine index.js oder default export gefunden
            }
        }
    }

    return appModulesRoutes
}
