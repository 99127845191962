import {getActivePinia,} from 'pinia'
import {useSnackbarStore,} from "@/assets/js/src/modules/snackbar/_pinia/snackbar"

export function useShowMessage () {
    const snackbarStore = useSnackbarStore(getActivePinia())
    const showMessage = function (payload) {
        return snackbarStore.showMessage(payload)
    }

    return {
        showMessage,
    }
}
