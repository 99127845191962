import {defineStore, getActivePinia,} from 'pinia'
import {DIR_LTR, DIR_RTL,} from '@/assets/js/src/modules/lang/_pinia/lang'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import fetch from '@/assets/js/src/util/fetch'
import {getDefaultHeaders,} from '@/assets/js/src/util/fetch/defaultHeaders'
import {checkResponse, handleException,} from '@/assets/js/src/util/apiTools'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useUserActionsStore,} from '@/assets/js/src/modules/user/_pinia/userActions'

const API_GET_MESSAGES = '/api/lang'
const API_GET_GUI_LANGUAGES = '/api/lang/gui'

export const useLangActionsStore = defineStore('langActions', {
    state: () => ({
        inAction: false,
    }),
    actions: {
        async setLocaleDirection ({locale,}) {
            let langStore = useLangStore(getActivePinia())

            await  this.fetchGuiLanguages()
            let isRtl = langStore.guiLanguages.find((language) => language.locale === locale).rtl || false
            if (isRtl) {
                langStore.localeDirection = DIR_RTL
            } else {
                langStore.localeDirection = DIR_LTR
            }
            langStore.rtl = isRtl
        },

        async setClientLocale ({id, updateUserLocally,}) {
            let activePinia = getActivePinia()
            let langStore = useLangStore(activePinia)
            try {
                if (this.inAction) {
                    return
                }
                this.inAction = true

                await this.fetchGuiLanguages()

                let locale = langStore.getLocaleById(id)

                // Standardwert für updateUserLocally setzen
                if (typeof updateUserLocally === 'undefined') {
                    updateUserLocally = true
                }

                // Prüfe, ob die Messages bereits vorhanden sind
                if (!Object.keys(langStore.messages).includes(locale)) {
                    // Die Messages mit dieser Action nachladen
                    langStore.messages[locale] = {}
                    if (langStore.messages[langStore.locale]?.async) {
                        await langStore.fetchAsyncMessages({
                            keys: Object.keys(langStore.messages[langStore.locale].async),
                            locale,
                        })
                    }
                }

                // Speichere die Language ID auch im User-Objekt
                if (updateUserLocally) {
                    let userStore = useUserStore(activePinia)
                    let userActionsStore = useUserActionsStore(activePinia)

                    if(userStore.loggedIn) {
                        await userActionsStore.updateLanguageProfile({
                            id,
                            updateLanguageLocally: false,
                        })
                    }
                }

                let oldDirection = langStore.localeDirection
                await this.setLocaleDirection({locale,})

                // Die ausgewählte Sprache im Store setzen
                langStore.locale = locale

                // Die ausgewählte Sprache im Cookie setzen
                cookieManager.set('bs-locale', locale, {
                    expires: 365,
                    secure: true,
                })
                cookieManager.remove('bs-session-locale')

                if (oldDirection !== langStore.localeDirection) {
                    setTimeout(() => {
                        location.reload()
                    }, 350)
                }
            } finally {
                this.inAction = false
            }
        },

        async fetchMessagesByLocale ({locale,}) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            let langStore = useLangStore(activePinia)
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: `${API_GET_MESSAGES}/${locale}`,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                langStore.messages = globalThis.clone(langStore.messages)
                langStore.messages[locale] = apiResponse.data

            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true)
            }
        },

        async fetchGuiLanguages () {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            let langStore = useLangStore(activePinia)

            try {
                // fetch others if only one language exists
                if (langStore.guiLanguages.length > 1) {
                    return
                }

                // Api-Request senden
                let useCache = true
                let apiResponse = await fetch({
                    url: `${API_GET_GUI_LANGUAGES}`,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                            useCache,
                        }),
                    },
                    useCache,
                    tags: [
                        'languages',
                    ],
                })

                // Api-Response prüfen
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                // GUI Sprachen im Store setzten
                langStore.guiLanguages = apiResponse.data
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, true)
            }
        },
    },
})
