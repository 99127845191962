import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {storeToRefs} from "pinia"

const TextView = () => import(
    /* webpackChunkName: "Text" */
    /* webpackPreload: true */
    '@/assets/js/src/modules/text/_views/TextView.vue')

export function textLastRoutes (pinia) {
    let bibleStore = useBibleStore(pinia)
    let abbreviationsEncoded = bibleStore.abbreviations.map((abbr) => encodeURIComponent(abbr))
    let oneBible = '(' + [ ...abbreviationsEncoded, ].join('|') + ')'

    return [
        {
            name: 'ReadBible',
            path: '/:abbreviation' + oneBible + '/:textref?',
            component: TextView,
            meta: {
                autocomplete: true,
                isCompare: false,
                autocompleteInput: '',
                sidebar: true,
                uiType: 'TextView',
                keepAlive: true,
                noScrollTop: () => true,
            },
            props: {isCompare: false,},
        },
        {
            name: 'ReadBibleAll',
            path: '/:abbreviation' + oneBible + '/:textref*',
            component: TextView,
            meta: {
                autocomplete: true,
                isCompare: false,
                autocompleteInput: '',
                sidebar: true,
                uiType: 'TextView',
                keepAlive: true,
                noScrollTop: () => true,
            },
            props: {isCompare: false,},
        },
        {
            name: 'CompareBible',
            path: '/:abbreviation' + oneBible + ':separator(\\.):rest/:textref?',
            component: TextView,
            meta: {
                autocomplete: true,
                isCompare: true,
                autocompleteInput: '',
                sidebar: true,
                uiType: 'TextView',
                keepAlive: true,
                noScrollTop: () => true,
            },
            props: {isCompare: true,},
        }, // eslint-disable-line
        {
            name: 'CompareBibleAll',
            path: '/:abbreviation' + oneBible + ':separator(\\.):rest/:textref*',
            component: TextView,
            meta: {
                autocomplete: true,
                isCompare: true,
                autocompleteInput: '',
                sidebar: true,
                uiType: 'TextView',
                keepAlive: true,
                noScrollTop: () => true,
            },
            props: {isCompare: true,},
        }, // eslint-disable-line
        {
            name: 'SelectedBibles',
            path: '/selectedbibles/:textref*',
            beforeEnter: (to, from, next) => {
                next({path: `/${bibleStore.selectedBibles.join('.')}/${to.params.textref}`,})
            },
        },
    ]
}