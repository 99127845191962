import {getInitializedApp,} from '@/assets/js/src/util/appRef'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useAuthStore,} from '@/assets/js/src/pinia/auth'

let globalThis = (1, eval)('this')
export const notifyError = function (err, info) {
    if (!import.meta.env.DEV && !globalThis.bgsng) {
        (async () => {
            try {
                const bugsnag = await import(/* webpackChunkName: "Bugsnag" */'@bugsnag/js')
                const Bugsnag = bugsnag.default

                let plugins = []
                if(!import.meta.env.SSR) {
                    const bugsnagVue = await import(/* webpackChunkName: "Bugsnag" */'@bugsnag/plugin-vue')
                    const BugsnagPluginVue = bugsnagVue.default
                    plugins = [ new BugsnagPluginVue(), ]
                }

                let {pinia: firstStore,} = getInitializedApp()

                Bugsnag.start({
                    // eslint-disable-next-line no-undef
                    apiKey: import.meta.env.SSR ? __BS_BUGSNAG_NODE_API_KEY__ : useAuthStore(firstStore).bugsnagAuth,
                    // eslint-disable-next-line no-undef
                    appVersion: __BS_VERSION__, // __BS_VERSION__ is const injection via webpack.Defineplugin
                    // eslint-disable-next-line no-undef
                    releaseStage: __BS_RELEASE_STAGE__, // __BS_RELEASE_STAGE__ is const injection via webpack.Defineplugin
                    plugins,
                })
                globalThis.bgsng = Bugsnag
                globalThis.bgsng.notify(err,function (event) {
                    // Add additional diagnostic information
                    let {pinia,} = getInitializedApp()
                    event.addMetadata('custom', {
                        userType: useUserStore(pinia).loggedIn ? 'Member' : 'Visitor',
                        ...info,
                    })
                })
            } catch(initError) {
                console.warn(err.message || err, info, initError)
            }
        })()
    } else if (globalThis.bgsng) {
        globalThis.bgsng.notify(err,function (event) {
            // Add additional diagnostic information
            let {pinia,} = getInitializedApp()
            event.addMetadata('custom', {
                userType: useUserStore(pinia).loggedIn ? 'Member' : 'Visitor',
                ...info,
            })
        })
    } else {
        console.warn(err.message || err, info)
    }
}

export function errorHandler (err, vm, info) {// Bugsnag

    let handler, current = vm
    if (typeof vm  === 'undefined') {
        console.warn(err)

        return
    }

    let lastRoutes = import.meta.env.SSR ?
        vm.$pinia.state.value.routeMeta.url :
        vm.$pinia.state.value.appUi.lastRoutes

    // Bugsnag
    notifyError(err, {info, tag: vm.$.type?.name, lastRoutes,})

    if (vm.$options.errorHandler) {
        handler = vm.$options.errorHandler
    } else {
        while (current.$parent) {
            current = current.$parent
            handler = current.$options.errorHandler
            if (handler) break
        }
    }
    if (handler) handler.call(current, err, vm, info)
    else console.warn(err)
}

export class ServiceWorkerRegError extends Error {
    constructor (message) {
        super(message)
        this.name = 'ServiceWorkerRegError'
    }
}

export class ApiResponseError extends Error {
    constructor (message) {
        super(message)
        this.name = 'ApiResponseError'
    }
}
