<template>
    <img
        v-if="image"
        :src="url"
        :alt="t('async.common.greeting.img')"
    >
    <v-icon v-else>
        bs:$vuetify.icons.mdiAccountCircle
    </v-icon>
</template>

<script>
import styleXl from '@/assets/js/src/style/json/variables'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

export default {
    name: 'BsUserImage',
    props: {
        userCopy: {
            type: Object,
            default: () => ({}),
        },
    },
    setup () {
        let activePinia = getActivePinia()

        const userStore = useUserStore(activePinia)
        const {user,} = storeToRefs(userStore)

        return {
            user,
            ...useTranslation(),
        }
    },
    computed: {
        isUserCopy () {
            return Object.keys(this.userCopy).length !== 0
        },
        gravatarUrl () {
            let type = 'retro' // or '404'
            let hash = this.isUserCopy ? this.userCopy.hash : this.user.hash

            return `//www.gravatar.com/avatar/${hash}?s=${styleXl.user.image_size}&d=${type}`
        },
        gravatarAvailable () {
            return (this.isUserCopy ? this.userCopy.options.gravatar : this.user.options.gravatar)
        },
        image () {
            return this.gravatarAvailable || (this.isUserCopy ? this.userCopy.image.length !== 0 : this.user.image.length !== 0)
        },
        url: function () {
            return this.gravatarAvailable ?
                this.gravatarUrl :
                (this.isUserCopy ? this.userCopy.image : this.user.image)
        },
    },
}
</script>
