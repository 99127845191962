import {slugify,} from '@/assets/js/src/util/slugify'
import {reduceCanonicalsToRef,} from '@/assets/js/src/util/uiTools'
import {REF_DEFAULT, REF_SHORT,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useExcludeFootnotes,} from '@/assets/js/src/util/composables/useExcludeFootnotes'

function getRefLink ({canonical, verse, abbr, booknames,}) {
    let slug = slugify(reduceCanonicalsToRef({canonicals: [ canonical, ], type: REF_DEFAULT, isUrl: true, booknames,}))

    return `/cross-reference/${encodeURIComponent(abbr)}/${reduceCanonicalsToRef({
        canonicals: [ verse, ],
        type: REF_DEFAULT,
        isUrl: true,
        booknames,
        abbr,
    })}#${'verse-' + slug}`
}

export function getVerseReferences ({verse, books, abbr,}) {
    let verseReferences = []
    let googleReferences = []
    let verseReferencesGoogle = ''
    let {excludeFootnotes,} = useExcludeFootnotes()
    if(verse.references.length !== 0){
        for(let j = 0;j < verse.references.length;j++){
            let verseReference = verse.references[j]
            verseReference.content = excludeFootnotes(verseReference.content)
            verseReference.link = getRefLink({canonical: verseReference.canonical, verse: verse.canonical,abbr,booknames:books,})
            verseReference.link_by_canonical = reduceCanonicalsToRef({canonicals: [ verseReference.canonical, ], type: REF_SHORT,})
            verseReference.parts = {
                href: `/${reduceCanonicalsToRef({
                    canonicals: [ verseReference.canonical, ],
                    type: REF_DEFAULT,
                    isUrl: true,
                    booknames: books,
                    abbr,
                })}`.replace(/ /g, ''),
                text: reduceCanonicalsToRef({
                    canonicals: [ verse.references[j].canonical, ],
                    type: REF_SHORT,
                }),
            }
            verseReferences.push(verseReference)
            googleReferences.push(`<a href="/${encodeURIComponent(abbr)}${verseReference.parts.href}" target="_blank">${verseReference.parts.text}</a>`)
        }
        verseReferencesGoogle = `(${googleReferences.join('; ')})`
    }

    return {verseReferences, verseReferencesGoogle,}
}

export function getVerseFootnotes ({verse,footnoteOffset,}) {
    footnoteOffset = footnoteOffset ?? 0
    let verseFootnotes = []
    if(verse.footnotes.length !== 0){
        let verseSplit = verse.content.split('*')

        for(let j = 0; j < verseSplit.length;j++){
            if(verseSplit[j].trim() !== '' || j === 0){
                verseSplit[j].trim() !== '' && verseFootnotes.push({
                    content: verseSplit[j],
                })
                if(j === verseSplit.length - 1) {
                    break
                }
                if(j >= verse.footnotes.length) {
                    continue
                }

                // Footnote für '*' einfügen
                verseFootnotes.push({
                    number: `[${footnoteOffset + 1}]`,
                    content: verse.footnotes.find((footnote) => footnote.position === j + 1).content,
                })
                footnoteOffset++
            }
        }
    }

    return verseFootnotes
}