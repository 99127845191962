import {defineStore, getActivePinia,} from 'pinia'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {safeEncodeURI,} from '@/assets/js/src/util/safeEncodeURI'
import fetch from '@/assets/js/src/util/fetch'
import {getDefaultHeaders,} from '@/assets/js/src/util/fetch/defaultHeaders'
import {checkResponse, handleException,} from '@/assets/js/src/util/apiTools'
import {useActivityStore,} from '@/assets/js/src/modules/activity/_pinia/activity'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'

const API_ROUTE_META = '/api/autocomplete/routeMeta'

export const useRouteMetaStore = defineStore('routeMeta', {
    state: () => ({
        type: '', // wird vom StateProvider.php im VueRenderer überschrieben
        url: '', // wird vom StateProvider.php im VueRenderer überschrieben
        params: {}, // wird vom StateProvider.php im VueRenderer überschrieben
        apiUrl: '', // wird vom StateProvider.php im VueRenderer überschrieben
        isCompare: false, // wird vom StateProvider.php im VueRenderer überschrieben
        bibles: {}, // wird vom InitialStateProviderService.php im VueRenderer überschrieben
        search: '', // wird vom InitialStateProviderService.php im VueRenderer überschrieben
        selectedVerses: [], // wird vom InitialStateProviderService.php im VueRenderer überschrieben
        autocomplete: '', // wird vom InitialStateProviderService.php im VueRenderer überschrieben
        book: '', // wird vom InitialStateProviderService.php im VueRenderer überschrieben
        bookname: '', // wird vom InitialStateProviderService.php im VueRenderer überschrieben
        reload: false,
        canonical: '',
        prevUrl: '',
        nextUrl: '',
        chapterCanonical: 0,
        route: null,
        isSet: false,
    }),
    actions:{
        setSelectedVerses ({state,},payload){
            state.selecetedVerses = payload
        },
        setIsSet (payload) {
            this.isSet = payload
        },
        setType ({state,},payload) {
            state.type = payload
        },
        setRoute ({state,},payload) {
            state.route = payload
        },
        setReload ({state,},payload) {
            state.reload = payload
        },
        deleteParams () {
            this.params = {}
            if(!import.meta.env.SSR && cookieManager.get('bs-params')) {
                cookieManager.remove('bs-params')
            }
        },
        replaceRouteMetaState (newState) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value

            let oldUrl =  globalThis.clone(this.url)
            let newUrl =  globalThis.clone(newState.url)

            Object.keys(newState).forEach(key=>{
                this[key] = newState[key]
            })

            this.isSet = true

            if(oldUrl === newUrl && rootState.appUi.home){
                let appUiStore = useAppUiStore(activePinia)
                appUiStore.homeLoad = true
                appUiStore.home = false
            }
        },
        async loadRouteMeta ({url,type,locale,reload,fallback,}) {
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            let bibleStore = useBibleStore(activePinia)
            let langStore = useLangStore(activePinia)
            try {
                if(typeof locale === 'undefined') {
                    locale = langStore.locale
                }

                if(typeof reload !== 'undefined') {
                    this.reload = reload
                }

                let urlParts = url.split('/')

                if(type === 'search' && !reload) {
                    urlParts.shift()
                }

                let autocomplete = rootState.autocomplete.autocompleteInput

                if(urlParts.length > 1){
                    let params = `${type}/${locale}/${urlParts.shift()}/${urlParts.map((part)=>safeEncodeURI(part)).join('/')}`
                    let paramsFallback = fallback ? '?fallback=' + safeEncodeURI(fallback) : ''

                    if(type === 'text' && !import.meta.env.SSR) {
                        paramsFallback  += `${paramsFallback ? '&' : '?'}no-ssr=true'`
                    }

                    // Api-Request senden
                    let useCache = true
                    let apiResponse = await fetch({
                        url: `${API_ROUTE_META}/${params}${paramsFallback}`,
                        options: {
                            headers: getDefaultHeaders({
                                rootState,
                                useCache,
                            }),
                        },
                        useCache, // keine Tags notwendig, weil die Route nicht im SSR läuft
                    })

                    // Prüfen der Api Antwort
                    if (checkResponse(apiResponse)) {
                        throw new Error('Fehler in Api-Response')
                    }

                    // Route Meta aktualisieren
                    this.replaceRouteMetaState(apiResponse.data)
                    autocomplete = apiResponse.data.autocomplete
                    url = apiResponse.data.url
                }

                // Activities tracken --> sidebar --> Bsp Tag
                if(useUserStore(activePinia).loggedIn){
                    let activityStore = useActivityStore(getActivePinia())
                    let userStore = useUserStore(getActivePinia())
                    if(reload || rootState.appUi.countRouteChange === 0){
                        activityStore.addActivity({
                            name: autocomplete,
                            route: url,
                            type: type,
                            abbreviations: globalThis.clone(bibleStore.selectedBibles),
                            parent: 0,
                        })
                    }else{
                        activityStore.addActivity({
                            name: autocomplete,
                            route: url,
                            type: type,
                            abbreviations: globalThis.clone(bibleStore.selectedBibles),
                            parent: globalThis.clone(userStore.parentActivity),
                        })
                    }
                }
            } catch (e) {
                // Evtl. Fehler ausgeben
                handleException(e, false, false)
            }
        },
    },
})
