import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {getActivePinia, storeToRefs,} from 'pinia'

export function useMainHeaderClasses () {
    let activePinia = getActivePinia()

    const appUiStore = useAppUiStore(activePinia)
    const {mainHeaderClasses,} = storeToRefs(appUiStore)

    return {
        mainHeaderClasses,
    }
}