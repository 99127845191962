import {defineStore,} from 'pinia'

export const useResourceStore = defineStore('resource', {
    state () {
        return {
            resources: {},
            provider: {},
            providerList: {},
            backToList: false,
        }
    },
    getters: {
        getAbbreviationsByProviderArray: (state) => (providers) => {
            let abbreviations = Object.values(state.resources)
                .filter((resource) => providers.includes(resource.provider.split('_')[0]) && resource.public_url === true)
                .map((resource) => resource.abbreviation)

            return [ ...new Set(abbreviations), ]
        },
        getProviderByAbbreviation: (state) => (abbreviation) => {
            return Object.values(state.resources)
                .find((resource) => abbreviation === resource.abbreviation).provider.split('_')[0]
        },
        hasAmp: (state) => (abbreviation) => {
            let item = Object.values(state.resources)
                .find((resource) => abbreviation === resource.abbreviation)

            if (typeof item === 'undefined') {
                return false
            }

            return item.amp
        },
        getCacheableProviders: (state) => (type) => {
            return state.provider[type]
                .filter((providerName) => providerName.endsWith('_cacheable'))
        },
        getNonCacheableProviders: (state) => (type) => {
            return state.provider[type]
                .filter((providerName) => !providerName.endsWith('_cacheable'))
        },
        getSingleProviderName: (state) => (providerName) => {
            providerName = providerName.toLowerCase()
            let resource = Object.values(state.resources).find((resource) => resource.provider_plural === providerName)

            return resource ? resource.provider.replace('_cacheable', '') : providerName
        },
    },
})
