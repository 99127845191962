import ogImageFiles from '@/assets/js/src/util/requireContextOpenGraph'
import {safeEncodeURI,} from '@/assets/js/src/util/safeEncodeURI'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'
import {computed,} from "vue"
import {useAmpLink,} from "@/assets/js/src/util/composables/useAmpLink"

export default function (pinia) {

    let bibleStore = useBibleStore(pinia)
    let langStore = useLangStore(pinia)
    let routeMetaStore = useRouteMetaStore(pinia)

    const locale = langStore.locale
    const biblesByLocale = bibleStore.getAbbreviationsByLocale(locale)
    const baseUrl = pinia.state.value.baseUrl
    const currentRoute = routeMetaStore.canonical

    const ogImages = []
    const twiiterImages = []

    let filteredOgImages = Object.keys(ogImageFiles).filter(
        // Alle OpenGraph-Images nach locale filtern
        (fileName) => {

            const name = fileName.replace(/(^\/assets\/images\/opengraph\/|\.png$)/g, '')

            return name.startsWith(locale)
        }
    )

    if (filteredOgImages.length === 0) {
        filteredOgImages = Object.keys(ogImageFiles).filter(
            // Alle OpenGraph-Images nach fallbackLocale filtern
            (fileName) => {
                const name = fileName.replace(/(^\/assets\/images\/opengraph\/|\.png$)/g, '')

                return name.startsWith(langStore.fallbackLocale)
            }
        )
    }

    const hasOwnOGImage = [ '/graphic/', '/topic/', '/nicodemus-ai', '/verse-of-the-day', ].some(pre => routeMetaStore.url.startsWith(pre))

    filteredOgImages.forEach((fileName) => {
        // Danach alle OpenGraph-Images laden (Ausnahme Graphic - Route hat eigene ogImages)
        if (!hasOwnOGImage) {
            let ogImage = {
                property: 'og:image',
                content: baseUrl + ogImageFiles[fileName].default,
            }
            ogImages.push(ogImage)
        }

        // Nur das erste wird Twittercard-Image
        if (twiiterImages.length === 0)
            twiiterImages.push({
                name: 'twitter:image',
                content: baseUrl + ogImageFiles[fileName].default,
                vmid: 'twitter:image',
            })
    })

    // OpenSearch Dateien pro locale referenzieren
    const openSearch = []

    biblesByLocale.forEach((abbreviation) => {
        openSearch.push({
            rel: 'search',
            type: 'application/opensearchdescription+xml',
            title: 'ERF Bibleserver (' + abbreviation + ')',
            href: baseUrl + '/opensearch/' + abbreviation.toLowerCase() + '.xml',
        })
    })

    let defaultMetaInfo = {
        base: {target: '_blank', href: baseUrl,},
        titleTemplate: (titleChunk) => {
            return titleChunk.indexOf('ERF Bibleserver') === -1 ? `${titleChunk} :: ERF Bibleserver` : titleChunk
        },
        meta: [
            {
                name: 'description',
                content: '',
                vmid: 'description',
            },

            // Opengraph
            {
                property: 'og:type',
                content: 'website',
            },
            {
                property: 'og:url',
                content: baseUrl + safeEncodeURI(currentRoute, true),
                vmid: 'og:url',
            },
            {
                property: 'og:title',
                content: '',
                vmid: 'og:title',
            },
            {
                property: 'og:description',
                content: '',
                vmid: 'og:description',
            },
            {
                property: 'og:locale',
                content: locale,
            },
            ...ogImages,
            {
                property: 'og:image:width',
                content: '1200',
                vmid: 'og:image:width',
            },
            {
                property: 'og:image:height',
                content: '630',
                vmid: 'og:image:height',
            },

            // Twitter
            {
                name: 'twitter:card',
                content: 'summary_large_image',
            },
            ...twiiterImages,
        ],
        link: [
            // Canonical-Link
            {
                rel: 'canonical',
                href: baseUrl + safeEncodeURI(currentRoute, true),
                vmid: 'canonical',
            },

            // OpenSearch einbinden
            ...openSearch,
        ],
    }

    return {
        ...defaultMetaInfo,
    }
}

export const genHeadOverrides = ({title, description, canonical, ampCanonical,}) => {
    let info = {}

    let bsTitle = computed(() => {
        if(!title || !title.value) {
            return 'ERF Bibleserver'
        }

        return title.value.indexOf('ERF Bibleserver') === -1 ? `${title.value} :: ERF Bibleserver` : title.value
    })
    let meta = [
        {
            property: 'og:title',
            content: bsTitle,
            vmid: 'og:title',
        },
    ]

    if(description && typeof description.value !== 'undefined') {
        meta.push(...[
            {
                name: 'description',
                content: description,
                vmid: 'description',
            },
            {
                property: 'og:description',
                content: description,
                vmid: 'og:description',
            },
        ])
    }

    info = {
        title: bsTitle,
        meta,
        link: [],
    }

    if(canonical && typeof canonical.value !== 'undefined') {
        info.meta.push({
            property: 'og:url',
            content: canonical,
            vmid: 'og:url',
        })

        info.link.push({
            rel: 'canonical',
            href: canonical,
            vmid: 'canonical',
        })
    }

    if(ampCanonical && typeof ampCanonical.value !== 'undefined' && ampCanonical.value) {
        import.meta.env.SSR && useAmpLink(ampCanonical.value)
        info.link.push({
            rel: 'amphtml',
            href: ampCanonical,
            vmid: 'amphtml',
        })
    }

    return info
}
