import {useBooknamesStore,} from "@/assets/js/src/modules/bible/_pinia/booknames"
import {getActivePinia, storeToRefs,} from "pinia"
import {useLangStore,} from "@/assets/js/src/modules/lang/_pinia/lang"

export const useBooknamesLoaded = () => {
    const activePinia = getActivePinia()
    const booknamesStore = useBooknamesStore(activePinia)
    const {booknamesLoaded,isActive,} = storeToRefs(booknamesStore)
    const {locale,} = storeToRefs(useLangStore(activePinia))

    if(!booknamesLoaded.value && !isActive.value) {
        (async ()=>{
            await booknamesStore.loadBooknames(locale.value)
        })()
    }
    
    return {
        booknamesLoaded,
    }
}