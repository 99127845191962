import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useResourceStore,} from '@/assets/js/src/modules/resource/_pinia/resource'

const ResourceList = () => import(/* webpackChunkName: "Resources" */ '@/assets/js/src/modules/resource/_views/ResourceList.vue')
const ResourceItem = () => import(/* webpackChunkName: "Resources" */ '@/assets/js/src/modules/resource/_views/ResourceItem.vue')
const ResourceOverlay = () => import(/* webpackChunkName: "Resources" */ '@/assets/js/src/modules/resource/_views/ResourceOverlay.vue')

export default function (pinia) {
    let langStore = useLangStore(pinia)
    let resourceStore = useResourceStore(pinia)

    const noScrollTopProviders = [ 'graphic', 'video', ]
    const listableProviders = [ 'dictionaries', 'graphics', 'videos', ]
    const itemableProviders = [ 'dictionary', 'graphic', 'video', 'commentary', ]

    let itemAbbreviations = resourceStore.getAbbreviationsByProviderArray(itemableProviders)
    let itemAbbreviationsEncoded = itemAbbreviations.map((abbr) => encodeURIComponent(abbr))
    let itemAbbreviationsRegEx = [ ...new Set([ ...itemAbbreviations, ...itemAbbreviationsEncoded, ]), ].join('|')

    return [
        {
            path: `/:provider(${listableProviders.join('|')})`,
            meta: {
                uiType: 'Overlay',
            },
            component: ResourceOverlay,
            children: [
                {
                    name: 'ResourceListRoute',
                    path: '',
                    meta: {
                        uiType: 'Overlay',
                        tag: 'resource',
                    },
                    component: ResourceList,
                },
                {
                    name: 'ResourceItemRoute',
                    path: `/:provider(${itemableProviders.join('|')})/:abbreviation(${itemAbbreviationsRegEx})/:title?/:id(\\d+)?`,
                    meta: {
                        uiType: 'Overlay', noScrollTop: (route) => noScrollTopProviders.includes(route.params.provider),
                        tagType: 'resource',
                    },
                    component: ResourceItem,
                    beforeEnter: (to, from, next) => {
                        if (resourceStore.getProviderByAbbreviation(to.params.abbreviation) !== to.params.provider) {
                            next({
                                name: 'PageNotFound',
                            })
                        }
                        resourceStore.backToList = [ 'ResourceListRouteML', 'ResourceListRoute', ].includes(from.name)
                        if (typeof to.params.id === 'undefined' && to.params.provider !== 'commentary') {
                            if (typeof to.params.title !== 'undefined' && to.params.title.match(/\d+/) !== null) {
                                let {provider, abbreviation,} = to.params
                                next({
                                    name: 'ResourceItemRoute', params: {
                                        provider,
                                        abbreviation,
                                        id: to.params.title.toString(),
                                    },
                                })
                            } else {
                                next({name: 'ResourceListRoute', params: {...to.params,},})
                            }
                        } else {
                            next()
                        }
                    },

                },
            ],
        },
        {
            path: `/:locale(${langStore.supportedLocales.join('|')})/:provider(${listableProviders.join('|')})`,
            meta: {
                uiType: 'Overlay',
                tagType: 'resource',
            },
            component: ResourceOverlay,
            children: [
                {
                    name: 'ResourceListRouteML',
                    path: '',
                    meta: {
                        uiType: 'Overlay',
                        tagType: 'resource',
                    },
                    component: ResourceList,
                },
                {
                    name: 'ResourceItemRouteML',
                    path: `/:locale(${langStore.supportedLocales.join('|')})/:provider(${itemableProviders.join('|')})/:abbreviation(${itemAbbreviationsRegEx})/:title?/:id(\\d+)?`,
                    meta: {
                        uiType: 'Overlay', noScrollTop: (route) => noScrollTopProviders.includes(route.params.provider),
                        tagType: 'resource',
                    },
                    component: ResourceItem,
                    beforeEnter: (to, from, next) => {
                        if (resourceStore.getProviderByAbbreviation(to.params.abbreviation) !== to.params.provider) {
                            next({
                                name: 'PageNotFound',
                            })
                        }
                        resourceStore.backToList = [ 'ResourceListRouteML', 'ResourceListRoute', ].includes(from.name)
                        if (typeof to.params.id === 'undefined' && to.params.provider !== 'commentary') {
                            if (typeof to.params.title !== 'undefined' && to.params.title.match(/\d+/) !== null) {
                                let {locale, provider, abbreviation,} = to.params
                                next({
                                    name: 'ResourceItemRouteML', params: {
                                        locale,
                                        provider,
                                        abbreviation,
                                        id: to.params.title.toString(),
                                    },
                                })
                            } else {
                                next({name: 'ResourceListRouteML', params: {...to.params,},})
                            }
                        } else {
                            next()
                        }
                    },
                },
            ],
        },
    ]
}
