import {useUserStore,} from "@/assets/js/src/modules/user/_pinia/user"
import {useAppUiStore,} from "@/assets/js/src/pinia/appUi"
import {useChatItemStore,} from "@/assets/js/src/modules/chat/_pinia/chatItem"

const ChatList = () => import(/* webpackChunkName: "Contact" */ '@/assets/js/src/modules/chat/_views/ChatList.vue')
const ChatLanding = () => import(/* webpackChunkName: "Contact" */ '@/assets/js/src/modules/chat/_views/NicodemusAI.vue')

export default function (pinia) {
    let userStore = useUserStore(pinia)
    let appUiStore = useAppUiStore(pinia)
    let chatItemStore = useChatItemStore(pinia)

    return [
        {
            name: 'ChatList', 
            path: '/chats', 
            meta: {uiType: 'Overlay',}, 
            component: ChatList,
            beforeEnter: (to, from, next) => {
                if (userStore.loggedIn) {
                    next()

                    return
                }

                appUiStore.menu = false
                next({path: '/nicodemus-ai',})
            },},
        {
            name: 'NewChat',
            path: '/start-chat',
            beforeEnter: () => {
                chatItemStore.activeReset()

                return false
            },
        },
        {name: 'ChatLanding', path: '/nicodemus-ai', meta: {uiType: 'Overlay',landingPage: true,}, component: ChatLanding,},
    ]
}
