import {useRouteMetaStore,} from '@/assets/js/src/pinia/routeMeta'

const TopicItem = () => import(/* webpackChunkName: "Topic" */ '@/assets/js/src/modules/topic/_views/TopicItem.vue')
const TopicList = () => import(/* webpackChunkName: "Topic" */ '@/assets/js/src/modules/topic/_views/TopicList.vue')
const TopicOverlay = () => import(/* webpackChunkName: "Topic" */ '@/assets/js/src/modules/topic/_views/TopicOverlay.vue')
import {getInitializedApp,} from '@/assets/js/src/util/appRef'

export default function (pinia) {
    let routes = [
        {
            path: '/topics',
            meta: {
                uiType: 'Overlay',
            },
            component: TopicOverlay,
            children: [
                {
                    name: 'TopicListOverlay',
                    path: '',
                    meta: {
                        uiType: 'Overlay',
                    },
                    component: TopicList,
                    beforeEnter: (to, from, next) => {
                        next()
                    },
                },
            ],
        },
        {
            path: '/topic',
            meta: {
                uiType: 'Overlay',
            },
            component: TopicOverlay,
            children: [
                {
                    name: 'TopicItemRoute',
                    path: ':topic/:id(\\d+)',
                    meta: {
                        uiType: 'Overlay',
                        landingPage: true,
                    },
                    beforeEnter: (to, from, next) => {
                        let path = `/${to.params.topic}`

                        if (!import.meta.env.SSR) {
                            let {router,} = getInitializedApp()
                            if (router) {
                                let {route,} = router.resolve(path)

                                if (typeof route === 'undefined' || route.name === 'PageNotFound') {
                                    router.addRoute(
                                        {
                                            path: '/',
                                            meta: {
                                                uiType: 'Overlay',
                                            },
                                            component: TopicOverlay,
                                            children: [
                                                {
                                                    name: 'TopicCanonicalRoute',
                                                    path: to.params.topic,
                                                    meta: {
                                                        uiType: 'Overlay',
                                                        id: to.params.id,
                                                        landingPage: true,
                                                        registerBack: true,
                                                    },
                                                    component: TopicItem,
                                                },
                                            ],
                                        }
                                    )
                                    router.push({path,})

                                    return
                                }
                            }
                        }

                        next(path)
                    },
                    component: TopicItem,
                },
            ],
        },
    ]

    let routeMetaStore = useRouteMetaStore(pinia)
    if (routeMetaStore.url.startsWith('/topic')) {
        let redirect = null
        let id = routeMetaStore.url.split('/')[3]
        let path = routeMetaStore.canonical.substring(1)
        if (path.includes('/')) {
            path = path.split('/')[1]
            redirect = {
                path: routeMetaStore.canonical.substring(1),
                redirect: path,
            }
        }

        let children = [
            {
                name: 'TopicCanonicalRoute',
                path,
                meta: {
                    uiType: 'Overlay',
                    id,
                    landingPage: true,
                    registerBack: true,
                },
                component: TopicItem,
            },
        ]

        redirect && children.push(redirect)

        routes.push(
            {
                path: '/',
                meta: {
                    uiType: 'Overlay',
                },
                component: TopicOverlay,
                children,
            }
        )
    }

    return routes
}