<template>
    <ul class="bs-nav__menu-footer__list">
        <li>
            <a
                :href="isDeLocale ? 'https://www.bibleserver.com/spenden' : 'https://www.bibleserver.com/donate'"
                :title="t('async.menu.donate')"
                tabindex="-1"
                target="_blank"
                rel="noopener noreferrer"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.donate') }}</a>
        </li>
        <li>
            <a
                href="https://blog.bibleserver.com"
                :title="t('async.menu.blog')"
                tabindex="-1"
                target="_blank"
                rel="noopener noreferrer"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.blog') }}</a>
        </li>
        <li>
            <router-link
                to="/newsletter"
                :title="t('async.menu.newsletter')"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.newsletter') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/partner"
                :title="t('async.menu.partner')"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.partner') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/help"
                :title="t('async.menu.help')"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.help') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/contact"
                :title="t('async.menu.contact')"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.contact') }}
            </router-link>
        </li>
        <li>
            <button
                :title="t('async.menu.report-error')"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
                @click="openReport"
                @keyup.enter="openReport"
                @keyup.space="openReport"
            >{{ t('async.menu.report-error') }}
            </button>
        </li>
        <li>
            <router-link
                to="/help/88"
                :title="t('async.menu.alexa')"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.alexa') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/webmasters"
                :title="t('async.menu.webmasters')"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.webmasters') }}
            </router-link>
        </li>
        <li class="spacer"></li>
        <li>
            <a
                href="https://www.erf.de/download/pdf/Datenschutzerklaerung_ERF_Medien.pdf"
                :title="t('async.menu.privacy')"
                tabindex="-1"
                target="_blank"
                rel="noopener noreferrer"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.privacy') }}</a>
        </li>
        <li>
            <a
                href="https://www.erf.de/download/pdf/Informationspflicht_zur_Datenverarbeitung_DSGVO.pdf"
                :title="t('async.menu.dsgvo')"
                tabindex="-1"
                target="_blank"
                rel="noopener noreferrer"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.dsgvo') }}</a>
        </li>
        <li>
            <router-link
                to="/imprint"
                :title="t('async.menu.imprint')"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
            >{{ t('async.menu.imprint') }}
            </router-link>
        </li>
        <li class="spacer"></li>
        <li>
            <router-link
                to="/languages"
                title="Language"
                tabindex="-1"
                class="bs-nav__menu-footer__list__link"
            >Language: <strong>{{ language }}</strong></router-link>
        </li>
        <li>
            <v-switch
                v-model="dark"
                :label="t('async.menu.dark_mode')"
                class="v-theme--dark"
                color="#fff"
                hide-details
            ></v-switch>
        </li>
    </ul>
</template>

<script>
import {useColorMode,} from '@vueuse/core'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {onMounted, ref,} from 'vue'
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

export default {
    name: 'FooterMenu',
    props: {
        language: {
            type: String,
            default: 'English',
        },
    },
    setup () {
        let activePinia = getActivePinia()

        const appUiStore = useAppUiStore(activePinia)
        const {menu,report,} = storeToRefs(appUiStore)

        const {isDeLocale,} = storeToRefs(useLangStore(activePinia))

        let dark = ref(false)
        onMounted(()=>{
            dark.value = useColorMode().value === 'dark'
        })

        return {
            menu,
            report,
            isDeLocale,
            dark,
            ...useTranslation(),
        }
    },
    watch: {
        dark (newValue) {
            useColorMode().value = !newValue ? 'light' : 'dark'
            this.$vuetify.theme.global.name = !newValue ? 'light' : 'dark'
            this.menu = false
        },
    },
    methods: {
        openReport () {
            this.menu = false
            this.report = true
        },
    },
}
</script>

<style lang="scss">
    .bs-app .bs-nav {
        &__menu-footer {
            &__list {
                width: 100%;
                margin: 0;
                padding: 0;
                text-align: right;
                list-style: none;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    text-align: left;
                }

                > li {
                    margin: 6px 0;

                    &.spacer {
                        margin: 32px 0 0;
                    }
                }

                &__link {
                    color: #fff;
                    font-size: 14px;
                    text-decoration: none;
                    transition: map-deep-get($bs-transition, linear);

                    &:hover, &:focus {
                        color: map-deep-get($bs-color, bsHighlight);
                        outline: none;
                    }
                }

                .v-input {
                    margin-top: 0;

                    .v-selection-control {
                        --v-input-control-height: 24px;

                        &__wrapper {
                            order: 2;
                            height: 24px;
                            margin-right: 0;
                        }

                        &__input {
                            --v-selection-control-size: 24px;
                        }
                    }

                    label {
                        flex-grow: 1;
                        justify-content: flex-end;
                        order: 1;
                        margin-right: 8px;
                        color: white;
                        font-size: 14px;
                        opacity: 1;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            flex-grow: 0;
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
</style>
