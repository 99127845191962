import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'

const NoteList = () => import(/* webpackChunkName: "Notes" */ '@/assets/js/src/modules/note/_views/NoteList.vue')
const NoteOverlay = () => import(/* webpackChunkName: "Notes" */ '@/assets/js/src/modules/note/_views/NoteOverlay.vue')

export default function (pinia) {
    let userStore = useUserStore(pinia)
    let appUiStore = useAppUiStore(pinia)

    return [
        {
            path: '/notes',
            meta: {
                uiType: 'Overlay',
                userContent: true,
            },
            beforeEnter: (to, from, next) => {
                if (!userStore.loggedIn) {
                    appUiStore.userContentRoute = {
                        route: to,
                        key: 'async.menu.notes',
                    }
                    appUiStore.menu = false
                    next({path: '/login',})
                } else {
                    next()
                }
            },
            component: NoteOverlay,
            children: [
                {
                    name: 'NoteOverlay',
                    path: '',
                    meta: {
                        uiType: 'Overlay',
                    },
                    component: NoteList,
                },
            ],
        },
    ]
}
