import {defineStore, getActivePinia,} from 'pinia'
import fetch from '@/assets/js/src/util/fetch'
import {getDefaultHeaders,} from '@/assets/js/src/util/fetch/defaultHeaders'
import {checkResponse, handleException,} from '@/assets/js/src/util/apiTools'

const API_GET_LAST = '/api/activity/last'

export const useNavigationStore = defineStore('navigation', {
    state: () => ({
        activity: {},
    }),
    actions: {
        async fetchLastActivity (){
            let activePinia = getActivePinia()
            let rootState = activePinia.state.value
            
            try {
                // Api-Request senden
                let apiResponse = await fetch({
                    url: API_GET_LAST,
                    options: {
                        headers: getDefaultHeaders({
                            rootState,
                        }),
                    },
                })

                // Prüfen der Api Antwort
                if (checkResponse(apiResponse)) {
                    throw new Error('Fehler in Api-Response')
                }

                this.activity = apiResponse.data ?? {}
            } catch (e) {
                handleException(e, false, false)
            }
        },
    },
})