import {mergeDeep,} from '@/assets/js/src/util/merge'
import styleXl from '@/assets/js/src/style/json/variables.json'
import {ALPHABET_SORT, DATE_SORT, USER_COUNT_SORT,} from '@/assets/js/src/util/uiTools'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {getInitializedApp,} from '@/assets/js/src/util/appRef'
import {useShowMessage,} from '@/assets/js/src/modules/snackbar/_composables/useShowMessage'

let globalThis = (1, eval)('this')

/**
 * Required min UserType für Validierung und Typesierung
 * @type {{email: *, plain_password: *}}
 */
const userProps = {
    email: {
        type: String,
        default: '',
    },
    plain_password: {
        type: String,
        default: '',
    },
}

/**
 * Entire UserType für Validierung und Typesierung
 */
const entireUserProps = {
    ...userProps,
    id: {
        type: Number,
        default: 0,
    },
    firstname: {
        type: String,
        default: '',
    },
    lastname: {
        type: String,
        default: '',
    },
    gender: {
        type: String,
        default: '',
    },
    timezone: {
        type: String,
        default: '',
    },
    language: {
        type: Number,
        default: 1,
    },
    image: {
        type: String,
        default: '',
    },
    roles: {
        type: Array,
        default: [],
    },
    hash: {
        type: String,
        default: '',
    },
    options: {
        type: Object,
        default: {
            gravatar: false,
            fixHeader: true,
            bibles: [],
            sortByAlpha: false,
            showOutline: false,
            selectedBibles: [],
            externalLinks: [],
            useExternalLinks: false,
            textFormats: {
                fontSize: ((styleXl.text.font.size.steps - styleXl.text.font.size.steps % 2) / 2 + styleXl.text.font.size.steps % 2) - 1,
                font: 'ptserif',
                maxWidth: true,
                oneVerseLine: false,
                showHeadings: true,
                showFootnotes: true,
                showVerseNumbers: true,
                showCrossRefs: false,
                printMyNotes: false,
            },
            sidebar: {
                search: {
                    pinned: false,
                    boxes: {},
                    order: [],
                },
                text: {
                    pinned: false,
                    boxes: {},
                    order: [],
                },
                open: false,
                syncCommentaries: true,
            },
            note: {
                gridView: true,
                list: {
                    sort: DATE_SORT,
                },
            },
            tag: {
                gridView: true,
                list: {
                    my: false,
                    sort: USER_COUNT_SORT,
                },
            },
            like: {
                list: {
                    my: false,
                    sort: DATE_SORT,
                },
            },
            help: {
                gridView: true,
            },
            topic: {
                gridView: true,
            },
            readingPlan: {
                gridView: true,
                list: {
                    my: false,
                    sort: USER_COUNT_SORT,
                },
                sectionAbbr: '',
            },
            resource: {
                commentary: {
                    targetSidebar: false,
                },
                dictionary: {
                    gridView: true,
                    sort: ALPHABET_SORT,
                    abbreviations: [],
                },
                graphic: {
                    sort: DATE_SORT,
                },
                video: {
                    sort: ALPHABET_SORT,
                    abbreviations: [],
                },
            },
            verse: {
                my: false,
                abbreviations: [],
            },
            audioPlayer: {
                autoplay: false,
                volume: 100,
            },
            promo: [],
            chat: {
                gridView: true,
                btn: true,
            },
        },
    },
    newsletter: {
        type: Boolean,
        default: false,
    },
}

const getType = function (objProps) {
    let type = {}
    Object.keys(objProps).forEach((key) => {
        type[key] = JSON.parse(JSON.stringify(objProps[key].default))
    })

    return type
}

const userType = () => getType(userProps)
const entireUserType = () => {
    let user = globalThis.clone(entireUserProps)
    if (!import.meta.env.SSR) {
        try {
            let options = window.sessionStorage.getItem('options')
            if (options) {
                user.options.default = mergeDeep(user.options.default, JSON.parse(options))
            }
        } catch (err) {
            let {i18n,} = getInitializedApp()
            let message = i18n && i18n.t('async.common.no_cookie_notice')
            let {showMessage,} = useShowMessage()
            setTimeout(() => {
                if(message !== 'async.common.no_cookie_notice' && !cookieManager.get('bs-nocookie-notice')) {
                    showMessage({
                        message,
                        type: 'error',
                        timeout: -1,
                        html: true,
                        closeCb: ()=>{
                            if (!cookieManager.get('bs-nocookie-notice')) {
                                cookieManager.set('bs-nocookie-notice', 'hidden')
                            }
                        },
                    })
                }
            })
        }
    }

    return getType(user)
}

export {
    userType,
    entireUserType,
}