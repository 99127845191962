<template>
    <ul class="bs-nav__menu-main__list">
        <li v-if="!loggedIn || cookieSet || !activity.name">
            <router-link
                :to="readURL"
                :title="t('async.menu.read')+': '+readPath"
                tabindex="-1"
                class="bs-nav__menu-main__list__link d-flex align-baseline"
            ><strong class="flex-shrink-0">{{ t('async.menu.read') }}</strong><span class="text-truncate d-block">{{ readPath }}</span></router-link>
        </li>
        <li v-else>
            <router-link
                :to="activity.route ? activity.route : ''"
                :title="t('async.menu.read_on') + ':' + activity.name"
                tabindex="-1"
                class="bs-nav__menu-main__list__link d-flex align-baseline"
                @click="remindClick"
            ><strong class="flex-shrink-0">{{ t('async.menu.read_on') }}</strong><span class="text-truncate d-block">{{ activity.name }}</span></router-link>
        </li>
        <li>
            <router-link
                to="/chats"
                class="bs-nav__menu-main__list__link"
                :title="t('async.menu.nicodemus-ai')"
                tabindex="-1"
                v-html="t('async.menu.nicodemus-ai')"
            ></router-link>
        </li>
        <li>
            <router-link
                to="/notes"
                class="bs-nav__menu-main__list__link"
                :title="t('async.menu.notes')"
                tabindex="-1"
            >{{ t('async.menu.notes') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/tags"
                :title="t('async.menu.tags')"
                tabindex="-1"
                class="bs-nav__menu-main__list__link"
            >{{ t('async.menu.tags') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/likes"
                class="bs-nav__menu-main__list__link"
                :title="t('async.menu.likes')"
                tabindex="-1"
            >{{ t('async.menu.likes') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/history"
                :title="t('async.menu.history')"
                tabindex="-1"
                class="bs-nav__menu-main__list__link"
            >{{ t('async.menu.history') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/dictionaries"
                :title="t('async.menu.dictionary')"
                tabindex="-1"
                class="bs-nav__menu-main__list__link"
            >{{ t('async.menu.dictionary') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/reading-plans"
                :title="t('async.menu.reading-plan')"
                tabindex="-1"
                class="bs-nav__menu-main__list__link"
            >
                {{ t('async.menu.reading-plan') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/graphics"
                :title="t('async.menu.graphics')"
                tabindex="-1"
                class="bs-nav__menu-main__list__link"
            >{{ t('async.menu.graphics') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/videos"
                :title="t('async.menu.video')"
                tabindex="-1"
                class="bs-nav__menu-main__list__link"
            >{{ t('async.menu.video') }}
            </router-link>
        </li>
        <li>
            <router-link
                to="/topics"
                :title="t('async.menu.topics')"
                tabindex="-1"
                class="bs-nav__menu-main__list__link"
            >{{ t('async.menu.topics') }}
            </router-link>
        </li>
    </ul>
</template>

<script>
import read from '@/assets/js/src/modules/navigation/_components/data/read.json'
import {getActivePinia, storeToRefs,} from 'pinia'
import {REF_SHORT, useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useLastActivityApi,} from '@/assets/js/src/modules/navigation/_composables/useLastActivityApi'
import {useBibleRefs,} from '@/assets/js/src/util/composables/useBibleRefs'
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'

const triggerRandomBiblePath = function (newVal, oldVal) {
    if (newVal !== oldVal && newVal) {
        this.calculateRandomBiblePath()
    }
}

export default {
    name: 'MainMenu',
    setup () {
        const activePinia = getActivePinia()

        // AppUiStore
        const appUiStore = useAppUiStore(activePinia)
        const { menu, } = storeToRefs(appUiStore)

        // BibleStore
        const bibleStore = useBibleStore(activePinia)
        const { selectedBibles, } = storeToRefs(bibleStore)

        // UserStore
        const userStore = useUserStore(activePinia)
        const { loggedIn, } = storeToRefs(userStore)

        return {
            menu,
            selectedBibles,
            loggedIn,
            ...useLastActivityApi(),
            ...useBibleRefs(),
            ...useTranslation(),
        }
    },
    data: () => ({
        readURL: '',
        readPath: '1. Mose 3',
        cookieSet: false,
    }),
    watch: {
        menu: triggerRandomBiblePath,
    },
    methods: {
        remindClick () {
            setTimeout(()=>{
                this.cookieSet = true
            },600)
        },
        calculateRandomBiblePath () {
            let rand = Math.floor(Math.random() * 99)
            this.readPath = this.getRefByCanonicals({canonicals: read[rand], type: REF_SHORT,})
            this.readURL = '/' + encodeURIComponent(this.selectedBibles.join('.')) + '/' + this.getRefByCanonicals({canonicals: read[rand],isUrl:true,})
        },
    },
}
</script>

<style lang="scss">
    .bs-app .bs-nav {
        &__menu-main {
            &__list {
                position: absolute;
                top: 50%;
                left: 80px;
                width: calc(100% - 80px);
                padding: 0;
                list-style: none;
                transform: translateY(-50%);

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    position: relative;
                    top: auto;
                    left: auto;
                    width: 100%;
                    padding: 24px 0 24px 24px;
                    transform: none;
                }


                &__link {
                    color: rgb(255 255 255 / 100%);
                    font-weight: 900;
                    font-size: 40px;
                    text-decoration: none;
                    transition: map-deep-get($bs-transition, linear);

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        font-size: 30px;
                    }

                    &:hover, &:focus {
                        outline: none;
                        opacity: .6;
                    }

                    span {
                        padding: 0 12px;
                        font-weight: normal;
                        font-size: 14px;
                    }

                    sup {
                        margin-left: .5rem;
                        font-weight: 400;
                    }
                }
            }
        }
    }
</style>
