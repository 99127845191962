<template>
    <nav
        v-if="messagesLoaded"
        ref="bs-menu"
        class="bs-nav hidden-print-only"
    >
        <!-- MENU - BUTTON -->
        <bs-menu-btn
            :is-menu="menu"
            :is-banner="banner"
            :is-notification="menuNotificationVisible"
            tabindex="0"
        ></bs-menu-btn>
        <!-- HEADER - LOGO -->
        <svg
            v-once
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            width="0"
            height="0"
        >
            <defs>
                <g id="logo">
                    <g>
                        <path
                            d="M40.9,30c2.1-2.5,2.1-6.2,2.1-6.2c-0.1-5.5-4.6-10-10.2-10l-3.8,0c0,0,0,0,0,0s0,0,0,0c-1.4,0-2.6,1.2-2.6,2.6
			c0,1.4,1.2,2.6,2.6,2.6c0,0,0,0,0,0s0,0,0,0h2c3.9,0,5.8,1.5,5.9,4.8c-0.1,3.3-2,4.8-6,4.8h-5.2c-0.4,0-0.8,0-1.2,0.2
			c-0.8,0.2-1.6,0.8-1.6,2v0.2c0,0.7,0.3,1.3,1,2.1c0,0,11.8,13.4,13.4,15.1c0.5,0.7,1.3,1.1,2.3,1.1c1.6,0,2.9-1.3,2.9-2.9
			c0-0.8-0.3-1.5-0.8-2l0,0L33,34.6c-0.5-0.6-1.6-1.4-1.6-1.4l3.1-0.2C34.5,33.1,38.4,33,40.9,30z"
                        />
                        <path
                            d="M51.2,26.6c1.7,0,3-1.3,3-3V19h9.4c1.4,0,2.6-1.2,2.6-2.6c0-1.4-1.2-2.6-2.6-2.6H50.8c-1.4,0-2.6,1.2-2.6,2.6v7.2
			C48.2,25.2,49.5,26.6,51.2,26.6z"
                        />
                        <path
                            d="M18.1,13.8H2.6C1.2,13.8,0,15,0,16.4v5.9c0,1.7,1.3,3,3,3s3-1.3,3-3V19h12.1c1.4,0,2.6-1.2,2.6-2.6
			C20.7,15,19.5,13.8,18.1,13.8z"
                        />
                        <path
                            d="M63.6,28.6h-5.2c-5.6,0-10.2,4.6-10.2,10.2v7.5v0c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3h0v-7.9c0-2.6,2.1-4.6,4.7-4.6l4.8,0
			c1.4,0,2.6-1.2,2.6-2.6C66.2,29.8,65.1,28.6,63.6,28.6z"
                        />
                        <path
                            d="M18.1,43.8C18.1,43.8,18.1,43.8,18.1,43.8h-7.4c-2.6,0-4.7-2.1-4.7-4.6v-5.3h9.5c1.4,0,2.6-1.2,2.6-2.6
			c0-1.4-1.2-2.6-2.6-2.6H3c-1.7,0-3,1.3-3,3v7.2C0,44.4,4.6,49,10.2,49h7.9c1.4,0,2.6-1.2,2.6-2.6C20.7,45,19.5,43.8,18.1,43.8z"
                        />
                        <path
                            d="M449.2,13.5h-0.8c-3.5,0-6.2,1.7-8,4.8l-4-4c-1.3-1.3-3-1.5-4.3-0.1l-1.4,1.4c-1.3,1.3-1.2,3,0.1,4.3l3.1,3.2v23.5
			c0,1.5,1,2.4,2.5,2.4h3.5c1.5,0,2.5-1,2.5-2.4V28.8c0-4.9,2.3-7.3,6.9-7.3h1c2.4,0,3.9-1.3,3.9-3.5v-0.7
			C454.1,14.8,452.4,13.5,449.2,13.5z"
                        />
                        <path
                            d="M210.9,42.1h-2.8c-1.5,0-2.1-0.6-2.1-2.4v-37c0-2.1-1.3-3.4-3.4-3.4h-1.5c-2.1,0-3.4,1.3-3.4,3.4v37.5
			c0,6.2,2.3,8.8,9.8,8.8h3.4c1.3,0,2-0.8,2-2.1V44C212.8,42.8,212.1,42.1,210.9,42.1z"
                        />
                        <path
                            d="M306.9,13c-10.1,0-15.6,4.9-15.6,13.9v9.3c0,8.9,5.5,13.5,16.2,13.5c9.5,0,14.3-3.3,14.3-9.8v-0.3c0-0.7-0.4-1.1-1.1-1.1
			h-5.7c-0.8,0-1.1,0.3-1.1,1.1v0.2c0,2.4-2.1,3.6-6.2,3.6c-5.5,0-7.9-1.9-7.9-6.3v-2.7h19.7c2,0,2.9-1,2.9-2.9v-5
			C322.2,17.6,317,13,306.9,13z M314.2,28.1h-14.6v-2.6c0-4.1,2.5-6.3,7.2-6.3s7.4,2.2,7.4,6.3V28.1z"
                        />
                        <path
                            d="M174,13c-3.9,0-6.9,1-9,3.1V2.7c0-2.1-1.3-3.4-3.4-3.4H160c-2.1,0-3.4,1.3-3.4,3.4v43.2c0,2.2,1.5,3.8,3.6,3.8h0.3
			c2.2,0,3.6-1.5,3.6-3.7c2.2,2.5,5.5,3.8,9.7,3.8c9,0,14.9-5.7,14.9-14.6v-7.5C188.8,18.8,182.9,13,174,13z M180.3,35.9
			c0,4.6-2.7,7.2-7.6,7.2s-7.6-2.6-7.6-7.2v-9c0-4.6,2.7-7.2,7.6-7.2s7.6,2.6,7.6,7.2V35.9z"
                        />
                        <path
                            d="M137.3,9.4h2.9c2.2,0,3.5-1.3,3.5-3.5V3.3c0-2.2-1.3-3.5-3.5-3.5h-2.9c-2.2,0-3.5,1.3-3.5,3.5v2.7
			C133.8,8.2,135.1,9.4,137.3,9.4z"
                        />
                        <path
                            d="M275.3,28.3l-9-1.1c-2.2-0.3-3.3-1.1-3.3-3.2v-0.7c0-2.7,2-4,6.6-4c4.5,0,6.5,1.2,6.5,3.7v0.3c0,0.8,0.5,1.2,1.3,1.2h5
			c0.8,0,1.3-0.6,1.3-1.4v-0.3c0-6.5-4.8-9.8-14.1-9.8c-9.2,0-14.4,4.1-14.4,11.2v0.6c0,5.8,2.7,8.7,9.3,9.5l9,1.1
			c2.2,0.3,3.1,1.1,3.1,3.2v0.8c0,2.7-2.3,4.1-6.8,4.1c-4.9,0-7.1-1.2-7.1-3.8v-0.4c0-0.8-0.4-1.2-1.2-1.2h-5
			c-0.8,0-1.4,0.5-1.4,1.3v0.5c0,6.6,4.9,9.9,14.7,9.9c9.2,0,14.6-4.1,14.6-11.2v-0.8C284.4,32.1,281.8,29.2,275.3,28.3z"
                        />
                        <path
                            d="M232.8,13c-10.1,0-15.6,4.9-15.6,13.9v9.3c0,8.9,5.5,13.5,16.2,13.5c9.5,0,14.3-3.3,14.3-9.8v-0.3c0-0.7-0.4-1.1-1.1-1.1
			h-5.7c-0.8,0-1.1,0.3-1.1,1.1v0.2c0,2.4-2.1,3.6-6.2,3.6c-5.5,0-7.9-1.9-7.9-6.3v-2.7h19.7c2,0,2.9-1,2.9-2.9v-5
			C248.2,17.6,242.9,13,232.8,13z M240.1,28.1h-14.6v-2.6c0-4.1,2.5-6.3,7.2-6.3s7.4,2.2,7.4,6.3V28.1z"
                        />
                        <path
                            d="M347.5,13.5h-0.8c-3.5,0-6.2,1.7-8,4.8l-4-4c-1.3-1.3-3-1.5-4.3-0.1l-1.4,1.4c-1.3,1.3-1.2,3,0.1,4.3l3.1,3.2v23.5
			c0,1.5,1,2.4,2.5,2.4h3.5c1.5,0,2.5-1,2.5-2.4V28.8c0-4.9,2.3-7.3,6.9-7.3h1c2.4,0,3.9-1.3,3.9-3.5v-0.7
			C352.4,14.8,350.7,13.5,347.5,13.5z"
                        />
                        <path
                            d="M148.6,42.1h-2.8c-1.5,0-2.1-0.6-2.1-2.4v-23c0-2.1-1-2.9-2.9-2.9H131c-1.1,0-1.9,0.7-1.9,1.8v3.2c0,1.1,0.8,1.8,1.9,1.8
			h4.3v19.6c0,6.2,2.3,8.8,9.8,8.8h3.4c1.3,0,2-0.8,2-2.1V44C150.4,42.8,149.7,42.1,148.6,42.1z"
                        />
                        <path
                            d="M385.8,13.8H383c-1.6,0-2.4,0.6-3.1,2.8l-8.1,25.1l-8.1-25.1c-0.7-2.2-1.5-2.8-3.1-2.8h-3.3c-1.5,0-2.3,0.8-2.3,2.1
			c0,0.5,0.1,1.1,0.4,1.7l9.7,28.3c0.8,2.4,1.8,3.4,4.3,3.4h4.3c2.6,0,3.5-0.9,4.3-3.4l9.7-28.3c0.2-0.6,0.4-1.2,0.4-1.7
			C388.1,14.6,387.3,13.8,385.8,13.8z"
                        />
                        <path
                            d="M116.8,23.9c4.1-1.8,6.2-5,6.2-9.5v-2C122.9,4,117.8,0,106.8,0h-15c-2.4,0-3.9,1.5-3.9,3.8v41.3c0,2.4,1.5,3.8,3.9,3.8
			h15.6c11,0,16.4-4.3,16.4-13v-2.2C123.8,29,121.4,25.6,116.8,23.9z M96.6,6.9h10.3c5.2,0,7.5,1.8,7.5,6v2c0,4.1-2.2,5.9-7.5,5.9
			H96.6V6.9z M115.2,35.9c0,4.3-2.4,6.2-7.7,6.2H96.6V27.6h10.9c5.3,0,7.7,1.9,7.7,6.2V35.9z"
                        />
                        <path
                            d="M408.6,13c-10.1,0-15.6,4.9-15.6,13.9v9.3c0,8.9,5.5,13.5,16.2,13.5c9.5,0,14.3-3.3,14.3-9.8v-0.3c0-0.7-0.4-1.1-1.1-1.1
			h-5.7c-0.8,0-1.1,0.3-1.1,1.1v0.2c0,2.4-2.1,3.6-6.2,3.6c-5.5,0-7.9-1.9-7.9-6.3v-2.7h19.7c2,0,2.9-1,2.9-2.9v-5
			C423.9,17.6,418.7,13,408.6,13z M415.9,28.1h-14.6v-2.6c0-4.1,2.5-6.3,7.2-6.3s7.4,2.2,7.4,6.3V28.1z"
                        />
                    </g>
                </g>
            </defs>
        </svg>
        <bs-menu-logo
            :is-menu="menu"
            :is-banner="banner"
            :is-overlay="overlay"
            :is-home="home"
            :is-home-hidden="homeLogoHidden && !menu"
            :main-header-classes="mainHeaderClasses"
            tabindex="0"
        ></bs-menu-logo>
        <!-- HEADER - Menü -->
        <div
            id="bs-nav__menu-wrapper"
            class="bs-nav__menu-wrapper"
            tabindex="-1"
            :aria-hidden="!menu ? 'true' : 'false'"
            :class="[{'bs-nav__menu-wrapper--open':menu},{'bs-nav__menu-wrapper--closed':!menu}]"
        >
            <div
                class="bs-nav__menu bs-nav__menu-user"
                :class="[{'bs-nav__menu-user--banner-visible':banner},{'bs-nav__menu-user--open':menu},{'bs-nav__menu-footer--closed':!menu}]"
            >
                <div
                    class="bs-nav__menu-user__scroll-header"
                    :class="[{'bs-nav__menu-user__scroll-header--open':menu},{'bs-nav__menu-user__scroll-header--closed':!menu},{'bs-nav__menu-user__scroll-header--banner-visible':banner}]"
                ></div>
                <bs-menu-user id="help-text-tour-5-mobil"></bs-menu-user>
                <bs-menu-notification tabindex="-1"></bs-menu-notification>
            </div>
            <div
                class="bs-nav__menu bs-nav__menu-main"
                :class="[{'bs-nav__menu-main--open':menu}]"
            >
                <div
                    class="stroke bg-white float-right"
                    style="top: 90%; right: 3%; opacity: .2;"
                ></div>
                <div
                    class="stroke bg-white xl float-right"
                    style="top: 80%; opacity: .2;"
                ></div>
                <div
                    class="stroke bg-white xxl float-right"
                    style="top: 10%; right: -40%; opacity: .2;"
                ></div>
                <bs-main-menu></bs-main-menu>
            </div>
            <div
                class="bs-nav__menu bs-nav__menu-footer"
                :class="[{'bs-nav__menu-footer--open':menu},{'bs-nav__menu-footer--closed':!menu}]"
            >
                <div
                    class="stroke bg-white float-right"
                    style="top: -20%; right: 10%;opacity: .07;"
                ></div>
                <div
                    class="stroke bg-white xxl float-left"
                    style="top: 75%; left: -35%; opacity: .07;"
                ></div>
                <bs-footer-menu :language="guiLanguageName"></bs-footer-menu>
                <bs-social-media :locale="locale"></bs-social-media>
            </div>
        </div>
    </nav>
</template>

<script>
import {getActivePinia, mapState, mapWritableState, storeToRefs} from 'pinia'
import MenuBtn from '@/assets/js/src/modules/navigation/_components/MenuBtn.vue'
import MenuLogo from '@/assets/js/src/modules/navigation/_components/MenuLogo.vue'
import MenuUser from '@/assets/js/src/modules/navigation/_components/MenuUser.vue'
import BsFooterMenu from '@/assets/js/src/modules/navigation/_components/FooterMenu.vue'
import BsSocialMedia from '@/assets/js/src/modules/navigation/_components/SocialMediaBar.vue'
import BsMainMenu from '@/assets/js/src/modules/navigation/_components/MainMenu.vue'
import BsMenuNotification from '@/assets/js/src/modules/navigation/_components/MenuNotification.vue'
import {useMainHeaderClasses,} from '@/assets/js/src/util/composables/useMainHeaderClasses'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'

export default {
    name: 'Navigation',
    components: {
        'bs-menu-btn': MenuBtn,
        'bs-menu-logo': MenuLogo,
        'bs-menu-user': MenuUser,
        'bs-footer-menu': BsFooterMenu,
        'bs-social-media': BsSocialMedia,
        'bs-main-menu': BsMainMenu,
        'bs-menu-notification': BsMenuNotification,
    },
    props: {
        messagesLoaded: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        let activePinia = getActivePinia()

        const { guiLanguageName, locale, } = storeToRefs(useLangStore(activePinia))
        const {
            banner,
            overlay,
            home,
            homeLogoHidden,
            menuNotificationVisible,
            menu,
        } = storeToRefs(useAppUiStore(activePinia))

        return {
            guiLanguageName,
            locale,
            banner,
            overlay,
            home,
            homeLogoHidden,
            menuNotificationVisible,
            menu,
            ...useMainHeaderClasses(),
        }
    },
    data () {
        return {
            offset: 0,
            aktFocusElem: 0,
            focusElems: [
                '.bs-nav__menu-btn',
                '.bs-nav__logo',
                '.bs-nav__menu-user__user__image-wrapper',
                '.bs-nav__menu-user__user__text a',
                '.bs-nav__notification > p > a',
                '.bs-nav__notification__button',
                '.bs-nav__menu-main__list li:nth-child(1) a',
                '.bs-nav__menu-main__list li:nth-child(2) a',
                '.bs-nav__menu-main__list li:nth-child(3) a',
                '.bs-nav__menu-main__list li:nth-child(4) a',
                '.bs-nav__menu-main__list li:nth-child(5) a',
                '.bs-nav__menu-main__list li:nth-child(6) a',
                '.bs-nav__menu-main__list li:nth-child(7) a',
                '.bs-nav__menu-main__list li:nth-child(8) a',
                '.bs-nav__menu-main__list li:nth-child(9) a',
                '.bs-nav__menu-main__list li:nth-child(10) a',
                '.bs-nav__menu-footer__list li:nth-child(1) a',
                '.bs-nav__menu-footer__list li:nth-child(2) a',
                '.bs-nav__menu-footer__list li:nth-child(3) a',
                '.bs-nav__menu-footer__list li:nth-child(4) a',
                '.bs-nav__menu-footer__list li:nth-child(5) a',
                '.bs-nav__menu-footer__list li:nth-child(6) a',
                '.bs-nav__menu-footer__list li:nth-child(7) a',
                '.bs-nav__menu-footer__list li:nth-child(8) button',
                '.bs-nav__menu-footer__list li:nth-child(9) a',
                '.bs-nav__menu-footer__list li:nth-child(10) a',
                '.bs-nav__menu-footer__list li:nth-child(12) a',
                '.bs-nav__menu-footer__list li:nth-child(13) a',
                '.bs-nav__menu-footer__list li:nth-child(14) a',
                '.bs-nav__menu-footer__list li:nth-child(16) a',
                '.bs-nav__menu-footer__social-bar__box a:nth-child(1)',
                '.bs-nav__menu-footer__social-bar__box a:nth-child(2)',
                '.bs-nav__menu-footer__social-bar__box a:nth-child(3)',
            ],
        }
    },
    computed: {
        route: function () {
            return this.$route.path
        },
    },
    watch: {
        menu: {
            handler: function (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    if (!import.meta.env.SSR) {
                        let fixElem = document.querySelector('main.v-main')
                        this.offset = window.pageYOffset
                        fixElem.style.position = 'fixed'
                        fixElem.style.width = '100%'
                        fixElem.style.top = (-1 * this.offset) + 'px'
                        fixElem.style.minHeight = '100vh'

                        document.addEventListener('keydown', this.handleGeneralKeyUp)
                        document.addEventListener('click', this.handleClick)
                        this.$refs['bs-menu'].querySelector(this.focusElems[this.aktFocusElem]).blur()
                    }
                } else if (newVal !== oldVal && !newVal) {
                    if (!import.meta.env.SSR) {
                        let fixElem = document.querySelector('main.v-main')
                        fixElem.style.position = 'relative'
                        fixElem.style.top = 'auto'
                        fixElem.style.minHeight = 'auto'
                        window.scrollTo(0, this.offset)

                        document.removeEventListener('keydown', this.handleGeneralKeyUp)
                        document.removeEventListener('click', this.handleClick)
                        this.aktFocusElem = 0
                        this.$refs['bs-menu'].querySelector('.bs-nav__menu-btn').focus()
                    }
                }
            },
        },
    },
    mounted () {
        document.addEventListener('keyup', this.handleOpenKey)
    },
    beforeUnmount () {
        removeEventListener('keyup', this.handleOpenKey)
    },
    methods: {
        handleOpenKey (event) {
            if (event.keyCode === 77 && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && !event.target.classList.contains('ck-content')) {
                this.$bsa && this.$bsa.event({
                    eventCategory: 'shortcuts',
                    eventAction: 'm (Menü öffnen)',
                })
                this.menu = true
            }
        },
        handleGeneralKeyUp (event) {
            if (event.key === 'Tab') {
                event.preventDefault()

                if (!event.shiftKey) {
                    this.aktFocusElem++

                    if (this.aktFocusElem >= this.focusElems.length) {
                        this.aktFocusElem = 0
                    }
                } else if (event.shiftKey) {
                    this.aktFocusElem--

                    if (this.aktFocusElem < 0) {
                        this.aktFocusElem = this.focusElems.length - 1
                    }
                }

                if (this.$refs['bs-menu'].querySelector(this.focusElems[this.aktFocusElem]) !== null) {
                    this.$refs['bs-menu'].querySelector(this.focusElems[this.aktFocusElem]).focus()
                } else {
                    this.handleGeneralKeyUp(event)
                }
            }
        },
        handleClick (event) {
            let {target,} = event
            while (target && target.tagName !== 'A') target = target.parentNode
            if (target) {
                let path = target.pathname
                if (this.route === path) {
                    if (this.menu) {
                        this.menu = false
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss">
    $minHeightUser: 275px;
    $minHeightFooter: 620px;

    .bs-app {
        &.block-scrolling {
            main.v-main {
                height: 100%;
                overflow: hidden;
            }
        }

        .bs-nav {
            display: flex;

            &__menu-wrapper {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 9992;
                width: 100vw;
                height: 100vh;
                height: calc(var(--vh) * 100);
                margin-left: -100vw;
                overflow-x: hidden;
                overflow-y: auto;
                transition-delay: 0s;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    position: fixed;
                    top: -100vh;
                    top: calc(var(--vh) * -100);
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    height: calc(var(--vh) * 100);
                    overflow-x: hidden;
                    overflow-y: auto;
                    transition-delay: 0s;
                }

                &--open {
                    margin-left: 0;
                    animation: .01s linear .6s changebg-in;
                    animation-fill-mode: forwards;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        top: 0;
                    }

                    @keyframes changebg-in {
                        0% {
                            background-color: transparent;
                        }

                        100% {
                            background-color: map-deep-get($bs-color, bs);
                        }
                    }
                }

                &--closed {
                    transition-delay: .5s;
                    animation: .01s linear 0s changebg-out;
                    animation-fill-mode: forwards;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        transition-delay: .5s;
                    }

                    @keyframes changebg-out {
                        0% {
                            background-color: map-deep-get($bs-color, bs);
                        }

                        100% {
                            background-color: transparent;
                        }
                    }
                }
            }

            &__menu {
                position: absolute;
                top: 0;
                left: -50vw;
                z-index: 9998;
                margin-top: 0;
                margin-left: -50vw;
                transition: margin .5s ease-in-out;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    position: relative;
                    top: auto;
                    left: auto;
                    z-index: 9998;
                    box-sizing: border-box;
                    margin: 0;
                    transition: margin .5s ease-in-out;
                }
            }

            // User Menü

            &__menu-user {
                z-index: 9998;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-end;
                justify-content: flex-end;
                width: 50vw;
                height: 30vh;
                height: calc(var(--vh) * 30);
                min-height: $minHeightUser;
                padding-top: map-deep-get($bs-xl, header, height);
                padding-right: 154px;
                background: map-deep-get($bs-color, bs);
                transition-delay: 0s;

                @media screen and (height <= 960px) {
                    padding-right: 110px;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    position: relative;
                    top: auto;
                    left: auto;
                    z-index: 9998;
                    width: 100vw;
                    height: auto;
                    min-height: auto;
                    margin-top: -100%;
                    padding: map-deep-get($bs-xs, header, height) 0 0 0;
                    background: map-deep-get($bs-color, bs);
                    transition-delay: 0s;
                }

                &--banner-visible {
                    padding-top: map-deep-get($bs-xl, header, height) + map-deep-get($bs-xl, banner, height);

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        padding-top: map-deep-get($bs-xs, header, height) + map-deep-get($bs-xs, banner, height);
                    }
                }

                &__scroll-header {
                    display: none;
                }

                &--closed {
                    transition-delay: .15s;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        transition-delay: .35s;
                    }
                }

                &--open {
                    margin-left: 50vw;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        margin-top: 0;
                        transition-delay: .15s;
                    }
                }

                &__scroll-header {
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 9999;
                    display: block;
                    width: 50vw;
                    height: map-deep-get($bs-xs, header, height);
                    margin-left: -50vw;
                    background: map-deep-get($bs-color, bs);
                    transition: margin .2s ease-in-out;
                    transition-delay: .75s;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        width: 100%;
                        height: map-deep-get($bs-xs, header, height);
                        margin-top: -100%;
                        margin-left: 0;
                        transition: margin .2s ease-in-out;
                        transition-delay: .3s;
                    }

                    &--open {
                        margin-left: 0;

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            margin-top: 0;
                        }
                    }

                    &--closed {
                        transition-delay: 0s;
                    }

                    &--banner-visible {
                        height: map-deep-get($bs-xs, header, height) + map-deep-get($bs-xs, banner, height);

                        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                            height: map-deep-get($bs-xs, header, height) + map-deep-get($bs-xs, banner, height);
                        }
                    }
                }
            }

            // Hauptmenü

            &__menu-main {
                z-index: 9997;
                width: 50vw;
                height: 100vh;
                height: calc(var(--vh) * 100);
                min-height: $minHeightUser + $minHeightFooter;
                background: map-deep-get($bs-color, bsHighlight);
                transition-delay: 0s;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    z-index: 9997;
                    width: 100vw;
                    height: auto;
                    min-height: auto;
                    margin-top: -100%;
                    background: map-deep-get($bs-color, bsHighlight);
                }

                &--open {
                    margin-left: 100vw;
                    transition-delay: .15s;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        margin-top: 0;
                        transition-delay: .25s;
                    }
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    &--closed {
                        transition-delay: .25s;
                    }

                    .stroke {
                        display: none;
                    }
                }
            }

            &__menu-footer {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                width: 50vw;
                height: 70vh;
                height: calc(var(--vh) * 70);
                min-height: $minHeightFooter;
                margin-top: 30vh;
                margin-top: calc(var(--vh) * 30);
                padding: 32px 154px 50px 0;
                background: map-deep-get($bs-color, bs);
                transition-delay: 0s;

                @media screen and (height <= 960px) {
                    padding: 32px 110px 50px 0;
                }

                @media screen and (height <= 916px) {
                    margin-top: 275px;
                }

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    z-index: 9996;
                    width: 100vw;
                    height: auto;
                    min-height: auto;
                    margin-top: -100%;
                    padding: 32px 0 32px 24px;
                    background: map-deep-get($bs-color, bs);
                    transition-delay: 0s;

                    .stroke {
                        display: none;
                    }
                }

                &--closed {
                    transition-delay: .15s;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        transition-delay: .15s;
                    }
                }

                &--open {
                    margin-left: 50vw;

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        margin-top: 0;
                        transition-delay: .35s;
                    }
                }
            }
        }
    }
</style>
