import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'

export default function (pinia) {
    let langStore = useLangStore(pinia)

    return [
        {
            name: 'Verse',
            path: `/:locale(${langStore.supportedLocales.join('|')})/verse/:textref*`,
            meta: {uiType: 'Overlay', type: 'VerseView', registerBack: true, noScrollTop: () => true,},
            component: () => import(/* webpackChunkName: "Verse" */ '@/assets/js/src/modules/verse/_views/VerseOverlay.vue'),
        },
    ]
}
