import {defineStore, getActivePinia,} from 'pinia'
import {safeDecodeURI,} from '@/assets/js/src/util/safeDecodeURI'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'

export const REF_SHORT = 1
export const REF_DEFAULT = 2

export const useBibleStore = defineStore('bible', {
    state: () => ({
        bibles: {},
        bookNumbers: {},
        selectedBibles: [],
        selectedBook: 1,
        selectedChapter: 0,
        introductions: {},
        locale: '',
    }),
    getters: {
        availableBooks: (state) => {
            let availableBooks = {}

            state.selectedBibles.forEach((abbr) => {
                availableBooks = (state.bookNumbers[abbr] || []).reduce((carry, value) => {
                    carry[value] = value

                    return carry
                }, availableBooks)
            })

            return availableBooks
        },
        abbreviations: (state) => Object.keys(state.bibles),
        firstSelectedBible (state) {
            return this.getAbbrByAbbreviation(state.selectedBibles[0])
        },
        biblesOfLocale () {
            let langStore = useLangStore(getActivePinia())
            let locale = langStore.locale
            let localeBible = []

            if (typeof this.biblesGroupByLocale[locale] !== 'undefined') {
                localeBible = this.biblesGroupByLocale[locale]
            }

            return localeBible
        },
        biblesGroupByLocale: (state) => {
            let tmp = {}

            Object.keys(state.bibles).forEach((key) => {
                if (typeof tmp[state.bibles[key].locale] === 'undefined') {
                    tmp[state.bibles[key].locale] = []
                }
                tmp[state.bibles[key].locale].push(state.bibles[key])
            })
            let langStore = useLangStore(getActivePinia())
            let locale = langStore.locale
            let keys = Object.keys(tmp).sort()
            let biblesByLanguage = {}

            if (typeof tmp[locale] !== 'undefined') {
                biblesByLanguage[locale] = tmp[locale]
            }

            for (let i = 0; i < keys.length; i++) {
                if (keys[i] !== locale) {
                    biblesByLanguage[keys[i]] = tmp[keys[i]]
                }
            }

            return biblesByLanguage
        },
        getAbbrByAbbreviation: (state) => {
            return (abbreviation) => {
                let compareAbbr = safeDecodeURI(abbreviation).toLowerCase()

                return Object.keys(state.bibles).find(
                    (key) => key.toLowerCase() === compareAbbr)
            }
        },
        getAbbreviationsByLocale: (state) => (locale) => {
            return Object.keys(state.bibles).filter(
                (key) => state.bibles[key].locale === locale
            )
        },
        getBibleLocales: function () {
            return (abbrs) => {
                let locales = []

                Object.keys(this.biblesGroupByLocale).forEach((localeKey) => {
                    let filter = this.biblesGroupByLocale[localeKey].find((elem) => abbrs.indexOf(elem.abbreviation) !== -1)

                    if (typeof filter !== 'undefined') {
                        locales.push(localeKey)
                    }
                })
                locales = [ ...new Set(locales), ]

                return locales
            }
        },
        getBibleById: (state) => (id) => {
            let matchedAbbreviation = Object.keys(state.bibles).find(
                (abbreviation) => {
                    return state.bibles[abbreviation].id === id
                }
            )

            return state.bibles[matchedAbbreviation]
        },
        getNameByAbbreviation: (state) => (abbreviation) => {
            let abbr = Object.keys(state.bibles).filter(
                (key) => key.toLowerCase() === abbreviation.toLowerCase())

            return state.bibles[abbr]?.name
        },
        getSepChapterByAbbreviation: (state) => (abbreviation) => {
            let abbr = Object.keys(state.bibles).filter(
                (key) => key.toLowerCase() === abbreviation.toLowerCase())

            return state.bibles[abbr]?.sep_chapter
        },
        hasAmp: (state) => (abbreviation) => {
            let item = Object.values(state.bibles)
                .find((bible) => abbreviation === bible.abbreviation)

            if (typeof item === 'undefined') {
                return false
            }

            return item.amp
        },
    },
    actions: {
        setSelectedBibles (bibles) {
            bibles = [ ...new Set(bibles), ]
            this.selectedBibles = bibles.map(abbr => this.getAbbrByAbbreviation(abbr))

            if (!import.meta.env.SSR) {
                try {
                    if (typeof window.sessionStorage !== 'undefined') {
                        window.sessionStorage.setItem('bibles', bibles)
                    }
                } catch (err) {
                    // eslint-disable-next-line no-empty
                }
                let userStore = useUserStore(getActivePinia())
                userStore.setUserOptions({option: `selectedBibles`, data: bibles,})
            }
        },
        setSelectedBook (book) {
            if (Number.isNaN(book) || (typeof book === 'string' && book.trim() === '')) {
                book = 1
            }
            this.selectedBook = book
        },
        setSelectedChapter (chapter) {
            this.selectedChapter = parseInt(chapter)
        },
    },
})
