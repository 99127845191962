import {getActiveHead,} from "@/assets/js/src/util/composables/useActiveHead"
import defaultMetaInfo, {genHeadOverrides,} from '@/assets/js/src/layout/meta'
import {getActivePinia,} from "pinia"
import {useMessagesLoaded,} from "@/assets/js/src/modules/lang/_composables/useMessagesLoaded"
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"
import {computed,} from "vue"
import {useRoute,} from "vue-router"

export const useHeadInfo = () => {
    const {messagesLoaded,} = useMessagesLoaded([ 'common', ])
    const {t,} = useTranslation()
    const head = getActiveHead()
    const activePinia = getActivePinia()
    const currentRoute = useRoute()

    const title = computed(() => {
        return messagesLoaded.value
            ? t('async.common.home.title')
            : ''
    })

    let info = {
        title,
        ...defaultMetaInfo(activePinia),
    }

    info.meta = info.meta.map((metaTag) => {
        if ([ 'og:description', 'description', ].includes(metaTag.vmid)) {
            metaTag.content = computed(()=>{
                return messagesLoaded.value
                    ? t('async.common.meta.description.default')
                    : ''
            })
        }

        if ([ 'og:title', ].includes(metaTag.vmid)) {
            metaTag.content = title
        }

        return metaTag
    })

    if ([ 'Home', 'HomeML', ].includes(currentRoute.name)) {
        info.link.push({
            rel: 'amphtml',
            href: computed(() => {
                return activePinia.state.value.baseUrl + '/amp'
            }),
            vmid: 'amphtml',
        })
    }

    head.push(info)
}

export const useHeadInfoTitle = ({titleKey,descriptionKey,}) => {
    let keys = new Set()
    Object.values({titleKey,descriptionKey,}).forEach((key) => {
        key && keys.add(key.split('.')[1])
    })

    const {messagesLoaded,} = useMessagesLoaded(Array.from(keys))
    const {t,} = useTranslation()
    const head = getActiveHead()

    const title = computed(() => {
        return messagesLoaded.value
            ? t(titleKey)
            : ''
    })

    let description
    if(descriptionKey) {
        description = computed(() => {
            return messagesLoaded.value
                ? t(descriptionKey)
                : ''
        })
    }

    head.push(genHeadOverrides({title, description,}))

    return {
        title,
    }
}
