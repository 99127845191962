import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useTranslation,} from "../util/composables/useTranslation"

export function createI18nInstance ({pinia,}) {

    // Globales Objekt (window bzw. global) referenzieren
    let globalThis = (1, eval)('this')

    globalThis.log('tap_createI18n')

    let langStore = useLangStore(pinia)

    const i18n = {
        ...useTranslation(langStore),
    }

    if (!import.meta.env.SSR && typeof cookieManager.get('bs-locale') === 'undefined') {
        cookieManager.set('bs-session-locale', langStore.locale, {
            secure: true,
        })
    }

    globalThis.log('stop_createI18n')

    return i18n
}
